import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {IAdditionalInformationData} from '../../models/additional-information/IAdditionalInformationData';
import {CmsAccessService} from '../../services/cms-access.service';

/**
 * Component to display additional information pages with content loaded from a CMS and displayed as HTML.
 * {@see AdditionalInformationService}
 */
@Component({
  selector: 'mob-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit {
  @Input() data: IAdditionalInformationData;
  @Output() closed = new EventEmitter<void>();

  content = '';

  constructor(
    private cmsAccessService: CmsAccessService,
  ) {}

  ngOnInit() {
    this._requestDataFromCms();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.onClose();
  }

  onClose() {
    this.closed.emit();
  }

  private _requestDataFromCms() {
    this.cmsAccessService.getContentFromCms$(this.data.cmsUrl, this.data.pathWithinCms, this.data.languageCode, this.data.contentOnCmsError
    ).subscribe(content => {
      this.content = content.replace('<p><img', '<p class="image-container"><img');
    });
  }
}
