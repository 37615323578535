import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoTimeSpan'
})
export class VideoTimeSpanPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (typeof(value) !== 'number') {
      return '--:--';
    }

    return `${Math.floor(value)}:00`;
  }
}
