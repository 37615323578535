<mob-positioned-popup
  [visible]="showPopup"
  [applyCorrectionForBrowser]="applyCorrectionForBrowser"
  [clickEvent]="clickEvent"
  [latchOrientation]="popupLatchOrientation"
  (popupClosed)="closePopup()"
>
  <div class="popup">
    <mob-popup-content (closeButtonClicked)="closePopup()">
      <div class="mob-map-popup-title">{{titleTranslationKey | translate}}</div>
      <div class="mob-map-popup-content">

        <div
          class="popup-external-content"
          [innerHTML]="contentHtml"
        ></div>

        <div class="popup-footer">
          <ng-content select=".info-popup-footer"></ng-content>
        </div>

      </div>
    </mob-popup-content>
  </div>
</mob-positioned-popup>
