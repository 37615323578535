<div *ngIf="config.title" class="sub-title" >
  {{config.title | translate}}
</div>

<div class="legend-entries">
  <ng-container *ngFor="let item of config.entries; let last = last">
    <svg [attr.width]="item.icon.width" [attr.height]="item.icon.height">
      <rect *ngIf="!item.icon.paintAsLine"
            [attr.x]="0" [attr.y]="0" [attr.width]="item.icon.width" [attr.height]="item.icon.height"
            [attr.fill]="item.icon.fill" [attr.stroke]="item.icon.stroke" [attr.rx]="item.icon.cornerRadius"
            [attr.stroke-width]="item.icon.strokeWidth"></rect>
      <line *ngIf="item.icon.paintAsLine"
            [attr.x1]="0" [attr.y1]="item.icon.height/2"
            [attr.x2]="item.icon.width" [attr.y2]="item.icon.height/2"
            [attr.fill]="item.icon.fill" [attr.stroke]="item.icon.stroke" [attr.rx]="item.icon.cornerRadius"
            [attr.stroke-width]="item.icon.strokeWidth"
            stroke-dasharray="8, 5"></line>
    </svg>
    <div class="text-box">
      {{item.label | translate}}
      <mob-info-icon
        [compensateBaseline]="false"
        *ngIf="item.infoType"
        (clicked)="onClickInfoIcon($event, item.infoType)">
      </mob-info-icon>
    </div>
  </ng-container>
</div>
