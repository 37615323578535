import {Inject, Injectable} from '@angular/core';
import {concat, defer, Observable, of} from 'rxjs';
import {MOBILAB_COMMON_CONFIG, IMobilabCommonConfig} from '../mobilab-common.config';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';
import {LoggingService} from '../logging/logging.service';

/**
 * Wrapper-Service to access the current locale in mobilab-common without depending on ngx-translate.
 * This service is meant to be overridden by a subclass in each specific app which wants to use it..
 */
@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  constructor(
    private logger: LoggingService,
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig,
  ) {}

  /**
   * @return the currently active language (without country-code)
   */
  public get currentLanguage(): string {
    return this.getLanguageFromLocale(this.currentLocale);
  }

  public get browserLanguage(): string {
    return this.getLanguageFromLocale(this.browserLocale);
  }

  /**
   * This observable fires the first event immediately (with the current locale)
   * @return an observable following the locale, initialized with the current one.
   */
  public get currentLocaleStream$(): Observable<string> {
    return concat(
      defer(() => of(this.currentLocale)),
      this.onLocaleChanged$,
    );
  }

  public get currentLanguageStream$(): Observable<string> {
    return this.currentLocaleStream$.pipe(map(locale => this.getLanguageFromLocale(locale)));
  }

  /**
   * Change the current locale to {@param locale}.
   */
  public setLocale(locale: string): void {
    const validLocales = _.values(this.mobilabConfig.languageToLocaleMap);

    if (!_.isEmpty(locale) && _.includes(validLocales, locale)) {
      this.changeLocale(locale);
    }
  }

  /**
   * @return the language (without country-code) based on the provided language or locale ({@param languageOrLocale})
   */
  public getLanguageFromLocale(languageOrLocale: string): string {
    if (_.isEmpty(languageOrLocale)) {
      return '';
    }

    return languageOrLocale.length <= 2 ? languageOrLocale : languageOrLocale.substring(0, 2);
  }

  // =====================================================================
  // ======================== App-Specific Code ==========================
  // =====================================================================

  /**
   * @return the currently active locale
   */
  public get currentLocale(): string {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(LocaleService.name,  'currentLocale');
    return _.get(this.mobilabConfig.languageToLocaleMap, [this.mobilabConfig.defaultLanguage]);
  }

  /**
   * This observable does not fire until the first future change in the locale
   * @return an observable firing whenever the locale changes.
   */
  public get onLocaleChanged$(): Observable<string> {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(LocaleService.name,  'onLocaleChanged$');
    return of(this.currentLocale);
  }

  protected changeLocale(locale: string): void {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(LocaleService.name,  'changeLocale');
  }

  public get browserLocale(): string {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(LocaleService.name,  'browserLocale');
    return this.currentLocale;
  }
}
