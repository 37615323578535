<mob-map-with-layers
  class="mobilab-map"
  [selectedLayer]="dynamicConfig.selectedLayer"
  [configuration]="staticMapConfig"
  [styleLike]="layerStyleLike"
  [renderOrderFunction]="renderOrder"
  (mapInitialized)="onUnderlyingMapInitialized($event)"
  (layerAdded)="onLayerAdded($event)"
  (layerLoaded)="onLayerLoaded($event)"
></mob-map-with-layers>
<div #mapTip class="map-tip" [class.hidden]="!mapTipVisible">
  {{this.mapTipText}}
</div>
