import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import MultiPolygon from 'ol/geom/MultiPolygon';
import {IUesgImageData} from '../../models/uesg/IUesgImageData';
import {UesgGalleryService} from '../../services/uesg/uesg-gallery.service';
import {LoggingService} from '../../logging/logging.service';
import {LocaleService} from '../../services/locale.service';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {IUesgBboxImageResponse} from '../../models/uesg/IUesgBboxImageResponse';
import * as _ from 'lodash';
import {IUesgTranslatedTexts} from '../../models/uesg/IUesgTranslatedTexts';
import {IUesgImagesData} from '../../models/uesg/IUesgImagesData';
import {IMobilabCommonConfig, MOBILAB_COMMON_CONFIG} from '../../mobilab-common.config';

/**
 * Component to show a gallery of images of flooding.
 */
@Component({
  selector: 'mob-uesg-image-gallery',
  templateUrl: './uesg-gallery.component.html',
  styleUrls: ['./uesg-gallery.component.scss']
})
export class UesgGalleryComponent implements OnInit {
  @Input() readonly geometry: MultiPolygon;
  @Input() readonly translations: IUesgTranslatedTexts;
  @Input() readonly isScreenNarrow: boolean;

  @Output() readonly imagesLoaded = new EventEmitter<IUesgImagesData>();

  private _isDefaultImages: boolean;
  images: IUesgImageData[];

  constructor(
    private uesgGalleryService: UesgGalleryService,
    private logging: LoggingService,
    private locale: LocaleService,
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig,
  ) {}

  ngOnInit() {
    this._loadImages();
  }

  private get _uesgLanguage(): string {
    const language = this.locale.currentLanguage;
    return _.includes(this.mobilabConfig.uesg.availableLanguages, language) ?
      language : this.mobilabConfig.uesg.defaultLanguage;
  }

  get uesgUrl(): Observable<string> {
    return this.translations.baseUrl$.pipe(map(baseUrl => `${baseUrl}/${this._uesgLanguage}/home/`));
  }

  // =====================================================================
  // =========================== Handle Images ===========================
  // =====================================================================

  private _loadImages() {
    this.uesgGalleryService.getImagesInPolygon(this.geometry).pipe(
      catchError(error => {
        this.logging.error('Failed to load images in polygon from UESG-Server', error);
        return [];
      }),
      switchMap(images => this._useImagesOrDefault(images)),
      map(images => this._enhanceImages(images)),
    ).subscribe(images => {
      this.images = images;
      this.imagesLoaded.emit({
        images,
        isDefaultImages: this._isDefaultImages,
      });
    });
  }

  private _useImagesOrDefault(images: IUesgBboxImageResponse[]): Observable<IUesgBboxImageResponse[]> {
    if (_.isEmpty(images)) {
      this._isDefaultImages = true;
      return this.uesgGalleryService.getDefaultImages$();
    }

    return of(images);
  }

  private _enhanceImages(images: IUesgBboxImageResponse[]): IUesgImageData[] {
    return images.map(item => {
      const newItem = {...item, date: new Date(item.date)} as IUesgImageData;
      newItem.url$ = this._createImageUrl(item.id);
      newItem.cert$ = this._createCopyright(item);
      return newItem;
    });
  }

  private _createImageUrl(imageId: string): Observable<string> {
    return this.translations.baseUrl$.pipe(map(url => `${url}/${this._uesgLanguage}/home/${imageId}`));
  }

  private _createCopyright(item: IUesgBboxImageResponse): Observable<string> {
    if (item.copyright && item.copyright === 7) {
      return of(item.author);
    }

    return this.locale.currentLocaleStream$.pipe(
      switchMap(() => {
        const prefix$ = this.translations.copyright.prefixes[item.copyright];
        const postfix$ = this.translations.copyright.postfixes[item.copyright];

        return combineLatest([prefix$, postfix$]).pipe(
          map(([prefix, postfix]) => prefix + item.author + postfix)
        );
      })
    );
  }
}
