import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MunicipalitySearchService} from '../../services/municipality-search.service';
import {IMunicipalitySearchResponse} from '../../models/municipality-search/IMunicipalitySearchResponse';
import * as _ from 'lodash';

/**
 * Generic component to search for Swiss municipalities.
 * {@see MunicipalitySearchService}
 */
@Component({
  selector: 'mob-municipality-search',
  templateUrl: './municipality-search.component.html',
  styleUrls: ['./municipality-search.component.scss']
})
export class MunicipalitySearchComponent implements OnInit, OnDestroy {
  @ViewChild('textInput', {static: true}) textInputElement: ElementRef<HTMLInputElement>;

  @Input() readonly panelClass: string; // is passed directly to the angular-material component

  @Input() readonly searchFieldPlaceholder: string;
  @Input() readonly panelWidth: string | number;
  @Input() readonly hideSearchIcon: boolean;

  myControl = new UntypedFormControl();

  public options = [];
  public selected = null;

  private _searchQuery  = new Subject<string>();
  private _subscription: Subscription;

  constructor(
    private municipalitySearchService: MunicipalitySearchService,
  ) {}

  ngOnInit() {
    this._subscribeToSearchResponses();
  }

  ngOnDestroy(): void {
    if (!_.isNil(this._subscription)) {
      this._subscription.unsubscribe();
    }
  }

  // =====================================================================
  // ========================== Initialization ===========================
  // =====================================================================

  private _subscribeToSearchResponses() {
    // wait time and wait until search changes
    const searchResponse = this._searchQuery.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(query => this.municipalitySearchService.searchForMunicipalityByName$(query))
    );

    // set the autocomplete-field according to the response
    this._subscription = searchResponse.subscribe((res: IMunicipalitySearchResponse) => {
      this.options = [];

      const fieldEmpty = _.isEmpty(this.myControl.value);
      const resultsEmpty = _.isEmpty(res.results);

      if (!fieldEmpty && !resultsEmpty) {
        res.results.map(x => {
          x.attrs.label = this._stripHTML(x.attrs.label);
          this.options.push(x);
        });
      }
    });
  }

  // =====================================================================
  // ========================== Event Handlers ===========================
  // =====================================================================

  public onInput(event: Event)  {
    const query = _.get(event.target, 'value');

    if (query && _.toLower(query) === _.toLower(easterEgg)) {
      this._triggerEasterEgg();
    }

    this.selected = null;
    if (!_.isEmpty(query)) {
      this._searchQuery.next(query);
    } else {
      this.options = [];
    }
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    this.selected = this.options.find(x => x.attrs.label === event.option.value);
    this._loseFocus();
    if (this.selected) {
      this.municipalitySearchService.updateBoundingBox(this.selected);
    }
  }

  // =====================================================================
  // ========================== Helper Methods ===========================
  // =====================================================================

  private _stripHTML(str: string): string {
    // find the element that starts with <b>
    const extract = str.match(/<b>.*/g)[0];
    // remove all html tags and the remove () from the string
    return extract.replace(/<.*?>/g, '').replace(/\(\)/g, ''); // replace tags
  }

  private _loseFocus() {
    this.textInputElement.nativeElement.setSelectionRange(0, 0);
    this.textInputElement.nativeElement.blur();
  }

  private _triggerEasterEgg() {
    console.log('\n' +
      ' =================================================================\n' +
      ' |  ,-----------------,                                          |\n' +
      ' | /|    HELP ME      |==========.===.   .===.   .===========.   |\n' +
      ' || |    FIND MY      |          |   |   |   |   |      .-.  | E |\n' +
      ' || |   EASTER EGGS   |  |===|   |   |   |   |   |..==./xxx\\ | N |\n' +
      ' || |_________________|          |   |       |   /<<<<<\\    || D |\n' +
      ' ||/_________________/   .=======\'   |   .   |   \\>>>>>/xxxx/--. |\n' +
      ' |   |   |           |   |           |   |   |   |`\'==\'\'---; * *`\\\n' +
      ' |   |   \'===========\'   |   |=======\'   |   |   |   ,===. \\* * */\n' +
      ' |   |                   |               |   |   |   |   |  \'--\'`|\n' +
      ' |   \'===============|   \'===.   |===.   |   |   |===\'   \'=======|\n' +
      ' |                           |       |   |   |   |               |\n' +
      ' |   |===============.   .   \'===|   |   |===|   |   .=======.   |\n' +
      ' |                   |   |           |   |   |   |   |       |   |\n' +
      ' |   .===========.   |   |   |===.   |   |   |   |   |   |   |   |\n' +
      ' |   |           |   |   |       |   |   |   |   |   |   |   |   |\n' +
      ' |   |   .===.   |   |   |===.   \'===|   |   \'===\'   |   |   |   |\n' +
      ' |   |   |   |   |   |   |   |       |   |           |   |   |   |\n' +
      ' |   \'===\'   /`\\ \'===\'   |   \'===.   |   \'===========|   |   |   |\n' +
      ' |          / : |                |   |               |   |   |   |\n' +
      ' | _.._=====| \'/ \'===|   .=======\'   \'===========.   |   |   |   |\n' +
      ' /`    \\    | /          |                       |   |   |       |\n' +
      '|  .-._ \'-"` (=======.   |   .===============.   |   |   \'===.   |\n' +
      '|_/  |/   e  e\\==.   |   |   |               |   |   |       |   |\n' +
      ' | S ||  >   @ )<|   |   |   |   .=======.   |   |   |===.   |   |\n' +
      ' | T | \\  \'--`/  |   |   |   |   |       |   |   |   |   |   |   |\n' +
      ' | A | / \'--<`   |   |   |   |   |   |   |   |   \'===\'   |   \'   |\n' +
      ' | R || ,    \\\\  |           |   |   |   |   |           |       |\n' +
      ' | T |; ;     \\\\__\'======.   |   |   \'===\'   |   .===.   |   |   |\n' +
      ' |   / /      |.__)==,   |   |   |           |   |   |   |   |   |\n' +
      ' |  (_/,--.   ; //"""\\\\  |   |   \'===========\'   |   \'===\'   |   |\n' +
      ' |  { `|   \\_/  ||___||  |   |                   |           |   |\n' +
      ' |   ;-\\   / |  |(___)|  |   \'===========.   |   \'=======.   |   |\n' +
      ' |   |  | /  |  |XXXXX|  |               |   |           |   |   |\n' +
      ' |   | /  \\  \'-,\\XXXXX/  |   .===========\'   \'=======.   |   |   |\n' +
      ' |   | \\__|----\' `"""`   |   |                       |   |   |   |\n' +
      ' |   \'===================\'   \'=======================\'   \'===\'   |\n' +
      ' |                                                               |\n' +
      ' =================================================================');
  }
}

const easterEgg = 'It is easter';
