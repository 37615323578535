export enum LanguageCode {
  de = 'de',
  fr = 'fr',
  en = 'en',
}

export enum LocaleCode {
  deCH = 'de-CH',
  frCH = 'fr-CH',
  enUS = 'en-US',
}
