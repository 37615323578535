import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OnBoardingComponent } from '../../landing-page/onboarding/onboarding.component';
import { ON_BOARDING_DATA } from './IOnBoardingData';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) { }

  openOnBoarding(initialStep = 1) {
    const overlayRef = this.overlay.create({ minHeight: '100%', minWidth: '100%' });
    const injector = this._createInjector(overlayRef, initialStep);
    const portal = new ComponentPortal(OnBoardingComponent, null, injector);
    overlayRef.attach(portal);
  }

  private _createInjector(overlayRef: OverlayRef, initialStep: number) {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: OverlayRef, useValue: overlayRef },
        { provide: ON_BOARDING_DATA, useValue: { initialStep: initialStep }}
      ]
    });
  }
}
