import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CmsAccessService } from '../../../../mobilab-common/services/cms-access.service';

@Injectable({
  providedIn: 'root'
})
export class CmsAccessHowadynService {
  constructor(
    private translate: TranslateService,
    private cmsAccessService: CmsAccessService,
  ) { }

  getContentFromCmsOrDefault$(cmsBaseUrl: string, pathInCms: string, localeCode: string, fileExtension: string = 'json')
    : Observable<string> {
    return this.translate.get('howadyn.cmsContentOnError').pipe(
      switchMap(cmsContentOnError =>
      this.cmsAccessService.getContentFromCms$(cmsBaseUrl, pathInCms, localeCode, cmsContentOnError, fileExtension)
    ));
  }
}
