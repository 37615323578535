import { Pipe, PipeTransform } from '@angular/core';
import { ValueDisplayService } from '../../domain/numbers/value-display.service';

@Pipe({
  name: 'valueDisplay',
  pure: false
})
export class ValueDisplayPipe implements PipeTransform {

  constructor(private readonly valueDisplayService: ValueDisplayService) {
  }

  transform(value: any, showMillionText = true, percentage = false, forceLargeNumber = false): any {
    return this.valueDisplayService.rawValueToDisplayString(value, showMillionText, percentage, forceLargeNumber);
  }
}
