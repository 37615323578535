import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MOBILAB_COMMON_CONFIG, IMobilabCommonConfig} from '../mobilab-common.config';
import * as _ from 'lodash';
import {LoggingModule} from './logging.module';

interface LogMessage {
  message: string;
  error?: any;
  severity: 'debug' | 'info' | 'warn' | 'error' | 'fail';
}

@Injectable({ providedIn: LoggingModule })
export class LoggingService {
  private messageBuffer: LogMessage[] = [];
  private readonly loggingUrl = '/logging';

  constructor(
    private http: HttpClient,
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig
  ) { }

  static createErrorMessage(error: any): string {
    if (!error) {
      return '[No Error]';
    }

    if (error.message) {
      return error.message;
    } else {
      try {
        return JSON.stringify(error);
      } catch (e) {
        return error.toString();
      }
    }
  }

  public fail(message: string, error?: any) {
    this._log({ message, error, severity: 'fail' });
  }

  public error(message: string, error?: any) {
    this._log({ message, error, severity: 'error' });
  }

  public warn(message: string, error?: any) {
    this._log({ message, error, severity: 'warn' });
  }

  public info(message: string, error?: any) {
    this._log({ message, error, severity: 'info' });
  }

  public debug(message: string, error?: any) {
    this._log({ message, error, severity: 'debug' });
  }

  public warnAboutEmptyServiceDefaultMethod(serviceName: string, methodName: string) {
    this.warn(`Using the empty default implementation of ${serviceName}.${methodName}()`);
  }

  private _log(logMessage: LogMessage) {
    if (!_.isNil(logMessage.error)) {
      logMessage.message = `${_.trimEnd(logMessage.message, '.')}. Inner error: ${LoggingService.createErrorMessage(logMessage.error)}`;
    }

    this.messageBuffer.push(logMessage);

    if (this._isFlush(logMessage)) {
      this._flush();
    }
  }

  private _isFlush(logMessage: LogMessage) {
    if (!this.mobilabConfig.logging.sendToServer && this.mobilabConfig.logging.writeToBrowserLog) {
      return true;  // no reason to wait if the message is just written to the browser-log
    }

    return this.messageBuffer.length > 50 || logMessage.severity === 'error' || logMessage.severity === 'fail';
  }

  private _flush() {

    if (this.mobilabConfig.logging.sendToServer) {
      this._sendToServer();
    }

    if (this.mobilabConfig.logging.writeToBrowserLog) {
      this._writeToBrowserLog();
    }

    // new array (do not clear existing)
    this.messageBuffer = [];
  }

  private _sendToServer() {
    // push messages to backend, fire and forget
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    this.http.post<LogMessage[]>(this.loggingUrl, this.messageBuffer, httpOptions)
      .subscribe({next: () => {}, error: (error) => console.error('error uploading log data', error)});
  }

  private _writeToBrowserLog() {
    _.forEach(this.messageBuffer, logMessage => {
      if (logMessage.severity === 'error' || logMessage.severity === 'fail') {
        console.error(logMessage.message);
      } else if (logMessage.severity === 'warn') {
        console.warn(logMessage.message);
      } else {
        console.log(logMessage.message);
      }
    });
  }
}
