<mat-nav-list *ngIf="config" class="menu">
  <a mat-list-item [href]="config.landingPageLink$ | async" target="_blank" rel="noopener noreferrer">
    <div class="list-header">
      <mat-icon>home</mat-icon>
      <div class="landing-page-text">
        <h3 class="landing-page-title" [innerHTML]="config.landingPageTitle$ | async"></h3>
        <p class="landing-page-subtitle">{{config.landingPageSubtitle$ | async}}</p>
      </div>
    </div>
  </a>
  <br />
  <mob-menu-chapter *ngFor="let chapter of config.chapters" [config]="chapter"></mob-menu-chapter>
</mat-nav-list>
