import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// the logging service must be "providedIn" a different module than mobilab-common to avoid circular dependencies
// => this "empty" module does the trick
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class LoggingModule { }
