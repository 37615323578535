import { Injectable } from '@angular/core';
import * as _ from 'lodash';

/**
 * Helper-Service to access the local storage
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageAccessService {
  constructor() {}

  public getBoolean(key: string): boolean {
    return localStorage.getItem(key) === true.toString();
  }

  public getString(key: string, defaultValue?: string): string {
    return localStorage.getItem(key) || defaultValue;
  }

  public getObject<TResult>(key: string): TResult | null {
    const jsonString = localStorage.getItem(key);
    return _.isEmpty(jsonString) ? null : JSON.parse(jsonString) as TResult;
  }

  public setProperty(key: string, value: any) {
    localStorage.setItem(key, value.toString());
  }
}
