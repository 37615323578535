// Route Parameters
export const commonRouteParams = {
  languageId: 'languageId',
  cmsPath: 'cmsPath',

  geoUnit: 'geoUnit',
  featureId: 'featureId',
};

// Route Segments
export const commonRouteSegments = {
  home: '/',
  map: 'map',
  coordinates: 'coords',
  languagePlaceholder: 'lang',
  detailsOverview: 'details',
  additionalInformation: 'information',
};

