<div
  [@showLatch]='visibilityCss'
  class="info-area-latch-left"
  (click)="toggleVisibility()"
>
  <mat-icon>keyboard_arrow_right</mat-icon>
</div>

<div
  [@showInfo]='visibilityCss'
  class="info-area-left"
  [class.info-area-left-bottom-padding]="!removeBottomPadding"
>
  <div class="info-container">
    <div class="header-container">
      <div class="logo" *ngIf="logoPath">
        <img alt="Logo" [attr.src]="logoPath">
      </div>
      <mat-icon class="arrow" (click)="toggleVisibility()">keyboard_arrow_left</mat-icon>
    </div>

    <div class="content-container">
      <ng-content></ng-content>
    </div>
  </div>
</div>
