<div class="content" (click)="selected()" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">

  <div class="title">{{('scenarioName.' + scenario.key) | translate}}</div>
  <div class="subtitle">{{('scenarioSubtitle.' + scenario.key) | translate}}</div>
  <div class="horizontal">
    <img [src]="mapImageUrl" />
    <div class="vertical">
      <div>{{'duration' | translate}}</div>
      <div class="bold"><label class="amount">{{scenario.modelledDuration / 24 - 3 | valueDisplay}}</label> {{'days' | translate}}</div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="vertical">
      <div>{{'mean' | translate}}</div>
      <div class="bold"><label class="amount">{{scenario.precipitationSum | valueDisplay}}</label> {{'mm' | translate}}</div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="vertical">
      <div>{{'maximum' | translate}}</div>
      <div class="bold"><label class="amount">{{scenario.precipitationMaximumIntensity | valueDisplay}}</label> {{'mm' | translate}}</div>
    </div>

  </div>

</div>
<div class="border-box"></div>
