import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { MapModeSwitzerlandService } from '../../domain/map-mode-switzerland/map-mode-switzerland.service';
import { Impact } from '../../shared/enums/Impact';
import { ImpactGroupings } from '../../domain/map-mode-switzerland/impact';
import { Observable } from 'rxjs';

@Component({
  selector: 'how-detail-table',
  templateUrl: './detail-table.component.html',
  styleUrls: ['./detail-table.component.scss']
})
export class DetailTableComponent implements OnInit {
  selectedImpact: Impact;

  impactGroups = ImpactGroupings;

  @Input() values$: Observable<any>;
  @Input() translationKey: string;
  @Input() subtitleKey = 'impactOn';


  @Output() impactChange = new EventEmitter<Impact>();

  constructor(
    private readonly mapModeService: MapModeSwitzerlandService
  ) {  }

  ngOnInit() {
    this.mapModeService.impact$.pipe(take(1)).subscribe(impact => this.selectImpact(impact));
  }

  selectImpact(impact: Impact) {
    this.impactChange.emit(impact);
    this.selectedImpact = impact;
  }
}
