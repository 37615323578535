import { LanguageCode, LocaleCode } from '../../../mobilab-common/enums/LocaleCode';

export const appConfiguration = {
  defaultLanguage: LanguageCode.de,
  languageToLocaleMap: {
    [LanguageCode.de]: LocaleCode.deCH,
    [LanguageCode.fr]: LocaleCode.frCH,
    [LanguageCode.en]: LocaleCode.enUS,
  },
  matomoId: 5,
  trackedEvents: {
    mapFeatureSelect: 'MapFeatureSelect'
  }
};
