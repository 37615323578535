import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { getRgbaColorFromArray } from '../../../../../mobilab-common/models/configuration/ColorAsArray';
import { Impact } from '../../../shared/enums/Impact';
import { Precipitation } from '../../../shared/enums/Precipitation';
import { MapModeSwitzerlandService } from '../../../domain/map-mode-switzerland/map-mode-switzerland.service';
import { ILegendAbsoluteDataConfiguration } from '../../../../../mobilab-common/models/legend/configuration/ILegendDataConfiguration';
import { LegendConfigurationService } from '../../../domain/legend/legend-configuration.service';
import { ILegendWithTextConfig, ILegendWithTextElement } from '../../../domain/legend/legend-with-text-config';
import { flatMap } from 'rxjs/operators';
import { ImpactGroupings } from '../../../domain/map-mode-switzerland/impact';
import { IBoxClickEvent } from '../../../../../mobilab-common/models/info-icon/IBoxClickEvent';
import { IInfoPopupData } from '../../../../../mobilab-common/models/IInfoPopupData';
import { InfoPopupService } from '../../../../../mobilab-common/services/info-popup.service';
import { InfoPopupPrecipitationComponent } from '../../info-popup/implementations/info-popup-precipitation/info-popup-precipitation.component';
import { InfoPopupCatchmentAreaComponent } from '../../info-popup/implementations/info-popup-catchment-area/info-popup-catchment-area.component';

@Component({
  selector: 'how-control-switzerland',
  templateUrl: './control-switzerland.component.html',
  styleUrls: ['./control-switzerland.component.scss']
})
export class ControlSwitzerlandComponent implements OnInit {
  precipitation = Precipitation;
  precipitationIsVisible$: Observable<boolean>;
  activePrecipitation$: Observable<Precipitation>;
  activeImpact$: Observable<Impact>;

  legendConfiguration = {
    entries: [{
      icon: {
        width: 20,
        height: 20,
        fill: 'white',
        stroke: getRgbaColorFromArray([246, 99, 13, .6]),
        strokeWidth: 4,
      },
      label: 'catchmentArea',
      infoType: InfoPopupCatchmentAreaComponent,
    } as ILegendWithTextElement
    ]} as ILegendWithTextConfig;

  readonly precipitationIntensityLegend$: Observable<ILegendAbsoluteDataConfiguration>;
  readonly precipitationSumLegend$: Observable<ILegendAbsoluteDataConfiguration>;
  impactLegend$: Observable<ILegendWithTextConfig>;

  impactGroups = ImpactGroupings;

  constructor(
    private readonly mapModeSwitzerlandService: MapModeSwitzerlandService,
    private legendConfigurationService: LegendConfigurationService,
    private readonly infoPopupService: InfoPopupService,
  ) {
    this.precipitationIntensityLegend$ = this.legendConfigurationService.precipitationIntensityLegendConfiguration$;
    this.precipitationSumLegend$ = this.legendConfigurationService.precipitationSumLegendConfiguration$;
  }

  ngOnInit() {
    this.precipitationIsVisible$ = this.mapModeSwitzerlandService.precipitationIsVisible$;
    this.activePrecipitation$ = this.mapModeSwitzerlandService.precipitation$;
    this.activeImpact$ = this.mapModeSwitzerlandService.impact$;
    this.impactLegend$ = this.mapModeSwitzerlandService.impact$
      .pipe(flatMap(x => this.legendConfigurationService.impactLegendConfiguration(x)));
  }

  precipitationChange(value: Precipitation): void {
    this.mapModeSwitzerlandService.precipitation = value;
  }

  precipitationIsVisibleChange(isVisible: boolean) {
    this.mapModeSwitzerlandService.precipitationIsVisible = isVisible;
  }

  impactChange(value: Impact) {
    this.mapModeSwitzerlandService.impact = value;
  }

  onClickPrecipitationInfoIcon(event: IBoxClickEvent) {
    const popupConfig: IInfoPopupData = {
      clickEvent: event
    };

    this.infoPopupService.openOverlay(popupConfig, InfoPopupPrecipitationComponent);
  }
}
