import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MenuType } from '../../../mobilab-common/enums/MenuType';
import { RoutingHelperService } from '../../../mobilab-common/services/routing/routing-helper.service';
import { StatisticsTrackerService } from '../../../mobilab-common/services/statistics-tracker.service';
import { OnboardingService } from '../domain/onboarding/onboarding.service';

@Component({
  templateUrl: './scenario-page.component.html',
  styleUrls: ['./scenario-page.component.scss']
})
export class ScenarioPageComponent implements OnInit {
  activeMenuType: MenuType;

  constructor(
    private translate: TranslateService,
    private routingHelper: RoutingHelperService,
    private statisticsTracker: StatisticsTrackerService,
    private onboardingService: OnboardingService,
  ) {
    this.routingHelper.addPreviousRoute(this.routingHelper.relativeRoute);
  }

  public ngOnInit(): void {
    this.statisticsTracker.trackInternalPage(this.routingHelper.relativeRoute);
  }

  currentLang(): string {
    return this.translate.currentLang;
  }

  changeLanguage(language: string) {
    return this.translate.use(language);
  }

  onMenuOpened(menuType: MenuType): void {
    this.activeMenuType = menuType;
  }

  onHelpButtonClicked(): void {
    this.onboardingService.openOnBoarding(2);
  }
}
