import { Injectable } from '@angular/core';
import * as _ from 'lodash';

/**
 * Helper-Service to access the session storage
 */
@Injectable({
  providedIn: 'root'
})
export class SessionStorageAccessService {
  public getBoolean(key: string): boolean {
    return sessionStorage.getItem(key) === true.toString();
  }

  public getString(key: string, defaultValue?: string): string {
    return sessionStorage.getItem(key) || defaultValue;
  }

  public getObject<TResult>(key: string): TResult | null {
    const jsonString = sessionStorage.getItem(key);
    return _.isEmpty(jsonString) ? null : JSON.parse(jsonString) as TResult;
  }

  public setProperty(key: string, value: any) {
    sessionStorage.setItem(key, value.toString());
  }
}
