import { Component } from '@angular/core';

@Component({
  selector: 'how-copyright',
  template: '<label>©swisstopo</label>',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {

  constructor() { }

}
