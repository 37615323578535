<mob-main-container
  [appTitle]="'appTitle' | translate"
  [footerCopyrightTranslation]="'footerCopyright' | translate"
  [footerAboutUsTranslation]="'footerAboutUs' | translate"
  [localeCode]="currentLang()"
  [closeMenuOnBackgroundClick]="true"
  [hideFooter]="true"
  (localeCodeChange)="changeLanguage($event)"
  (menuOpened)="onMenuOpened($event)"
>
  <div class="mob-main-container-content">

    <how-detail-header
        [scenario]="scenario"
        [flowlineKey]="flowlineKey"
        [class.mat-elevation-z1]="true">
    </how-detail-header>
    <div class="main-page">
      <how-detail-table
        (impactChange)="impactChange($event)"
        [values$]="tableValues$"
        [translationKey]="'flowline.' + flowlineKey"
        [subtitleKey]="'impactOnFloodplain'">
      </how-detail-table>
      <div class="content">
        <how-detail-content
          [floodplainKey]="flowlineKey"
          [mapMode]="mapMode"
          [impact]="impact"
          [data$]="graphData$"
          *ngIf="graphData$">
        </how-detail-content>
      </div>
    </div>

  </div>
  <div class="mob-main-container-sidenav">
    <how-menu [menuType]="activeMenuType"></how-menu>
  </div>
</mob-main-container>
