import { OverlayRef } from '@angular/cdk/overlay';
import { DoCheck, Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SCENARIO_CONTENT_DATA } from '../../domain/scenario-popup/IScenarioPopup';

@Component({
  selector: 'how-scenario-popup',
  templateUrl: './scenario-popup.component.html',
  styleUrls: ['./scenario-popup.component.scss']
})

export class ScenarioPopupComponent implements DoCheck {

  constructor(
    private overlayRef: OverlayRef,
    @Inject(SCENARIO_CONTENT_DATA) public content$: Observable<string>
  ) { }

  ngDoCheck(): void {
    const tooFarRight = this.overlayRef.overlayElement.getBoundingClientRect().right > window.innerWidth;
    const tooFarLeft = this.overlayRef.overlayElement.getBoundingClientRect().left < 0;
    const tooFarDown = this.overlayRef.overlayElement.getBoundingClientRect().bottom > window.innerHeight;
    const tooFarUp = this.overlayRef.overlayElement.getBoundingClientRect().top < 0;
    if (tooFarRight || tooFarLeft || tooFarDown || tooFarUp) {
      this.overlayRef.updatePosition();
    }
  }
}
