import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ICmsHttpResponse} from '../models/additional-information/ICmsHttpResponse';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {LoggingService} from '../logging/logging.service';
import {LocaleService} from './locale.service';

/**
 * Service to load additional data from a CMS.
 */
@Injectable({
  providedIn: 'root'
})
export class CmsAccessService {
  constructor(
    private http: HttpClient,
    private loggingService: LoggingService,
    private localeService: LocaleService
  ) {}

  getContentFromCms$(cmsBaseUrl: string, pathInCms: string, localeOrLanguageCode: string, contentOnError?: string, fileExtension: string = 'json')
    : Observable<string> {
    fileExtension = _.trimStart(fileExtension, '.');
    cmsBaseUrl = _.trimEnd(cmsBaseUrl, '/');
    pathInCms = _.trim(pathInCms, '/');

    const languageCode = this.localeService.getLanguageFromLocale(localeOrLanguageCode);
    const url = `${cmsBaseUrl}/${languageCode}/${pathInCms}.${fileExtension}`;

    return this.http.get<ICmsHttpResponse>(url).pipe(
      map(response => response.content),
      catchError(err => this._processError(pathInCms, err, contentOnError)),
    );
  }

  private _processError(pathInCms: string, error: any, contentOnError?: string): Observable<string> {
    this.loggingService.error(`Failed to access CMS on path '${pathInCms}'`, error);
    return of(contentOnError || '');
  }
}
