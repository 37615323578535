import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IScenarioPopup } from '../../domain/scenario-popup/IScenarioPopup';
import { Scenario } from '../../openapi/models/scenario';

@Component({
  selector: 'how-scenario-card',
  templateUrl: './scenario-card.component.html',
  styleUrls: ['./scenario-card.component.scss']
})
export class ScenarioCardComponent {
  _scenario: Scenario = {
    key: 'testKey',
    modelledDuration: 92,
    precipitationMaximumIntensity: 30,
    precipitationSum: 6,
  };
  get scenario() {
    return this._scenario;
  }
  @Input() set scenario(value: Scenario) {
    this._scenario = value;
    this.mapImageUrl = `${environment.gravUrl}/user/pages/howadyn_scenarios/${value.key.toLowerCase()}/mapPreview.png`;
  }
  @Output() scenarioSelectedEvent = new EventEmitter<Scenario>();
  @Output() scenarioHoverEvent = new EventEmitter<IScenarioPopup>();
  mapImageUrl: string;
  scenarioPopup: IScenarioPopup = { scenario_key: null, rect: null };

  constructor(
    private el: ElementRef,
  ) { }

  selected() {
    this.scenarioSelectedEvent.next(this.scenario);
  }

  mouseEnter() {
    this.setPopupInfo();
    this.scenarioHoverEvent.next(this.scenarioPopup);
  }

  mouseLeave() {
    this.scenarioHoverEvent.next(undefined);
  }

  private setPopupInfo() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    this.scenarioPopup.scenario_key = this.scenario.key;
    this.scenarioPopup.rect = rect;
  }
}
