var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/**
 * @module ol/interaction/Interaction
 */
import BaseObject from '../Object.js';
import { easeOut, linear } from '../easing.js';
import InteractionProperty from './Property.js';
/**
 * Object literal with config options for interactions.
 * @typedef {Object} InteractionOptions
 * @property {function(import("../MapBrowserEvent.js").default):boolean} handleEvent
 * Method called by the map to notify the interaction that a browser event was
 * dispatched to the map. If the function returns a falsy value, propagation of
 * the event to other interactions in the map's interactions chain will be
 * prevented (this includes functions with no explicit return). The interactions
 * are traversed in reverse order of the interactions collection of the map.
 */
/**
 * @classdesc
 * Abstract base class; normally only used for creating subclasses and not
 * instantiated in apps.
 * User actions that change the state of the map. Some are similar to controls,
 * but are not associated with a DOM element.
 * For example, {@link module:ol/interaction/KeyboardZoom~KeyboardZoom} is
 * functionally the same as {@link module:ol/control/Zoom~Zoom}, but triggered
 * by a keyboard event not a button element event.
 * Although interactions do not have a DOM element, some of them do render
 * vectors and so are visible on the screen.
 * @api
 */
var Interaction = /** @class */function (_super) {
  __extends(Interaction, _super);
  /**
   * @param {InteractionOptions} options Options.
   */
  function Interaction(options) {
    var _this = _super.call(this) || this;
    if (options.handleEvent) {
      _this.handleEvent = options.handleEvent;
    }
    /**
     * @private
     * @type {import("../PluggableMap.js").default}
     */
    _this.map_ = null;
    _this.setActive(true);
    return _this;
  }
  /**
   * Return whether the interaction is currently active.
   * @return {boolean} `true` if the interaction is active, `false` otherwise.
   * @observable
   * @api
   */
  Interaction.prototype.getActive = function () {
    return /** @type {boolean} */this.get(InteractionProperty.ACTIVE);
  };
  /**
   * Get the map associated with this interaction.
   * @return {import("../PluggableMap.js").default} Map.
   * @api
   */
  Interaction.prototype.getMap = function () {
    return this.map_;
  };
  /**
   * Handles the {@link module:ol/MapBrowserEvent map browser event}.
   * @param {import("../MapBrowserEvent.js").default} mapBrowserEvent Map browser event.
   * @return {boolean} `false` to stop event propagation.
   * @api
   */
  Interaction.prototype.handleEvent = function (mapBrowserEvent) {
    return true;
  };
  /**
   * Activate or deactivate the interaction.
   * @param {boolean} active Active.
   * @observable
   * @api
   */
  Interaction.prototype.setActive = function (active) {
    this.set(InteractionProperty.ACTIVE, active);
  };
  /**
   * Remove the interaction from its current map and attach it to the new map.
   * Subclasses may set up event handlers to get notified about changes to
   * the map here.
   * @param {import("../PluggableMap.js").default} map Map.
   */
  Interaction.prototype.setMap = function (map) {
    this.map_ = map;
  };
  return Interaction;
}(BaseObject);
/**
 * @param {import("../View.js").default} view View.
 * @param {import("../coordinate.js").Coordinate} delta Delta.
 * @param {number=} opt_duration Duration.
 */
export function pan(view, delta, opt_duration) {
  var currentCenter = view.getCenterInternal();
  if (currentCenter) {
    var center = [currentCenter[0] + delta[0], currentCenter[1] + delta[1]];
    view.animateInternal({
      duration: opt_duration !== undefined ? opt_duration : 250,
      easing: linear,
      center: view.getConstrainedCenter(center)
    });
  }
}
/**
 * @param {import("../View.js").default} view View.
 * @param {number} delta Delta from previous zoom level.
 * @param {import("../coordinate.js").Coordinate=} opt_anchor Anchor coordinate in the user projection.
 * @param {number=} opt_duration Duration.
 */
export function zoomByDelta(view, delta, opt_anchor, opt_duration) {
  var currentZoom = view.getZoom();
  if (currentZoom === undefined) {
    return;
  }
  var newZoom = view.getConstrainedZoom(currentZoom + delta);
  var newResolution = view.getResolutionForZoom(newZoom);
  if (view.getAnimating()) {
    view.cancelAnimations();
  }
  view.animate({
    resolution: newResolution,
    anchor: opt_anchor,
    duration: opt_duration !== undefined ? opt_duration : 250,
    easing: easeOut
  });
}
export default Interaction;
