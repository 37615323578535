import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IPixelCoordinates } from '../../models/info-icon/IPixelCoordinates';
import {createBoxClickEvent, createPixelCoordinatesFromMouseEvent} from '../positioned-popup/positioned-popup.component';
import { IInfoIconClickEvent } from '../../models/info-icon/IInfoIconClickEvent';

/**
 * A component showing an info-icon with a on-click action.
 * The component also allows its parent to trigger a click programmatically.
 */
@Component({
  selector: 'mob-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent implements OnInit {
  @ViewChild('infoIcon', {static: true}) private infoIcon: ElementRef<HTMLInputElement>;

  @Input() readonly compensateBaseline: boolean = true;   // if true, the icon is moved slightly downwards to compensate for text baseline
  @Input() readonly positionTop: number;   // if set, that overrides the relative positioning of the icon
  @Input() readonly useDefaultCursor = false;
  @Output() readonly clicked = new EventEmitter<IInfoIconClickEvent>();

  constructor() {}
  ngOnInit() {}

  // =====================================================================
  // ========================= Public Interface ==========================
  // =====================================================================

  public triggerArtificialClick() {
    this._handleClick({
      leftPx: this.infoIcon.nativeElement.getBoundingClientRect().left,
      topPx: this.infoIcon.nativeElement.getBoundingClientRect().top,
    }, true);
  }

  // =====================================================================
  // ========================== Inner Workings ===========================
  // =====================================================================

  onClick(mouseEvent: MouseEvent) {
    this._handleClick(createPixelCoordinatesFromMouseEvent(mouseEvent));
  }

  private _handleClick(clickedCoordinates: IPixelCoordinates, isArtificialClick?: boolean) {
    const infoIconEvent = createBoxClickEvent(clickedCoordinates, this.infoIcon.nativeElement) as IInfoIconClickEvent;
    infoIconEvent.isArtificialClick = isArtificialClick;
    this.clicked.emit(infoIconEvent);
  }
}
