import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconPosition, ILegendDataConfiguration} from '../../../models/legend/configuration/ILegendDataConfiguration';
import {IBoxClickEvent} from '../../../models/info-icon/IBoxClickEvent';

/*
  Simple component to display a legend.
  Displays the legend as an SVG of which it expects to get from <ng-content>
 */
@Component({
  selector: 'mob-legend-base',
  templateUrl: './legend-base.component.html',
  styleUrls: ['./legend-base.component.scss']
})
export class LegendBaseComponent implements OnInit {
  @Input() readonly config: ILegendDataConfiguration;
  @Output() readonly noValueInfoIconClicked = new EventEmitter<IBoxClickEvent>();

  constructor() { }

  ngOnInit() {}

  get isPositionRight(): boolean {
    return this.config.infoIconPosition === IconPosition.right;
  }

  get containerClass(): string {
    const mainClass = 'legend-base-content';
    const secondClass = this.isPositionRight ? 'legend-base-content-besides' : 'legend-base-content-below';
    return mainClass + ' ' + secondClass;
  }

  get infoButtonClass(): string {
    return this.isPositionRight ? 'i-button-besides' : 'i-button-below';
  }
}
