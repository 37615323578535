<div class="search-container">
  <mat-form-field>
    <input #textInput type="text" matInput [formControl]="myControl" [placeholder]="searchFieldPlaceholder" [matAutocomplete]="auto" (input)="onInput($event)">
    <button *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="myControl.reset();">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-autocomplete
    #auto="matAutocomplete"
    [panelWidth]="panelWidth"
    [class]="panelClass"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option *ngFor="let option of options" [value]="option.attrs.label">
      {{ option.attrs.label }}
    </mat-option>
  </mat-autocomplete>

  <ng-container *ngIf="!hideSearchIcon">
    <button mat-icon-button id="suche" aria-label="Suche" disabled=true>
      <mat-icon class="icon search-icon" aria-hidden="search" >search</mat-icon>
    </button>
  </ng-container>
</div>
