<mob-main-container
  [appTitle]="'appTitle' | translate"
  [footerCopyrightTranslation]="'footerCopyright' | translate"
  [footerAboutUsTranslation]="'footerAboutUs' | translate"
  [localeCode]="currentLang()"
  [closeMenuOnBackgroundClick]="true"
  (localeCodeChange)="changeLanguage($event)"
  (menuOpened)="onMenuOpened($event)"
>
  <div class="mob-main-container-content">
    <button class="help-button" mat-icon-button (click)="onHelpButtonClicked()">
      ?
    </button>
    <how-main-header></how-main-header>
    <how-control-mapmode></how-control-mapmode>
    <div class="visible-map-overlay">
      <how-copyright></how-copyright>
      <how-info-strip></how-info-strip>
    </div>
    <how-main-map></how-main-map>
    <how-time-slider></how-time-slider>

  </div>

  <div class="mob-main-container-sidenav">
    <how-menu [menuType]="activeMenuType"></how-menu>
  </div>
</mob-main-container>
