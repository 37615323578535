import { Precipitation } from '../../shared/enums/Precipitation';
import { ImageStatic } from 'ol/source';
import * as Projection from 'ol/proj';
import { CoordinateProjection } from '../../../../mobilab-common/enums/CoordinateProjection';
import { environment } from '../../../environments/environment';
import ImageLayer from 'ol/layer/Image';
import { Options as BaseImageOptions } from 'ol/layer/BaseImage';
import { ScenarioLayer } from '../scenario/scenario-layer';
import { Scenario } from '../../openapi/models/scenario';
import { defaultExtent } from '../scenario/defaultExtent';

export interface Options extends BaseImageOptions {
  precipitation: Precipitation;
}

export class PrecipitationMaximumLayer extends ImageLayer implements ScenarioLayer {
  private readonly _precipitation: Precipitation;
  private _scenario: Scenario;

  constructor(options: Options) {
    super(options);
    this._precipitation = options.precipitation;
  }

  get scenario(): Scenario {
    return this._scenario;
  }

  set scenario(value: Scenario) {
    this.setExtent(defaultExtent);
    this.setSource(new ImageStatic({
      url: `${environment.gravUrl}/user/pages/howadyn_scenarios/${value.key.toLowerCase()}/${this.filename}`,
      projection: Projection.get(CoordinateProjection.EPSG3857),
      imageExtent: defaultExtent,
    }));
    this._scenario = value;
  }

  private get filename(): string {
    switch (this._precipitation) {
      case Precipitation.Intensity:
        return 'prec_max_int.png';
      case Precipitation.Sum:
        return 'prec_max_sum.png';
      default:
        console.warn('Precipitation Video File unknown.');
    }
  }
}
