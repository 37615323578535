<mob-info-area-left class="howadyn">
  <div class="control-outer">
    <div class="control-header">
      <div class="scenario-label">{{'scenario' | translate}}</div>
      <a [routerLink]="scenariosUrl$ | async" class="go-back-arrow"><mat-icon>arrow_back</mat-icon></a>
      <div class="scenario-name">
        {{('scenarioName.' + (scenario$ | async)?.key) | translate}}
      </div>
      <div class="scenario-duration">
        <div class="duration-label">
          {{'duration' | translate}}
        </div>
        <div class="duration-value">
          {{(scenario$ | async)?.modelledDuration / 24 - 3}} {{'days' | translate}}
        </div>
      </div>
    </div>

    <div class="control-content">
      <div *ngIf="activeMapMode$ | async as activeMapMode">
        <div class="map-modes">
          <button mat-button [class.selected]="activeMapMode === mapMode.Switzerland" (click)="onClickSwitzerland()">
            <div class="button-content">
              <div>{{'switzerland' | translate}}</div>
              <mob-info-icon [compensateBaseline]="false" (clicked)="onClickSwitzerlandInfoIcon($event)" (click)="$event.stopPropagation()"></mob-info-icon>
            </div>
          </button>
          <button mat-button [class.selected]="activeMapMode === mapMode.Floodplain" disabled class="floodplain-button">
            <div class="button-content">
              <div>{{'flowlines' | translate}}</div>
              <mob-info-icon [compensateBaseline]="false" (clicked)="onClickFlowlinesInfoIcon($event)" class="floodplain-info"></mob-info-icon>
            </div>
          </button>
        </div>

        <how-control-switzerland *ngIf="activeMapMode === mapMode.Switzerland"></how-control-switzerland>

        <how-control-flowlines *ngIf="activeMapMode === mapMode.Floodplain"></how-control-flowlines>
      </div>
    </div>
  </div>
</mob-info-area-left>
