import { Options as BaseOptions } from 'ol/layer/BaseVector';
import { DynamicLayer } from '../time/dynamic-layer';
import { ScenarioLayer } from '../scenario/scenario-layer';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { ImpactValueType } from '../../openapi/models/impact-value-type';
import { TimeService } from '../time/time.service';
import { TimeMode } from '../time/time-mode.enum';
import { Scenario } from '../../openapi/models/scenario';
import { ScenariosService } from '../../openapi/services/scenarios.service';
import { FeatureLike } from 'ol/Feature';

export class RoadImpactLayer extends VectorLayer implements DynamicLayer, ScenarioLayer {

  private _scenario: Scenario;
  private _timeMode: TimeMode;
  private _currentTime: number;
  private _fetchedIds = new Set<number>();
  private _impacts = { } as { [key: string]: { [key: string]: number } };
  private readonly _notAffectedStyle = new Style({ });
  private readonly _noAccessStyle = new Style({
    stroke: new Stroke({ color: 'rgb(165,15,21)', width: 4}),
  });
  private readonly _limitedAccessStyle = new Style({
    stroke: new Stroke({ color: 'rgb(251,106,74)', width: 4}),
  });
  private readonly _detourStyle = new Style({
    stroke: new Stroke({ color: 'rgb(128,128,0)', width: 4}),
  });


  constructor(options: BaseOptions, private timeService: TimeService, private scenarioService: ScenariosService) {
    super(options);
    this.getSource().on('change', () => this.fetchRoadImpacts());
    this.setStyle((feature, resolution) => this.getDynamicStyle(feature, resolution));
    this.timeService.currentRealTimeFullHours$.subscribe(t => this.currentTime = t);
    this.timeService.timeMode$.subscribe(t => this.timeMode = t);
    this.setZIndex(8);
  }

  get scenario(): Scenario {
    return this._scenario;
  }

  set scenario(value: Scenario) {
    this._scenario = value;
    this._fetchedIds = new Set<number>();
    this._impacts = { } as { [key: string]: { [key: string]: number } };
    this.fetchRoadImpacts();
  }

  get impactValueType(): ImpactValueType {
    switch (this._timeMode) {
      case TimeMode.Dynamic: return ImpactValueType.Actual;
      case TimeMode.Maximum: return ImpactValueType.Aggregated;
    }
  }

  get timeMode(): TimeMode {
    return this._timeMode;
  }

  set timeMode(value: TimeMode) {
    this._timeMode = value;
    this.changed();
  }

  get currentTime(): number {
    return this._currentTime;
  }

  set currentTime(value: number) {
    this._currentTime = value;
    this.changed();
  }

  getDynamicStyle(feature: FeatureLike, _: number) {
    const id = +(feature.getId() as string).substring(5);
    const roadImpacts: { [key: string]: number } = this._impacts[id];

    if (roadImpacts === undefined) {
      return this._notAffectedStyle;
    }

    const impact = this._timeMode === TimeMode.Dynamic ? roadImpacts[this.currentTime] : Math.max(...Object.values(roadImpacts));
    if (impact === undefined) {
      return this._notAffectedStyle;
    }

    switch (impact) {
      case 3:
        return this._noAccessStyle;
      case 2:
        return this._limitedAccessStyle;
      case 1:
        return this._detourStyle;
      case 0:
      case undefined:
      default:
        return this._notAffectedStyle;
    }
  }

  private fetchRoadImpacts(): void {
    const newIds = this.getSource().getFeatures().map(f => +(f.getId() as string).substring(5)).filter(f => !this._fetchedIds.has(f));
    newIds.forEach(i => this._fetchedIds.add(i));
    this.scenarioService.getRoadImpacts$Json({ scenarioKey: this.scenario.key, body: newIds}).subscribe(
      i => {
        this._impacts = { ...this._impacts, ...i};
        this.changed();
      }
    );
  }
}
