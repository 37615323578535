import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import TopoJSON from 'ol/format/TopoJSON';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import Feature, {FeatureLike} from 'ol/Feature';
import * as _ from 'lodash';
import {FeatureFilterFunction} from '../models/FeatureFilterFunction';
import {FeatureSanitizationFunction} from '../models/FeatureSanitizationFunction';
import {LoggingService} from '../logging/logging.service';

/**
 * Service to load the features to be displayed on the map
 */
@Injectable({
  providedIn: 'root'
})
export class FeatureDataService {
  constructor(
    private http: HttpClient,
    private logger: LoggingService
  ) {}

  private readonly _cache: {[key: string]: Observable<Feature[]>} = {}; // cache results so they do not have to be loaded every time

  public getFeatureProperty(feature: FeatureLike, propertyName: string) {
    return _.isNil(feature) ? undefined : feature.get(propertyName);
  }

  public loadFeature$(
    url: string,
    featureSelectionPredicate: FeatureFilterFunction,
    filterFunction: FeatureFilterFunction = null,
    sanitizationFunction: FeatureSanitizationFunction = null,
    updateCache: boolean = false
  ): Observable<Feature> {
    return this.loadFeatures$(url, filterFunction, sanitizationFunction, updateCache)
      .pipe(map(features => _.find(features, featureSelectionPredicate)));
  }

  /**
   * @param url : url of the server where to get the data
   * @param filterFunction : Function used to filter the imported features
   * @param sanitizationFunction : Function used to sanitize (map) the imported features before they are used
   * @param updateCache : if true, the data is always reloaded from the server
   */
  public loadFeatures$(
    url: string,
    filterFunction: FeatureFilterFunction = null,
    sanitizationFunction: FeatureSanitizationFunction = null,
    updateCache: boolean = false
  ): Observable<Feature[]> {
    const cachedFeatures$ = this._cache[url];

    let features$: Observable<Feature[]>;

    if (!_.isNil(cachedFeatures$) && !updateCache) {
      features$ = cachedFeatures$;
    } else {
      const remoteFeatures$ = this._loadFeaturesFromRemote$(url);
      this._cache[url] = remoteFeatures$;
      features$ = remoteFeatures$;
    }

    features$ = this._filterFeatures$(features$, filterFunction);
    return this._sanitizeFeatures$(features$, sanitizationFunction);
  }

  private _loadFeaturesFromRemote$(url: string): Observable<Feature[]> {
    return this.http.get(url).pipe(
      map(data => new TopoJSON().readFeatures(data)),
      catchError(error => {
        this.logger.error(`Failed to load features from remote: '${url}'`, error);
        return [];
      })
    );
  }

  private _filterFeatures$(features$: Observable<Feature[]>, filterFunction: FeatureFilterFunction): Observable<Feature[]> {
    if (_.isNil(filterFunction)) {
      return features$;
    }

    return features$.pipe(map(features => _.filter(features, feature => filterFunction(feature))));
  }

  private _sanitizeFeatures$(features$: Observable<Feature[]>, sanitizationFunction: FeatureSanitizationFunction): Observable<Feature[]> {
    if (_.isNil(sanitizationFunction)) {
      return features$;
    }

    return features$.pipe(map(features => _.map(features, feature => sanitizationFunction(feature))));
  }
}
