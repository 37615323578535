import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NumberUtilityService {
  constructor() {}

  /**
   * @return the order of magnitude: the result is > 0 if "{@param value} > 1" and < 0 if ""{@param value} < 1"
   */
  getOrderOfMagnitude(value: number): number {
    const logarithm = Math.log10(Math.abs(value));
    return Math.floor(logarithm);   // e.g. 1.2 => 1 and -5.5 => -6 which is exactly what we want in this case
  }

  public getNumberDisplayLengthInPixel(value: number, unitLength: number, fontSize: number, em2ch: number): number {
    if (_.isNil(value)) {
      return 0;
    }

    const numberOfDigits = this.getOrderOfMagnitude(value) + 1;
    return (numberOfDigits + unitLength) * em2ch * fontSize;
  }

  /**
   * @return
   *   0 if {@param val1} equals {@param val2}
   *   a negative number if {@param val1} < {@param val2}
   *   a positive number if {@param val1} > {@param val2}
   */
  compareNumbers(val1: number, val2: number): number {
    if (val1 === val2) {
      return 0;
    }

    if (val1 < val2) {
      return -1;
    }

    if (val1 > val2) {
      return 1;
    }

    return null;
  }
}
