import { Observable, of } from 'rxjs';
import { TimeMode } from '../domain/time/time-mode.enum';

export class TimeServiceMaximumMock {
  get currentRealTimeFullHours$(): Observable<number> {
    return of(0);
  }
  get timeMode$(): Observable<TimeMode> {
    return of(TimeMode.Maximum);
  }
}
