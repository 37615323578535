<div class="additional-information-container">
  <div class="header-container">
    <div class="closer">
      <mat-icon (click)="onClose()">clear</mat-icon>
    </div>
  </div>
  <div class="body-container">
    <div class="text-container" [innerHTML]="content"></div>
  </div>
</div>
