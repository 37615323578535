import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'how-hideable',
  templateUrl: './hideable.component.html',
  styleUrls: ['./hideable.component.scss']
})
export class HideableComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  constructor() { }

  setVisibility(vis: boolean) {
    this.visible = vis;
    this.visibleChange.emit(vis);
  }
}
