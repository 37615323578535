'use strict';
import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

/*
  Generic info area which can shown or hidden via a button.
  This component is displayed at a fixed position on the left side of the screen.
  Content can be passed via ng-content.
 */
@Component({
  selector: 'mob-info-area-left',
  templateUrl: './info-area-left.component.html',
  styleUrls: ['./info-area-left.component.scss'],
  animations: [
    trigger('showInfo', [
      state('visible', style({ transform: 'translateX(0)' })),
      state('invisible', style({ transform: 'translateX(-100%)', width: 0 })),
      transition('visible => invisible', animate(300)),
      transition('invisible => visible', animate(300)),
      transition(':enter', [style({ transform: 'translateX(-100%)' })])
    ]),
    trigger('showLatch', [
      state('invisible', style({ transform: 'translateX(0)' })),
      state('visible', style({ transform: 'translateX(-100%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class InfoAreaLeftComponent {
  @Input() readonly logoPath: string;
  @Input() readonly removeBottomPadding: boolean;

  private _visible = true;

  constructor() {}

  get visibilityCss() {
    return this._visible ? 'visible' : 'invisible';
  }

  toggleVisibility() {
    this._visible = !this._visible;
  }
}
