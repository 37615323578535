<div *ngIf="activeFlooding$ | async as activeFlooding">
  <div class="toggle-label">
    <span translate>flooding</span>
    <mob-info-icon [compensateBaseline]="false" (clicked)="onClickHazardClassesInfoIcon($event)"></mob-info-icon>
  </div>

  <mat-button-toggle-group [value]="activeFlooding" (change)="floodingChange($event.value)" class="howadyn-toggle-group" [hideSingleSelectionIndicator]="true">
    <mat-button-toggle [value]="flooding.WaterDepth" class="howadyn-toggle-button">{{'waterDepth' | translate}}</mat-button-toggle>
    <mat-button-toggle [value]="flooding.HazardClasses" class="howadyn-toggle-button">{{'hazardClasses' | translate}}</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="legend" >
    <ng-container *ngIf="activeFlooding === flooding.WaterDepth">
      <mob-legend-panel-absolute *ngIf="flowDepthLegend$ | async as flowDepthLegend"
                                 [config]="flowDepthLegend"
      ></mob-legend-panel-absolute>
    </ng-container>
    <ng-container *ngIf="activeFlooding === flooding.HazardClasses">
      <how-legend-with-text *ngIf="hazardClassesLegend" [config]="hazardClassesLegend"
      ></how-legend-with-text>
    </ng-container>
  </div>
</div>

<how-hideable [visible]="impactIsVisible$ | async" (visibleChange)="impactVisibilityChange($event)">
  <span hideable-header class="hideable-header impact-label">{{(impactTranslationKey$ | async) | translate}}</span>

  <div hideable-body>
    <mat-select [value]="'streets'" [disabled]="true" [class]="'no-arrow'">
      <mat-option [value]="'streets'">
        {{'impact.streets.name' | translate}}
      </mat-option>
    </mat-select>

    <div class="legend">
      <how-legend-with-text *ngIf="streetsImpactsLegend" [config]="streetsImpactsLegend"></how-legend-with-text>
    </div>
  </div>
</how-hideable>


<div class="legend">
  <how-legend-with-text *ngIf="impactsLegend$ | async as impactsLegend" [config]="impactsLegend"></how-legend-with-text>
</div>
