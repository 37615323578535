import {ValueType} from '../../../enums/ValueType';
import {ILegendRectangleElement} from '../ILegendRectangleElement';
import {GeoUnit} from '../../../enums/GeoUnit';
import {ILegendCircleElement} from '../ILegendCircleElement';
import {ValueMappingFunction} from '../../ValueMappingFunction';

export interface ILegendDataConfiguration {
  valueType: ValueType;
  valueMappingFunction: ValueMappingFunction;

  title: string;

  svgDimension: {width, height};
  infoIconX: number;
  infoIconPosition: IconPosition;
  showInfoIcon: boolean;

  noValueDescription: string;
  noValueTooltip: string;

  rectangles: ILegendRectangleElement[];
  noValueRect: ILegendRectangleElement[];    // the rectangle symbolizing "no value"
}

export interface ILegendAbsoluteDataConfiguration extends ILegendDataConfiguration {
  geographicalUnit: GeoUnit;
  unit?: string;
  circles: ILegendCircleElement[];
}

export enum IconPosition {
  right = 'right',
  below = 'below'
}
