import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { LocalStorageAccessService } from '../../../../mobilab-common/services/local-storage-access.service';
import { LocaleService } from '../../../../mobilab-common/services/locale.service';
import { SessionStorageAccessService } from '../../../../mobilab-common/services/session-storage-access.service';
import { environment } from '../../../environments/environment';
import { CmsAccessHowadynService } from '../../domain/cms-access/cms-access-howadyn.service';
import { onboardingPath } from '../../domain/cms-access/grav-config';
import { IOnBoardingData, ON_BOARDING_DATA } from '../../domain/onboarding/IOnBoardingData';

@Component({
  selector: 'how-on-boarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnBoardingComponent implements OnInit {

  content: string;
  hideOnBoarding: boolean;
  pageNr: number;
  onboardingLanguage: string;

  constructor(
    private overlayRef: OverlayRef,
    private cmsAccessService: CmsAccessHowadynService,
    private localStorage: LocalStorageAccessService,
    private sessionStorage: SessionStorageAccessService,
    private router: Router,
    private localeservice: LocaleService,
    @Inject(ON_BOARDING_DATA) public onboardingData: IOnBoardingData,
    ) { }

  ngOnInit() {
      this.onboardingLanguage = this.getOnboardingLanguage();
      this.pageNr = this.onboardingData.initialStep;
      this._loadData$(onboardingPath + '/step_' + this.pageNr, this.onboardingLanguage);
  }

  buttonClicked() {
    if (this.pageNr === 1) {
      this.getSecondPage();
      this.pageNr += 1;
    } else {
      this.closeOnBoarding();
    }
  }

  getSecondPage() {
    this._loadData$(onboardingPath + '/step_2', this.onboardingLanguage);
  }

  closeOnBoarding() {
    this.sessionStorage.setProperty('onboardingHidden', true);
    this.overlayRef.dispose();
  }

  ignoreClick(event: MouseEvent) {
    event.stopPropagation();  // make sure it does not propagate to the background-div (closing the on-boarding)
  }

  private getOnboardingLanguage(): string {
    const allowedLanguages = ['de', 'fr', 'en'];
    if (allowedLanguages.includes(this.router.url.substring(1, 3))) {
      return this.router.url.substring(1, 3);
    } else {
      return this.localeservice.browserLanguage;
    }
  }

  private _loadData$(path: string, locale: string) {
    this.cmsAccessService.getContentFromCmsOrDefault$(environment.gravUrl, path, locale).subscribe((content) => this.content = content);
  }

  onHideInfoPopupChanged(change: MatSlideToggleChange) {
    const hide = change.checked;
    this.hideOnBoarding = hide;
    this.localStorage.setProperty('onboardingHidden', hide);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeOnBoarding();
  }
}
