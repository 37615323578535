import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GeoUnit} from '../../../enums/GeoUnit';
import {ILegendAbsoluteDataConfiguration} from '../../../models/legend/configuration/ILegendDataConfiguration';
import * as _ from 'lodash';
import {ValueType} from '../../../enums/ValueType';
import {IBoxClickEvent} from '../../../models/info-icon/IBoxClickEvent';
import {LocaleService} from '../../../services/locale.service';

@Component({
  selector: 'mob-legend-panel-absolute',
  templateUrl: './legend-generic-absolute.component.html',
  styleUrls: ['./legend-generic-absolute.component.scss']
})
export class LegendGenericAbsoluteComponent {
  @Input() readonly config: ILegendAbsoluteDataConfiguration;
  @Output() readonly noValueInfoIconClicked = new EventEmitter<IBoxClickEvent>();
  readonly geoUnitEnum = GeoUnit;

  constructor(private localeService: LocaleService) {}

  get valueUnit(): string {
    if (_.isEmpty(this.config.unit)) {
      return '';
    }

    return ` ${this.config.unit}`;
  }

  mapValueForDisplay(value: number): string {
    if (_.isNil(value)) {
      return '';
    }

    if (_.isNil(this.config.valueMappingFunction)) {
      return this.defaultValueMappingFunction(value);
    }

    return this.config.valueMappingFunction(value, ValueType.Absolute).displayValue;
  }

  defaultValueMappingFunction(value: number): string {
    return _.round(value).toLocaleString(this.localeService.currentLocale);
  }
}
