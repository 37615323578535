import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {IStaticMapConfiguration} from '../../../models/map/map-with-layers/IStaticMapConfiguration';
import Style, {StyleLike} from 'ol/style/Style';
import OlMap from 'ol/Map';
import {ILayerInformation} from '../../../models/map/map-with-layers/ILayerInformation';
import {MapWithLayersComponent} from '../map-with-layers/map-with-layers.component';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {MapDrawingService} from '../../../services/map-drawing.service';
import MapBrowserPointerEvent from 'ol/MapBrowserPointerEvent';
import * as _ from 'lodash';
import Feature, {FeatureLike} from 'ol/Feature';
import {IMapStylingConfiguration} from '../../../models/configuration/IMapStylingConfiguration';
import {Coordinate} from 'ol/coordinate';
import {IMapClickEvent} from '../../../models/map/map-mobilab/IMapClickEvent';
import {GeoUnit} from '../../../enums/GeoUnit';
import {ValueType} from '../../../enums/ValueType';
import StyleStroke from 'ol/style/Stroke';
import StyleFill from 'ol/style/Fill';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import {FeatureDataService} from '../../../services/feature-data.service';
import OverlayPositioning from 'ol/OverlayPositioning';
import {boundingExtent} from 'ol/extent';
import {Pixel} from 'ol/pixel';
import {FeatureStyleMode} from '../../../enums/FeatureStyleMode';
import {IDynamicMapConfiguration} from '../../../models/map/map-with-layers/IDynamicMapConfiguration';
import {IMobilabCommonConfig, MOBILAB_COMMON_CONFIG} from '../../../mobilab-common.config';
import {BoundingBoxOverrideService} from '../../../services/bounding-box/bounding-box-override.service';
import {MapPopupService} from '../../../services/permalink/map-popup.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {LoggingService} from '../../../logging/logging.service';
import {ISelectedFeatureData} from '../../../models/map/map-mobilab/ISelectedFeatureData';

/**
  An OpenLayers map specifically designed for use in mobilab-applications:
   - based on ///<reference path="MapWithLayersComponent"/>
   - takes care of the styling of features in overlays
   - takes care of handling hovering over features
   - takes care of handling click-events on features

  Does not have a specific positioning and can be put in an arbitrary container.
  Content can be passed via ng-content.
  It is, however, recommended to just set the map wo an absolute position with a low z-index to show other elements on top of it.

  Registers to several observables to allow external manipulation of some parts of its state:
   - {@see BoundingBoxOverrideService.boundingBoxStream$} to set a new bounding box
   - {@see MapPopupService.selectedFeatureOverride$} to select a new feature (and show its popup)
 */
@Component({
  selector: 'mob-map-mobilab',
  templateUrl: './map-mobilab.component.html',
  styleUrls: ['./map-mobilab.component.scss']
})
export class MapMobilabComponent implements OnDestroy {
  @ViewChild(MapWithLayersComponent, {static: true}) readonly mapComponent: MapWithLayersComponent;
  @ViewChild('mapTip', {static: true}) readonly mapTipElement: ElementRef;

  @Input() readonly staticMapConfig: IStaticMapConfiguration;

  @Input() set dynamicMapConfig(dynamicConfig: IDynamicMapConfiguration) {
    this._updateDynamicConfiguration(dynamicConfig);
  }

  @Output() readonly mapInitialized = new EventEmitter<OlMap>();
  @Output() readonly layerLoaded = new EventEmitter<ILayerInformation>();
  @Output() readonly mapClicked = new EventEmitter<IMapClickEvent>();
  @Output() readonly mapHovered = new EventEmitter<Feature>();

  dynamicConfig: IDynamicMapConfiguration;
  private stylingConfig: IMapStylingConfiguration;
  private initialLayerLoaded = false;

  private _hoveredFeature: Feature;
  private _selectedFeature: Feature;

  private _mapTip: Overlay;
  mapTipVisible = false;
  mapTipText: string;

  private readonly _selectedLayerChanged$ = new Subject<GeoUnit>();
  private readonly _subscriptions: Subscription[] = [];

  readonly layerStyleLike: StyleLike = [
    new Style({
      stroke: new StyleStroke({
        color: [120, 120, 120, 0.5],
        width: 1
      }),
      fill: new StyleFill({
        color: [100, 100, 100, 0.5],
      })
    })
  ];

  constructor(
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig,
    private boundingBoxService: BoundingBoxOverrideService,
    private drawingService: MapDrawingService,
    private featureDataService: FeatureDataService,
    private mapPopupService: MapPopupService,
    private logger: LoggingService,
  ) {}

  ngOnDestroy(): void {
    _.forEach(this._subscriptions, subscription => subscription.unsubscribe());
  }

  private _updateDynamicConfiguration(dynamicConfig: IDynamicMapConfiguration) {
    const selectedLayerChanged = _.isNil(this.dynamicConfig) || dynamicConfig.selectedLayer !== this.dynamicConfig.selectedLayer;

    this.dynamicConfig = dynamicConfig;
    this.stylingConfig = this._createStylingConfig;

    this._redrawContent(selectedLayerChanged);

    if (selectedLayerChanged) {
      this._selectedLayerChanged$.next(dynamicConfig.selectedLayer);
    }
  }

  // =====================================================================
  // =============== Public Interface for Parent Component ===============
  // =====================================================================

  public get map$(): Observable<OlMap> {
    return this.mapComponent.map$;
  }

  public getLayer(layerKey: GeoUnit): VectorLayer {
    return this.mapComponent.getLayer(layerKey);
  }

  /**
   * Clear all effects user-interaction with the map
   */
  public clearMapInteraction(): void {
    this._hoverOverFeature(undefined, undefined);
    this._selectFeature(undefined, undefined, true);
  }

  public zoom(delta: number) {
    this.mapComponent.zoom(delta);
  }

  public zoomToOverview() {
    this.mapComponent.zoomToOverview();
  }

  // =====================================================================
  // =========================== Initialization ==========================
  // =====================================================================

  private _initializeMap(olMap: OlMap) {
    olMap.updateSize();
    this._addMapListeners(olMap);

    this.mapInitialized.emit(olMap);
  }

  private _addMapListeners(olMap: OlMap) {
    this._addMapClickListener(olMap);
    this._addMapHoverListener(olMap);
  }

  private _initializeContent() {
    this.mapComponent.updateLayerVisibility(this.dynamicConfig.selectedLayer);
    this._updateFeatureStyles();
  }

  private _redrawContent(forceClearMapInteraction?: boolean) {
    this.mapComponent.updateLayerVisibility(this.dynamicConfig.selectedLayer);

    if (forceClearMapInteraction || this.staticMapConfig.clearMapInteractionsOnRedraw) {
      this.clearMapInteraction();
    }

    this._updateFeatureStyles();
  }

  private get _createStylingConfig(): IMapStylingConfiguration {
    return {
      styles: this.mobilabConfig.mapStylingConfiguration,
      geographicalUnit: this.dynamicConfig.selectedLayer,
      maxAbsoluteValue: this.dynamicConfig.maxAbsoluteValue,
      valueType: this.dynamicConfig.valueType
    };
  }

  /**
   * Retrieve feature at given pixel. In contrast to map.getFeaturesAtPixel(),
   * which also looks how the feature is rendered, this one only considers the geometry of
   * features in the selected layer.
   */
  private _getFeatureAtPixel(olMap: OlMap, pixel: Pixel): Feature {
    const pixel2 = olMap.getCoordinateFromPixel(pixel);
    pixel2[0] += 1;
    pixel2[1] += 1;
    const extent = boundingExtent([pixel2, olMap.getCoordinateFromPixel(pixel)]);
    const layer = this.mapComponent.getLayer(this.dynamicConfig.selectedLayer);
    return layer.getSource().forEachFeatureIntersectingExtent(extent, x => x);
  }

  private _addMapClickListener(olMap: OlMap): void {
    olMap.on('singleclick', (evt: MapBrowserPointerEvent) => {
      const pixel = evt.pixel;
      const selectedFeature = this._getFeatureAtPixel(olMap, pixel);

      if (_.isNil(selectedFeature)) {
        this._selectFeature(undefined, undefined);
      } else {
        this._selectFeature(selectedFeature, evt.coordinate);
      }
    });
  }

  private _addMapHoverListener(olMap: OlMap): void {
    this._initializeMapTipOverlay();

    olMap.addOverlay(this._mapTip);

    olMap.on('pointermove', (evt: MapBrowserPointerEvent) => {
      const pixel = evt.pixel;
      const hoveredFeature = this._getFeatureAtPixel(olMap, pixel);

      if (_.isNil(hoveredFeature)) {
        this._hoverOverFeature(undefined, undefined);
      } else {
        this._hoverOverFeature(hoveredFeature, evt.coordinate);
      }
    });
  }

  private _addBoundingBoxListener(olMap: OlMap) {
    this._subscriptions.push(this.boundingBoxService.boundingBoxStream$.subscribe(boundingBox => {
      if (!_.isNil(boundingBox)) {
        olMap.getView().fit(boundingBox, { duration: 700 });
      }
    }));
  }

  private _initializeMapTipOverlay() {
    this._mapTip = new Overlay({
      element: this.mapTipElement.nativeElement,
      offset: [0, 23],
      positioning: OverlayPositioning.TOP_LEFT
    });
  }

  // =====================================================================
  // =========================== Interactions ============================
  // =====================================================================

  onLayerAdded(layerKey: GeoUnit) {
    if (layerKey === this.dynamicConfig.selectedLayer) {
      this._initializeContent();  // the data is not loaded by OpenLayers until the layer is drawn
    }
  }

  onLayerLoaded(layerInformation: ILayerInformation) {
    if (!this.initialLayerLoaded) {
      this.initialLayerLoaded = true;
      this.map$.subscribe(olMap => {
        this._addBoundingBoxListener(olMap);  // cannot add this at onMapInitialized() already because that would miss an initial zoom
      });
    }

    this.layerLoaded.emit(layerInformation);
  }

  onUnderlyingMapInitialized(olMap: OlMap) {
    this._initializeMapPopupOverrideListener();
    this._initializeContent();
    this._initializeMap(olMap);
  }

  /**
   * Beware: the listener does not need the map directly, but it needs it to be initialized!
   * Beware: if map-popups are closed on every change, this listener needs to be initialized after the rest of the map is initialized.
   */
  private _initializeMapPopupOverrideListener() {
    let clickedCoordinates: Coordinate;

    this._subscriptions.push(this.mapPopupService.selectedFeatureOverride$
      .pipe(
        switchMap(selectedFeatureData => this._waitForCorrectLayer$(selectedFeatureData)),
        filter(selectedFeatureData => !_.isNil(selectedFeatureData)),
        tap(selectedFeatureData => clickedCoordinates = selectedFeatureData.clickedCoordinates),
        switchMap(selectedFeatureData => this._loadFeatureById$(selectedFeatureData.selectedFeatureId)),
        filter(selectedFeature => !_.isNil(selectedFeature)),
      )
      .subscribe(selectedFeature => {
        this._selectFeature(selectedFeature, clickedCoordinates, false);
      }, error => {
        this.logger.error('Failed to override map-popup.', error);
      })
    );
  }

  /**
   * If the selected feature is of another layer, wait for the next change in the layer.
   * This is necessary, because the layer-change may arrive after the selected-feature override...
   */
  private _waitForCorrectLayer$(selectedFeatureData: ISelectedFeatureData): Observable<ISelectedFeatureData> {
    if (selectedFeatureData.geoUnit === this.dynamicConfig.selectedLayer) {
      return of(selectedFeatureData);
    }

    return this._selectedLayerChanged$.pipe(
      filter(newGeoUnit => newGeoUnit === selectedFeatureData.geoUnit),
      map(() => selectedFeatureData),
    );
  }

  private _loadFeatureById$(featureId: number): Observable<Feature> {
    const selectedLayer = this.dynamicConfig.selectedLayer;
    const layerUrl = this.staticMapConfig.layerUrls[selectedLayer];

    return this.featureDataService.loadFeature$(layerUrl, feature =>
      this.staticMapConfig.getFeatureId(feature) === featureId
    );
  }

  private _showMapTip(feature: Feature, coordinate: Coordinate) {
    this.mapTipVisible = true;
    this._mapTip.setPosition(coordinate);
    const prefix = this.dynamicConfig.hoverPrefix || '';
    this.mapTipText = prefix + this.featureDataService.getFeatureProperty(feature, this.dynamicConfig.propertyNameFeatureName);
  }

  private _hideMapTip() {
    this.mapTipVisible = false;
  }

  private _selectFeature(selectedFeature?: Feature, clickedCoordinates?: Coordinate, isClearingAction?: boolean) {
    // remember current selected feature and click coordinates
    const previousSelectedFeature = this._selectedFeature;
    this._selectedFeature = selectedFeature;

    // update feature style to appear selected
    const selectedFeatureId = this.staticMapConfig.getFeatureId(selectedFeature);
    const previousFeatureId = this.staticMapConfig.getFeatureId(previousSelectedFeature);

    if (previousFeatureId !== selectedFeatureId) {
      if (previousSelectedFeature) {
        this._updateFeatureStyle(previousSelectedFeature);
      }
      if (selectedFeature) {
        this._updateFeatureStyle(selectedFeature, FeatureStyleMode.selected);
      }
    }

    // avoid messy overlaps between map-tip and popup
    if (!_.isNil(selectedFeature)) {
      this._hideMapTip();
    }

    // inform popup-service
    if (_.isNil(selectedFeature)) {
      this.mapPopupService.selectedFeature = null;
    } else {
      this.mapPopupService.selectedFeature = {
        selectedFeatureId: this.staticMapConfig.getFeatureId(selectedFeature),
        clickedCoordinates: clickedCoordinates,
        geoUnit: this.dynamicConfig.selectedLayer,
      };
    }

    // inform parent
    const popupVisible = !_.isNil(selectedFeature);

    this.mapClicked.emit({
      selectedFeature: selectedFeature,
      clickedCoordinates: clickedCoordinates,
      popupVisible: popupVisible,
      isClearingEvent: isClearingAction
    });
  }

  private _hoverOverFeature(hoveredFeature?: Feature, hoverCoordinates?: Coordinate) {
    // remember current hovered feature
    const previousHoveredFeature = this._hoveredFeature;
    this._hoveredFeature = hoveredFeature;

    // update feature style to appear hovered
    const hoveredFeatureId = this.staticMapConfig.getFeatureId(hoveredFeature);
    const previousFeatureId = this.staticMapConfig.getFeatureId(previousHoveredFeature);
    const selectedFeatureId = this.staticMapConfig.getFeatureId(this._selectedFeature);

    if (previousFeatureId !== hoveredFeatureId) {
      // revert previous feature to normal style except if feature is currently selected
      if (previousHoveredFeature && previousFeatureId !== selectedFeatureId) {
        this._updateFeatureStyle(previousHoveredFeature);
      }
      // revert current feature to hovered style except if feature is currently selected
      if (hoveredFeature && hoveredFeatureId !== selectedFeatureId) {
        this._updateFeatureStyle(hoveredFeature, FeatureStyleMode.hovered);
      }
    }

    // manage map tip
    if (hoveredFeature) {
      this._showMapTip(hoveredFeature, hoverCoordinates);
    } else {
      this._hideMapTip();
    }

    // inform parent
    this.mapHovered.emit(hoveredFeature);
  }

  // =====================================================================
  // ============================== Styling ==============================
  // =====================================================================

  renderOrder = (feature1: FeatureLike, feature2: FeatureLike) => {
    return this.drawingService.getRenderOrder(
      feature1, feature2, this._hoveredFeature, this._selectedFeature, this.staticMapConfig.getFeatureId
    );
  }

  private _updateFeatureStyles() {
    const layer = this.mapComponent.getLayer(this.dynamicConfig.selectedLayer);
    if (layer) {
      return layer.getSource().forEachFeature(feature => this._updateFeatureStyle(feature));
    }
  }

  private _updateFeatureStyle(feature: Feature, featureStyleMode: FeatureStyleMode = FeatureStyleMode.normal) {
    feature.setStyle(this._getStyles(feature, featureStyleMode));
  }

  private _getStyles(feature: Feature, featureStyleMode: FeatureStyleMode): Style[] {
    const styles = [
      ...this._getPolygonStyles(feature),
      ...this._getPointStyles(feature)
    ];

    if (featureStyleMode === FeatureStyleMode.hovered) {
      styles.push(this.drawingService.getFeatureHoverStyle(this.mobilabConfig.mapStylingConfiguration));
    } else if (featureStyleMode === FeatureStyleMode.selected) {
      styles.push(this.drawingService.getFeatureSelectStyle(this.mobilabConfig.mapStylingConfiguration));
    }

    return styles;
  }

  private _getPointStyles(feature: Feature): Style[] {
    const styles: Style[] = [];

    const showFeature = this.staticMapConfig.showFeature(feature);
    if (!showFeature) {
      return styles;
    }

    if (this.dynamicConfig.selectedLayer === GeoUnit.hexagon) {
      return styles; // do not draw points/circles on hexagons
    }

    if (this.dynamicConfig.valueType === ValueType.Relative) {
      return styles; // do not draw points/circles for relative values
    }

    const {absoluteValue, relativeValue} = this.staticMapConfig.computationFunction(feature);

    if (!absoluteValue) {
      return styles;  // do not draw a circle if there is no value > 0
    }

    styles.push(this.drawingService.getCircleStyle(this.stylingConfig, feature, absoluteValue, relativeValue));
    return styles;
  }

  private _getPolygonStyles(feature: Feature): Style[] {
    const styles: Style[] = [];

    const showFeature = this.staticMapConfig.showFeature(feature);

    styles.push(new Style({
      stroke: new StyleStroke({
        color: this.mobilabConfig.mapStylingConfiguration.polygonOutlineColor
      })
    }));

    const {absoluteValue, relativeValue} = this.staticMapConfig.computationFunction(feature);
    styles.push(this.drawingService.getPolygonStyle(this.stylingConfig, absoluteValue, relativeValue, showFeature));
    return styles;
  }
}
