import { Component } from '@angular/core';

@Component({
  selector: 'how-not-supported-browser-warning',
  templateUrl: './not-supported-browser-warning.component.html',
  styleUrls: ['./not-supported-browser-warning.component.scss']
})
export class NotSupportedBrowserWarningComponent {

}
