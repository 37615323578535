import {Inject, Injectable} from '@angular/core';
import {IMunicipalitySearchResult} from '../models/municipality-search/IMunicipalitySearchResult';
import {CoordinateProjection} from '../enums/CoordinateProjection';
import {LoggingService} from '../logging/logging.service';
import {Extent} from 'ol/extent';
import {BoundingBoxOverrideService} from './bounding-box/bounding-box-override.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {IMunicipalitySearchResponse} from '../models/municipality-search/IMunicipalitySearchResponse';
import {IMobilabCommonConfig, MOBILAB_COMMON_CONFIG} from '../mobilab-common.config';
import {Coordinate} from 'ol/coordinate';
import {CoordinatesTransformationService} from './coordinates-transformation.service';

/**
 * Service to search for Swiss municipalities.
 * {@see MunicipalitySearchComponent}
 */
@Injectable({
  providedIn: 'root'
})
export class MunicipalitySearchService {
  constructor(
    private http: HttpClient,
    private boundingBoxService: BoundingBoxOverrideService,
    private coordinatesTransformation: CoordinatesTransformationService,
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig,
  ) {}

  // =====================================================================
  // ======================= Search by Coordinates =======================
  // =====================================================================

  searchForMunicipalityByCoordinates$(coordinatesLv95: Coordinate): Observable<IMunicipalitySearchResponse> {
    const boundingBox: Extent = [
      coordinatesLv95[0],
      coordinatesLv95[1],
      coordinatesLv95[0] + 1,
      coordinatesLv95[1] + 1,
    ];

    const query = _.join(boundingBox, ',');

    const url = this.mobilabConfig.municipalitySearchByBoundingBoxUrl + query;
    return this.http.get<IMunicipalitySearchResponse>(url);
  }

  // =====================================================================
  // ========================== Search by Name ===========================
  // =====================================================================

  searchForMunicipalityByName$(query: string): Observable<IMunicipalitySearchResponse> {
    const url = this.mobilabConfig.municipalitySearchByNameUrl + query;
    return this.http.get<IMunicipalitySearchResponse>(url);
  }

  updateBoundingBox(result: IMunicipalitySearchResult): void {
    const boundingBox = this._getBoundingBox(result);
    this.boundingBoxService.updateBoundingBox(boundingBox);
  }

  private _getBoundingBox(searchResult: IMunicipalitySearchResult): Extent {
    // Regex to extract all floating-point numbers from string
    const boundingBox = <Extent>searchResult.attrs.geom_st_box2d.match(/[+-]?\d+(\.\d+)?/g).map(Number);

    try {
      this._validateBoundingBox(boundingBox);
      return this.coordinatesTransformation.transformExtent(boundingBox, CoordinateProjection.EPSG2056, CoordinateProjection.EPSG3857);
    } catch (e) {
      // An error being thrown probably means that "Projection" has not been properly initialized!
      const innerErrorMessage = LoggingService.createErrorMessage(e);
      throw Error(`Failed to transform coordinates for bounding box: '${innerErrorMessage}'`);
    }
  }

  private _validateBoundingBox(boundingBox: Extent) {
    if (_.isEmpty(boundingBox)) {
      throw Error('Bounding-Box must not be null/empty.');
    }

    if (_.size(boundingBox) !== 4) {
      throw Error('Bounding-Box must have exactly 4 elements.');
    }

    const boundingBoxAsString = JSON.stringify(boundingBox);

    _.forEach(boundingBox, elem => {
      if (_.isNil(elem)) {
        throw Error(`Bounding-Box must not contain null-values: ${boundingBoxAsString}`);
      }

      if (!_.isFinite(elem) || _.isNaN(elem)) {
        throw Error(`Bounding-Box must only contain finite numbers: ${boundingBoxAsString}`);
      }
    });
  }
}
