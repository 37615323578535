import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Impact } from '../../shared/enums/Impact';
import { MapMode } from '../../shared/enums/MapMode';

@Component({
  selector: 'how-detail-content',
  templateUrl: './detail-content.component.html',
  styleUrls: ['./detail-content.component.scss']
})
export class DetailContentComponent {
  @Input() impact: Impact;
  @Input() mapMode: MapMode;
  @Input() data$: Observable<Object>;

  impactKey: string;

  @Input() floodplainKey: string | undefined;
  mapModes = MapMode;
  streets = [Impact.StreetsNotUsed, Impact.StreetsLimitedlyUsed, Impact.StreetsWithEvasiveTraffic];

  constructor() { }
}
