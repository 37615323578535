import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { MenuType } from '../../../mobilab-common/enums/MenuType';
import { RoutingHelperService } from '../../../mobilab-common/services/routing/routing-helper.service';
import { ScenarioService } from '../domain/scenario/scenario.service';
import { ImpactValueType } from '../openapi/models/impact-value-type';
import { Scenario } from '../openapi/models/scenario';
import { ScenariosService } from '../openapi/services';
import { Impact } from '../shared/enums/Impact';
import { MapMode } from '../shared/enums/MapMode';
import { StatisticsTrackerService } from '../../../mobilab-common/services/statistics-tracker.service';

@Component({
  selector: 'how-detail-page-flowlines',
  templateUrl: './detail-page-flowlines.component.html',
  styleUrls: ['./detail-page-flowlines.component.scss']
})
export class DetailPageFlowlinesComponent implements OnInit, OnDestroy {
  scenario: Scenario;
  impact: Impact;
  tableValues$: Observable<any>;
  graphData$: Observable<any>;
  flowlineKey: string;
  mapMode = MapMode.Floodplain;
  subscriptions = [];
  activeMenuType: MenuType;

  constructor(
    private translate: TranslateService,
    private readonly scenarioService: ScenarioService,
    private readonly scenariosService: ScenariosService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routingHelper: RoutingHelperService,
    private readonly statisticsTracker: StatisticsTrackerService,
  ) {
    this.routingHelper.addPreviousRoute(this.routingHelper.relativeRoute);
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot) {
      this.flowlineKey = this.activatedRoute.snapshot.params.flowlineId;
      this.statisticsTracker.trackInternalPage(this.routingHelper.relativeRoute);
    }
    this.subscriptions.push(this.scenarioService.currentScenario$.subscribe(scenario => {
      this.scenario = scenario;
      this.tableValues$ = this.scenariosService.apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Json(
        { 'scenarioKey': scenario.key, 'floodplainKey': this.flowlineKey }
      );
      const rawLineData$ = this.scenariosService.apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Json({
        scenarioKey: this.scenario.key,
        floodplainKey: this.flowlineKey,
        valueType: ImpactValueType.Aggregated
      });
      const rawBarData$ = this.scenariosService.apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Json({
        scenarioKey: this.scenario.key,
        floodplainKey: this.flowlineKey,
        valueType: ImpactValueType.Actual
      });
      this.graphData$ = forkJoin([rawLineData$, rawBarData$]);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  currentLang(): string {
    return this.translate.currentLang;
  }

  changeLanguage(language: string) {
    return this.translate.use(language);
  }

  impactChange(value: Impact) {
    this.impact = value;
  }

  onMenuOpened(menuType: MenuType): void {
    this.activeMenuType = menuType;
  }
}
