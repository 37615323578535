import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {MenuType} from '../../enums/MenuType';
import {MatSidenav} from '@angular/material/sidenav';
import {BrowserAndDeviceService} from '../../services/browser-and-device.service';
import {MOBILAB_COMMON_CONFIG, IMobilabCommonConfig} from '../../mobilab-common.config';

/*
  Main container of all the content to be displayed within the application.
  Contains the following elements:
   - Buttons to open the application-specific menu and the menu showing other applications.
   - A scaffold to show those menus. Their content can be passed via ng-content using the css-class "mob-main-container-sidenav".
   - A center content which can be passed via ng-content using the css-class "mob-main-container-content".
   - A footer with a copyright-note and a feedback button.
 */
@Component({
  selector: 'mob-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent {
  @ViewChild('sidenav', {static: true}) sideNav: MatSidenav;

  @Input() readonly appTitle: string;
  @Input() readonly closeMenuOnBackgroundClick: boolean;
  @Input() readonly hideFooter: boolean;


  @Input() readonly footerTooltipTranslation: string;
  @Input() readonly footerCopyrightTranslation: string;
  @Input() readonly footerAboutUsTranslation: string;

  @Input() readonly localeCode: string;
  @Output() readonly localeCodeChange = new EventEmitter<string>();

  @Output() readonly menuOpened = new EventEmitter<MenuType>();
  @Output() readonly menuClosed = new EventEmitter<void>();

  readonly menuTypeEnum = MenuType;

  isMoreToolsMenuOpen = false;

  constructor(
    private mediaMatcherService: BrowserAndDeviceService,
    @Inject(MOBILAB_COMMON_CONFIG) public readonly mobilabConfig: IMobilabCommonConfig,
  ) {}

  get isMobile(): boolean {
    return this.mediaMatcherService.isMobile;
  }

  async onMenuOpened(menuType: MenuType): Promise<void> {
    this.isMoreToolsMenuOpen = (menuType === MenuType.MoreToolsMenu);
    this.menuOpened.emit(menuType);
    await this.sideNav.toggle();
  }

  async onMenuClosed(): Promise<void> {
    if (this.sideNav.opened) {
      await this.sideNav.close();
      this.isMoreToolsMenuOpen = false;
      this.menuClosed.emit();
    }
  }

  async onBackgroundClicked() {
    if (this.closeMenuOnBackgroundClick) {
      await this.onMenuClosed();
    }
  }
}
