import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { ScenarioService } from '../scenario/scenario.service';
import { TimeMode } from './time-mode.enum';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  private readonly framesPerSecond = 2;

  private _currentVideoTime = new BehaviorSubject(0);
  private _isPlaying = new BehaviorSubject(false);
  private _timeMode = new BehaviorSubject(TimeMode.Dynamic);

  private _currentRealTimeFullHours: Observable<number>;
  private _currentVideoTimeWhilePaused: Observable<number>;

  private wasPlayingWhenChangedToMaxMode: boolean;

  constructor(private scenarioService: ScenarioService) {
    setTimeout(() => this._isPlaying.next(true), 2000);
    this.stopVideosForMaxMode();

    this._currentRealTimeFullHours = this.currentRealTime$.pipe(map(x => Math.floor(x)), distinctUntilChanged());
    this._currentVideoTimeWhilePaused = this.currentVideoTime$.pipe(
      skip(1),
      filter(() => !this.isPlaying),
      distinctUntilChanged(),
      debounceTime(100),
    );

    this.scenarioService.currentScenario$.subscribe(_ => {
      this.isPlaying = false;
      this.currentVideoTime = 0;
      setTimeout(() => {
        this.isPlaying = true;
      }, 1000);
    });
  }

  get currentVideoTime$(): Observable<number> {
    return this._currentVideoTime.asObservable().pipe(distinctUntilChanged());
  }

  get currentVideoTime(): number {
    return this._currentVideoTime.value;
  }

  set currentVideoTime(value: number) {
    this._currentVideoTime.next(value);
  }

  get currentRealTime$(): Observable<number> {
    return this._currentVideoTime.asObservable().pipe(distinctUntilChanged(), map(x => x * this.framesPerSecond));
  }

  get currentRealTimeFullHours$(): Observable<number> {
    return this._currentRealTimeFullHours;
  }

  get currentRealTime(): number {
    return this._currentVideoTime.value * this.framesPerSecond;
  }

  set currentRealTime(value: number) {
    this._currentVideoTime.next(value / this.framesPerSecond);
  }

  get isPlaying$(): Observable<boolean> {
    return this._isPlaying.asObservable();
  }

  get isPlaying(): boolean {
    return this._isPlaying.value;
  }

  set isPlaying(value: boolean) {
    this._isPlaying.next(value);
  }

  get timeMode$(): Observable<TimeMode> {
    return this._timeMode.asObservable();
  }

  set timeMode(timeMode: TimeMode) {
    this._timeMode.next(timeMode);
  }

  get timeMode(): TimeMode {
    return this._timeMode.value;
  }

  get duration$(): Observable<number> {
    return this.scenarioService.currentScenario$.pipe(map(scenario => scenario.modelledDuration));
  }

  get currentVideoTimeWhilePaused$(): Observable<number> {
    return this._currentVideoTimeWhilePaused;
  }

  private stopVideosForMaxMode() {
    this.timeMode$.pipe(distinctUntilChanged()).subscribe(
      (timeMode: TimeMode) => {
        if (timeMode === TimeMode.Maximum) {
          this.wasPlayingWhenChangedToMaxMode = this.isPlaying;
          this.isPlaying = false;
          return;
        }

        this.isPlaying = this.wasPlayingWhenChangedToMaxMode;
      });
  }
}
