import { VideoLayer } from '../map/video-layer';
import { Options as VideoLayerOptions } from '../map/video-layer';
import { ImageStatic } from 'ol/source';
import * as Projection from 'ol/proj';
import { CoordinateProjection } from '../../../../mobilab-common/enums/CoordinateProjection';
import { environment } from '../../../environments/environment';
import { DynamicLayer } from '../time/dynamic-layer';
import { Flooding } from '../../shared/enums/Flooding';
import { Extent } from 'ol/extent';
import OlMap from 'ol/Map';
import { TimeService } from '../time/time.service';
import { Scenario } from '../../openapi/models/scenario';

export interface Options extends VideoLayerOptions {
  flooding: Flooding;
  floodplainKey: string;
  scenario: Scenario;
}

export class FloodingVideoLayer extends VideoLayer implements DynamicLayer {
  private readonly _flooding: Flooding;

  constructor(options: Options, map: OlMap, timeService: TimeService) {
    super(options, map, timeService);
    this._flooding = options.flooding;

    this.setExtent(options.extent);
    this.setSource(new ImageStatic({
      url: 'assets/blankLayer.png',
      projection: Projection.get(CoordinateProjection.EPSG3857),
      imageExtent: options.extent as Extent,
    }));
    this.videoSourcePath = `${environment.gravUrl}/user/pages/howadyn_scenarios/${options.scenario.key.toLowerCase()}/${options.floodplainKey}_${this.filename}`;
  }

  private get filename(): string {
    switch (this._flooding) {
      case Flooding.WaterDepth:
        return 'flowdepth.webm';
      case Flooding.HazardClasses:
        return 'hazclass.webm';
      default:
        console.warn('Flooding Video File unknown.');
    }
  }
}
