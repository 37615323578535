<div class="legend-panel">
  <div class="legend-panel-title">
    <ng-content select=".mob-legend-panel-title"></ng-content>
  </div>

  <div class="legend-panel-content">
    <div *ngIf="maxValue && valueType != valueTypeEnum.Relative" class="legend" [ngClass]="dynamicClass">
      <mob-legend-panel-absolute
        [config]="legendAbsoluteConfig"
        (noValueInfoIconClicked)="noValueInfoIconClicked.emit($event)"
      ></mob-legend-panel-absolute>
    </div>

    <div *ngIf="valueType != valueTypeEnum.Absolute" class="legend" [ngClass]="dynamicClass">
      <mob-legend-panel-relative
        [config]="legendRelativeConfig"
        (noValueInfoIconClicked)="noValueInfoIconClicked.emit($event)"
      ></mob-legend-panel-relative>
    </div>
  </div>
</div>
