import { Component, Input } from '@angular/core';
import { MenuType } from '../../../../../mobilab-common/enums/MenuType';

@Component({
  selector: 'how-menu',
  templateUrl: './menu.howadyn.component.html',
  styleUrls: ['./menu.howadyn.component.scss']
})
export class MenuHowadynComponent {
  @Input() readonly menuType: MenuType;
  readonly menuTypeEnum = MenuType;
}
