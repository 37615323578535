<ng-container *ngIf="scenario">
  <div class="scenario">
    <div class="scenario-info">
      <div class="scenario-label">{{'scenario' | translate}}</div>
      <div class="scenario-name">{{('scenarioName.' + scenario.key) | translate}}</div>
    </div>
    <div class="horizontal">
      <div class="vertical">
        <div>{{'duration' | translate}}</div>
        <div class="bold"><label class="amount">{{scenario.modelledDuration / 24 - 3 | valueDisplay}}</label> {{'days' | translate}}</div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="vertical">
        <div>{{'mean' | translate}}</div>
        <div class="bold"><label class="amount">{{scenario.precipitationSum | valueDisplay}}</label> {{'mm' | translate}}</div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="vertical">
        <div>{{'maximum' | translate}}</div>
        <div class="bold"><label class="amount">{{scenario.precipitationMaximumIntensity | valueDisplay}}</label> {{'mm' | translate}}</div>
      </div>
    </div>
  </div>

  <a
    routerLink="{{redirectUrl}}"
    class="go-back-cross"
    (click)="resetMapExtent()">
    <mat-icon class="cross-icon">close</mat-icon>
  </a>
</ng-container>
