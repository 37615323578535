import {Inject, Injectable} from '@angular/core';
import {MOBILAB_COMMON_CONFIG, IMobilabCommonConfig} from '../../mobilab-common.config';
import {ParamMap} from '@angular/router';
import {commonRouteParams} from '../../common-routing-constants';
import * as _ from 'lodash';
import {ILocaleRoutingData} from '../../models/ILocaleRoutingData';

/**
 * Service to help with actions concerning the interaction of the current route and the currently selected locale of the app.
 */
@Injectable({
  providedIn: 'root'
})
export class LocaleRoutingService {
  private readonly languageToLocaleMap: {[key: string]: string};
  private readonly defaultLanguage: string;

  constructor(
    @Inject(MOBILAB_COMMON_CONFIG) mobilabConfig: IMobilabCommonConfig,
  ) {
    this.languageToLocaleMap = mobilabConfig.languageToLocaleMap;
    this.defaultLanguage = mobilabConfig.defaultLanguage;
  }

  public getLocaleFromRouteParams(paramMap: ParamMap): ILocaleRoutingData {
    const language = paramMap.get(commonRouteParams.languageId) || '';

    const locale = this.languageToLocaleMap[language];
    if (!_.isEmpty(locale)) {
      return {
        language,
        locale,
        routeNeedsChanging: false,  // already at a valid route
      };
    }

    const browserLanguage = this._getBrowserLanguage();
    const browserLocale = this.languageToLocaleMap[browserLanguage];

    return {
      language: browserLanguage,
      locale: browserLocale,
      routeNeedsChanging: true,
    };
  }

  private _getBrowserLanguage(): string {
    const browserLanguage: string = _.get(navigator, ['languages', 0]) || navigator.language || (navigator as any).userLanguage;

    const languageKeys = _.keys(this.languageToLocaleMap);
    const matchingKeys = _.filter(languageKeys, language => _.includes(browserLanguage, language));

    return _.first(matchingKeys) || this.defaultLanguage;
  }
}
