import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import localeAmericanEnglish from '@angular/common/locales/fr-CH';
import localeSwissFrench from '@angular/common/locales/en';
import localeSwissGerman from '@angular/common/locales/de-CH';

import { ApiModule } from './openapi/api.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ControlFlowlinesComponent } from './scenario-page/control-mapmode/control-flowlines/control-flowlines.component';
import { ControlMapModeComponent } from './scenario-page/control-mapmode/control-mapmode.component';
import { ControlSwitzerlandComponent } from './scenario-page/control-mapmode/control-switzerland/control-switzerland.component';
import { HideableComponent } from './shared/components/hideable/hideable.component';
import { MOBILAB_COMMON_CONFIG } from '../../mobilab-common/mobilab-common.config';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MainHeaderComponent } from './shared/components/main-header/main-header.component';
import { MainMapComponent } from './scenario-page/main-map/main-map.component';
import { MapComponent } from './shared/components/map/map.component';
import { MaterialModule } from '../../mobilab-common/material/material.module';
import { MobilabCommonModule } from '../../mobilab-common/mobilab-common.module';
import { ScalelineComponent } from './scenario-page/main-map/scaleline/scaleline.component';
import { ScenarioPageComponent } from './scenario-page/scenario-page.component';
import { TimeSliderComponent } from './scenario-page/time-slider/time-slider.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VideoTimeSpanPipe } from './shared/pipes/video-time-span.pipe';
import { CopyrightComponent } from './scenario-page/main-map/copyright/copyright.component';
import { ScenarioCardComponent } from './landing-page/scenario-card/scenario-card.component';
import { TooltipComponent } from './scenario-page/main-map/tooltip/tooltip.component';
import { LegendWithTextComponent } from './shared/components/legend-with-text/legend-with-text.component';
import { mobilabCommonConfig } from './mobilab.config';
import { PopUpComponent } from './scenario-page/main-map/pop-up/pop-up.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { DetailHeaderComponent } from './detail-page/detail-header/detail-header.component';
import { DetailTableComponent } from './detail-page/detail-table/detail-table.component';
import { DetailContentComponent } from './detail-page/detail-content/detail-content.component';
import { DetailGraphComponent } from './detail-page/detail-content/detail-graph/detail-graph.component';
import { ImpactTranslatePipe } from './domain/map-mode-switzerland/impact-translate.pipe';
import { DetailMapSwitzerlandComponent } from './detail-page/detail-content/detail-map-switzerland/detail-map-switzerland.component';
import { FormsModule } from '@angular/forms';
import { ValueDisplayPipe } from './shared/pipes/value-display.pipe';
import { ImpactUnitPipe } from './domain/map-mode-switzerland/impact-unit.pipe';
import { InfoPopupBaseComponent } from './scenario-page/info-popup/info-popup-base/info-popup-base.component';
import { InfoPopupSwitzerlandComponent } from './scenario-page/info-popup/implementations/info-popup-switzerland/info-popup-switzerland.component';
import { InfoPopupPrecipitationComponent } from './scenario-page/info-popup/implementations/info-popup-precipitation/info-popup-precipitation.component';
import { InfoPopupFlowlinesComponent } from './scenario-page/info-popup/implementations/info-popup-flowlines/info-popup-flowlines.component';
import { InfoPopupHazardClassesComponent } from './scenario-page/info-popup/implementations/info-popup-hazard-classes/info-popup-hazard-classes.component';
import { InfoPopupUnmodelledFlowlinesComponent } from './scenario-page/info-popup/implementations/info-popup-unmodelled-flowlines/info-popup-unmodelled-flowlines.component';
import { InfoPopupDetourRoadsComponent } from './scenario-page/info-popup/implementations/info-popup-detour-roads/info-popup-detour-roads.component';
import { DetailMapFlowlinesComponent } from './detail-page/detail-content/detail-map-flowlines/detail-map-flowlines.component';
import { DetailPageFlowlinesComponent } from './detail-page/detail-page-flowlines.component';
import { environment } from '../environments/environment';
import { MenuHowadynComponent } from './shared/components/menu/menu.howadyn.component';
import { HowadynApplicationMenuComponent } from './shared/components/menu/application-menu/application-menu.component';
import { HowadynMoreToolsMenuComponent } from './shared/components/menu/more-tools-menu/more-tools-menu.component';
import { AdditionalInformationService } from '../../mobilab-common/services/additional-information.service';
import { RoutingHelperService } from '../../mobilab-common/services/routing/routing-helper.service';
import { LoggingService } from '../../mobilab-common/logging/logging.service';
import { AdditionalInformationHowadynService } from './domain/cms-access/additional-information.howadyn.service';
import { LocaleService } from '../../mobilab-common/services/locale.service';
import { LocaleHowadynService } from './domain/locale/locale.howadyn.service';
import { InfoStripComponent } from './scenario-page/main-map/info-strip/info-strip.component';
import { DeprecatedBrowserWarningComponent } from './shared/components/deprecated-browser-warning/deprecated-browser-warning.component';
import { OnBoardingComponent } from './landing-page/onboarding/onboarding.component';
import { NotSupportedBrowserWarningComponent } from './shared/components/not-supported-browser-warning/not-supported-browser-warning.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { ScenarioPopupComponent } from './landing-page/scenario-popup/scenario-popup.component';
import { InfoPopupCatchmentAreaComponent } from './scenario-page/info-popup/implementations/info-popup-catchment-area/info-popup-catchment-area.component';
import { InfoPopupScenariosComponent } from './scenario-page/info-popup/implementations/info-popup-scenarios/info-popup-scenarios.component';
import { StatisticsTrackerService } from '../../mobilab-common/services/statistics-tracker.service';
import { MatomoTracker } from '../../matomo/matomo-tracker.service';
import { StatisticsTrackerHowadynService } from './shared/statistics-tracker-howadyn.service';
import { MatomoModule } from '../../matomo/matomo.module';

registerLocaleData(localeSwissGerman);
registerLocaleData(localeSwissFrench);
registerLocaleData(localeAmericanEnglish);

@NgModule({
  declarations: [
    AppComponent,
    ControlFlowlinesComponent,
    ControlMapModeComponent,
    ControlSwitzerlandComponent,
    HideableComponent,
    LandingPageComponent,
    MainHeaderComponent,
    MainMapComponent,
    MapComponent,
    ScalelineComponent,
    ScenarioPageComponent,
    TimeSliderComponent,
    VideoTimeSpanPipe,
    CopyrightComponent,
    ScenarioCardComponent,
    TooltipComponent,
    LegendWithTextComponent,
    PopUpComponent,
    DetailPageComponent,
    DetailHeaderComponent,
    DetailTableComponent,
    DetailContentComponent,
    ImpactTranslatePipe,
    DetailGraphComponent,
    DetailMapSwitzerlandComponent,
    ValueDisplayPipe,
    ImpactUnitPipe,
    InfoPopupBaseComponent,
    InfoPopupSwitzerlandComponent,
    InfoPopupPrecipitationComponent,
    InfoPopupFlowlinesComponent,
    InfoPopupHazardClassesComponent,
    InfoPopupUnmodelledFlowlinesComponent,
    InfoPopupDetourRoadsComponent,
    DetailMapFlowlinesComponent,
    DetailPageFlowlinesComponent,
    MenuHowadynComponent,
    HowadynApplicationMenuComponent,
    HowadynMoreToolsMenuComponent,
    DeprecatedBrowserWarningComponent,
    InfoStripComponent,
    OnBoardingComponent,
    NotSupportedBrowserWarningComponent,
    SafeHtmlPipe,
    ScenarioPopupComponent,
    InfoPopupCatchmentAreaComponent,
    InfoPopupScenariosComponent
  ],
  imports: [
    ApiModule.forRoot({ rootUrl: environment.backendUrl + '/api' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    MaterialModule,
    MatomoModule,
    MobilabCommonModule,
    NgxSliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient]
      }
    }),
    FormsModule,
  ],
  providers: [
    { provide: MOBILAB_COMMON_CONFIG, useValue: mobilabCommonConfig },
    {
      provide: AdditionalInformationService,
      deps: [TranslateService, RoutingHelperService, LoggingService],
      useClass: AdditionalInformationHowadynService,
    },
    {
      provide: LocaleService,
      deps: [TranslateService, LoggingService, MOBILAB_COMMON_CONFIG],
      useClass: LocaleHowadynService,
    },
    {
      provide: StatisticsTrackerService,
      deps: [MatomoTracker, LocaleService, LoggingService],
      useClass: StatisticsTrackerHowadynService,
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
