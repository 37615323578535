import {Component, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

/**
 * Component to show a dialog to inform the user about something with a close-button (no OK/Cancel, just close).
 */
@Component({
  selector: 'mob-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText: string;

  constructor(
    private dialogRef: MatDialogRef<InformationDialogComponent>
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
