import { Injectable } from '@angular/core';
import { MapService } from '../../scenario-page/main-map/map.service';
import { TimeMode } from '../time/time-mode.enum';
import { Precipitation } from '../../shared/enums/Precipitation';
import { ScenarioService } from '../scenario/scenario.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Impact } from '../../shared/enums/Impact';
import { PrecipitationVideoLayer } from './precipitation-video-layer';
import { ScenarioLayer } from '../scenario/scenario-layer';
import { PrecipitationMaximumLayer } from './precipitation-maximum-layer';
import { TimeService } from '../time/time.service';
import { Vector } from 'ol/source';
import OlMap from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON';
import { environment } from '../../../environments/environment';
import { ImpactLayer } from './impact-layer';
import { ScenariosService } from '../../openapi/services/scenarios.service';
import { defaultExtent } from '../scenario/defaultExtent';

interface PrecipitationLayer {
  timeMode: TimeMode;
  precipitation: Precipitation;
  layer: ScenarioLayer;
}

@Injectable({
  providedIn: 'root'
})
export class MapModeSwitzerlandService {
  private _precipitation = new BehaviorSubject(Precipitation.Intensity);
  private _precipitationIsVisible = new BehaviorSubject(true);
  private _impact = new BehaviorSubject(Impact.Buildings);

  private impactLayer: ImpactLayer;

  private _precipitationLayers: Array<PrecipitationLayer> = [];

  constructor(
    private mapService: MapService,
    private scenarioService: ScenarioService,
    private timeService: TimeService,
    private scenariosService: ScenariosService) {
    this._precipitationLayers.push(
      MapModeSwitzerlandService.createDynamicPrecipitationLayer(Precipitation.Intensity, this.mapService.map, this.timeService, true));
    this._precipitationLayers.push(
      MapModeSwitzerlandService.createDynamicPrecipitationLayer(Precipitation.Sum, this.mapService.map, this.timeService, false));
    this._precipitationLayers.push(MapModeSwitzerlandService.createMaximumPrecipitationLayer(Precipitation.Intensity));
    this._precipitationLayers.push(MapModeSwitzerlandService.createMaximumPrecipitationLayer(Precipitation.Sum));

    this.impactLayer = new ImpactLayer({
      source: new Vector({
        url: `${environment.backendUrl}/geoserver/Hochwasserdynamik/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=Hochwasserdynamik%3Afloodplain&outputFormat=application/json&srsname=EPSG:3857&propertyName=is_modelled,key`,
        format: new GeoJSON(),
      }),
      minResolution: 100,
      zIndex: 11,
    }, this.scenariosService, this.timeService);

    this._precipitationLayers.forEach(pl => {
      mapService.addLayer(pl.layer);
    });
    combineLatest([this.timeService.timeMode$, this.precipitation$, this.precipitationIsVisible$])
      .subscribe(([timeMode, precipitation, precipitationIsVisible]) =>
        this.setVisiblePrecipitationLayer(timeMode, precipitation, precipitationIsVisible));
    mapService.addLayer(this.impactLayer);
    this.scenarioService.currentScenario$.subscribe(
      scenario => {
        this._precipitationLayers.forEach(l => l.layer.scenario = scenario);
        this.impactLayer.scenario = scenario;
      }
    );
    this.impact$.subscribe(impact => this.impactLayer.impact = impact);
  }

  private static createDynamicPrecipitationLayer(
    precipitation: Precipitation,
    map: OlMap,
    timeService: TimeService,
    isMainVideo: boolean): PrecipitationLayer {
    return {
      timeMode: TimeMode.Dynamic,
      precipitation: precipitation,
      layer: new PrecipitationVideoLayer(
        { zIndex: 5, visible: false, minResolution: 100, precipitation, opacity: 0.5, isMainVideo, extent: defaultExtent },
        map,
        timeService),
    };
  }

  private static createMaximumPrecipitationLayer(precipitation: Precipitation): PrecipitationLayer {
    return {
      timeMode: TimeMode.Maximum,
      precipitation: precipitation,
      layer: new PrecipitationMaximumLayer({ zIndex: 5, visible: false, minResolution: 100, precipitation, opacity: 0.5 }),
    };
  }

  get precipitation$(): Observable<Precipitation> {
    return this._precipitation.asObservable();
  }

  set precipitation(precipitation: Precipitation) {
    this._precipitation.next(precipitation);
  }

  get precipitationIsVisible$(): Observable<boolean> {
    return this._precipitationIsVisible.asObservable();
  }

  set precipitationIsVisible(value: boolean) {
    this._precipitationIsVisible.next(value);
  }

  get impact$(): Observable<Impact> {
    return this._impact.asObservable();
  }

  set impact(impact: Impact) {
    this._impact.next(impact);
  }

  private setVisiblePrecipitationLayer(timeMode: TimeMode, precipitation: Precipitation, precipitationIsVisible: boolean) {
    for (const layer of this._precipitationLayers) {
      const visible = layer.timeMode === timeMode && layer.precipitation === precipitation && precipitationIsVisible;
      layer.layer.setVisible(visible);
    }
  }

  public reset() {
    this.precipitation = Precipitation.Intensity;
    this.precipitationIsVisible = true;
    this.impact = Impact.Buildings;
  }
}
