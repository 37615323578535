<mob-popup-content (closeButtonClicked)="closeDialog()">
  <div class="mob-map-popup-title">{{title}}</div>

  <div class="mob-map-popup-content">
    <div class="description">{{description}}</div>
    <div class="footer">
      <button *ngIf="buttonText" mat-raised-button (click)="closeDialog()">{{buttonText}}</button>
    </div>
  </div>
</mob-popup-content>
