<div class="menu-chapter" *ngIf="config">
  <h3 mat-subheader class="chapter-title" *ngIf="config.title$">{{config.title$ | async}}</h3>

  <ng-container *ngFor="let item of config.items">

    <a *ngIf="isButton(item)" mat-list-item class="menu-item" (click)="item.clicked()">
      {{item.displayText$ | async}}
    </a>

    <a *ngIf="isInternalLink(item)" mat-list-item class="menu-item" [routerLink]="item.routerLink$ | async">
      {{item.displayText$ | async}}
    </a>

    <a *ngIf="isExternalLink(item)" mat-list-item class="menu-item" target="_blank" rel="noopener noreferrer" [href]="item.href$ | async">
      {{item.displayText$ | async}}
    </a>

  </ng-container>

  <mat-divider class="chapter-divider" *ngIf="config.endWithHorizontalLine"></mat-divider>
</div>
