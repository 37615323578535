import { pi, tau } from "../math";
var ka = 0.89081309152928522810,
  kr = Math.sin(pi / 10) / Math.sin(7 * pi / 10),
  kx = Math.sin(tau / 10) * kr,
  ky = -Math.cos(tau / 10) * kr;
export default {
  draw: function (context, size) {
    var r = Math.sqrt(size * ka),
      x = kx * r,
      y = ky * r;
    context.moveTo(0, -r);
    context.lineTo(x, y);
    for (var i = 1; i < 5; ++i) {
      var a = tau * i / 5,
        c = Math.cos(a),
        s = Math.sin(a);
      context.lineTo(s * r, -c * r);
      context.lineTo(c * x - s * y, s * x + c * y);
    }
    context.closePath();
  }
};