<div class="popup-content-container">
  <div class="title-container">
    <button
      mat-button
      class="close-button"
      (click)="closeButtonClicked.emit()"
    >
      <mat-icon>close</mat-icon>
    </button>

    <div class="title-content"><ng-content select=".mob-map-popup-title"></ng-content></div>
  </div>

  <div>
    <ng-content select=".mob-map-popup-content"></ng-content>
  </div>
</div>
