<how-info-popup-base
  titleTranslationKey="infoButton.header.unmodelledFlowlines"
  [clickEvent]="data.clickEvent"
  [pathWithinCms]="pathWithinCms"
  (popupClosed)="closeOverlay()"
>
  <div class="info-popup-footer">
    <p>
      <span>{{'infoButton.footer.textBeforeLink' | translate}}</span>
      <a [routerLink]="methodLink$ | async" (click)="closeOverlay()">{{'infoButton.footer.textLink' | translate}}</a>
      <span>{{'infoButton.footer.textAfterLink' | translate}}</span>
    </p>
  </div>
</how-info-popup-base>