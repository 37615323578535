<mat-sidenav-container class="main-container sidenav-container" [class.is-mobile]="isMobile" [hasBackdrop]="isMobile">
  <mat-sidenav
      #sidenav
      class="menu-sidenav"
      position="end"
      mode="over"
      [fixedInViewport]="isMobile"
  >
    <div class="top-row" [ngClass]="isMoreToolsMenuOpen ? 'left-align': ''">
      <button class="no-focus" mat-icon-button (click)="onMenuClosed()">
        <mat-icon>close</mat-icon>
      </button>

      <mob-lang-switch
        [languageToLocaleMap]="mobilabConfig.languageToLocaleMap"
        [localeCode]="localeCode"
        [appTitle]="appTitle"
        [hidden]="isMoreToolsMenuOpen"
        (localeCodeChange)="localeCodeChange.emit($event)"
      ></mob-lang-switch>
    </div>

    <div class="sidenav-content">
      <ng-content select=".mob-main-container-sidenav"></ng-content>
    </div>

  </mat-sidenav>

  <mat-sidenav-content>
    <div class="menu-latch">
      <button class="no-focus" mat-icon-button (click)="onMenuOpened(menuTypeEnum.AppMenu)">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="menu-latch more-tools">
      <button class="no-focus" mat-icon-button (click)="onMenuOpened(menuTypeEnum.MoreToolsMenu)">
        <mat-icon>apps</mat-icon>
      </button>
    </div>
    <div class="main-content" [class.main-content-without-footer]="hideFooter" (click)="onBackgroundClicked()">
      <ng-content select=".mob-main-container-content"></ng-content>
    </div>

    <div class="footer" *ngIf="!hideFooter">
      <div>{{footerAboutUsTranslation}}</div>
      <div class="stretcher"></div>
      <div>{{footerCopyrightTranslation}}</div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
