import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BubbleTopComponent } from './components/bubble/bubble-top/bubble-top.component';
import { LanguageSwitchingComponent } from './components/lang-switch/language-switching.component';
import { MapBasicComponent } from './components/map/map-basic/map-basic.component';
import { MapWithLayersComponent } from './components/map/map-with-layers/map-with-layers.component';
import { InfoAreaLeftComponent } from './components/info-area-left/info-area-left.component';
import { MaterialModule } from './material/material.module';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { LegendPanelComponent } from './components/legend/legend-panel.component';
import { MapDetailPopupGenericComponent } from './components/map-popup/map-detail-popup-generic.component';
import { InfoAreaRightComponent } from './components/info-area-right/info-area-right.component';
import { ZoomButtonsComponent } from './components/zoom-buttons/zoom-buttons.component';
import { BubbleBackgroundComponent } from './components/bubble/bubble-generic/bubble-background/bubble-background.component';
import { BubbleGenericComponent } from './components/bubble/bubble-generic/bubble-generic.component';
import { LegendGenericAbsoluteComponent } from './components/legend/legend-absolute/legend-generic-absolute.component';
import { LegendGenericRelativeComponent } from './components/legend/legend-relative/legend-generic-relative.component';
import { MapMobilabComponent } from './components/map/map-mobilab/map-mobilab.component';
import { LegendBaseComponent } from './components/legend/legend-base/legend-base.component';
import { MunicipalitySearchComponent } from './components/municipality-search/municipality-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggingModule } from './logging/logging.module';
import { PopupContentComponent } from './components/map-popup/popup-content/popup-content.component';
import { GenericAppMenuComponent } from './components/menu/app-menu/generic-app-menu.component';
import { GenericMoreToolsMenuComponent } from './components/menu/more-tools-menu/generic-more-tools-menu.component';
import { MenuChapterComponent } from './components/menu/common/menu-chapter/menu-chapter.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { PositionedPopupModule } from './components/positioned-popup/positioned-popup.module';
import { AdditionalInformationComponent } from './components/additional-information/additional-information.component';
import { RouterModule } from '@angular/router';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { AdditionalInformationRoutedComponent } from './components/additional-information/additional-information-routed/additional-information-routed.component';
import { InfoPopupPermalinkCopiedComponent } from './components/info-popup-permalink-copied/info-popup-permalink-copied.component';
import { UesgGalleryComponent } from './components/uesg-gallery/uesg-gallery.component';
import { UesgImageComponent } from './components/uesg-gallery/uesg-image/uesg-image.component';

@NgModule({ declarations: [
        BubbleTopComponent,
        LanguageSwitchingComponent,
        MapBasicComponent,
        MapWithLayersComponent,
        InfoAreaLeftComponent,
        MainContainerComponent,
        CopyrightComponent,
        LegendPanelComponent,
        MapDetailPopupGenericComponent,
        InfoAreaRightComponent,
        ZoomButtonsComponent,
        BubbleBackgroundComponent,
        BubbleGenericComponent,
        LegendGenericAbsoluteComponent,
        LegendGenericRelativeComponent,
        MapMobilabComponent,
        LegendBaseComponent,
        MunicipalitySearchComponent,
        PopupContentComponent,
        GenericAppMenuComponent,
        GenericMoreToolsMenuComponent,
        MenuChapterComponent,
        InfoIconComponent,
        AdditionalInformationComponent,
        InformationDialogComponent,
        AdditionalInformationRoutedComponent,
        InfoPopupPermalinkCopiedComponent,
        UesgGalleryComponent,
        UesgImageComponent,
    ],
    exports: [
        MaterialModule,
        LoggingModule,
        PositionedPopupModule,
        BubbleTopComponent,
        LanguageSwitchingComponent,
        MapBasicComponent,
        MapWithLayersComponent,
        InfoAreaLeftComponent,
        MainContainerComponent,
        LegendPanelComponent,
        LegendGenericRelativeComponent,
        MapDetailPopupGenericComponent,
        InfoAreaRightComponent,
        ZoomButtonsComponent,
        BubbleBackgroundComponent,
        BubbleGenericComponent,
        MapMobilabComponent,
        CopyrightComponent,
        MunicipalitySearchComponent,
        PopupContentComponent,
        GenericAppMenuComponent,
        GenericMoreToolsMenuComponent,
        InfoIconComponent,
        AdditionalInformationComponent,
        InformationDialogComponent,
        AdditionalInformationRoutedComponent,
        InfoPopupPermalinkCopiedComponent,
        UesgGalleryComponent,
        LegendGenericAbsoluteComponent,
    ], imports: [CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        LoggingModule,
        PositionedPopupModule,
        RouterModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class MobilabCommonModule { }
