import ImageLayer from 'ol/layer/Image';
import { ImageStatic } from 'ol/source';
import * as Projection from 'ol/proj';
import * as Transform from 'ol/transform';
import OlMap from 'ol/Map';
import RenderEvent from 'ol/render/Event';
import { Options as BaseImageOptions } from 'ol/layer/BaseImage';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { CoordinateProjection } from '../../../../mobilab-common/enums/CoordinateProjection';
import { EventsKey } from 'ol/events';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { TimeService } from '../time/time.service';

export interface Options extends BaseImageOptions {
  videoSourcePath?: string;
  isMainVideo?: boolean;
}

export class VideoLayer extends ImageLayer {
  private _videoElement: HTMLVideoElement = null;
  private readonly cachedImageCanvasElement: HTMLCanvasElement;

  private _subscription: Subscription;
  private _renderEvent: EventsKey;
  private _playResolved = false;
  private _extentIsVisible: BehaviorSubject<boolean>;

  private readonly _isMainVideo: boolean;
  private _videoSourcePath = '';

  constructor(options: Options, private map: OlMap, private timeService: TimeService) {
    super(options);
    this.setSource(new ImageStatic({
      url: 'assets/blankLayer.png',
      projection: Projection.get(CoordinateProjection.EPSG3857),
      imageExtent: options.extent,
    }));
    this.setExtent(options.extent);
    this._extentIsVisible = new BehaviorSubject<boolean>(this.isExtentVisible());
    this.map.on('moveend', () => this._extentIsVisible.next(this.isExtentVisible()));

    this._isMainVideo = options.isMainVideo ?? false;
    this.videoSourcePath = options.videoSourcePath ?? '';

    this.cachedImageCanvasElement = document.createElement('canvas');

    // Create when visible, Remove when invisible
    this.on('change:visible', () => this.createVideoElementIfNotExistsAndSynchronize());
    this._extentIsVisible.pipe(distinctUntilChanged()).subscribe(() => this.createVideoElementIfNotExistsAndSynchronize());

    this.timeService.currentVideoTimeWhilePaused$.pipe(filter(() => this.isVisible || this._isMainVideo))
      .subscribe(t => {
        this.currentTime = t;
      });

    this._subscription = timer(0, 100).pipe(
      filter(() => this.isVisible),
    ).subscribe(_ => this.sampleCurrentFrameToCache());
    this._renderEvent = this.on('postrender', event => this.renderCachedImageToMap(event));

    if (this._isMainVideo) {
      this.updateTimeServiceStateWhilePlaying();
    }
    this.toggleIsPlaying();
  }

  private static createVideoElement(sourcePath: string): HTMLVideoElement {
    const videoElem = document.createElement('video');
    videoElem.muted = true;
    videoElem.crossOrigin = 'Anonymous';
    const videoSource = document.createElement('source');
    videoSource.src = sourcePath;
    videoSource.type = 'video/webm';
    videoElem.preload = 'auto';
    videoElem.appendChild(videoSource);
    videoElem.playbackRate = 1;
    // Is this really doing anything?
    videoElem.remove();
    return videoElem;
  }

  public play(): void {
    this.currentTime = this.timeService.currentVideoTime;
    this._playResolved = false;
    this._videoElement?.play().then(() => this._playResolved = true);
  }

  public pause(): void {
    // TODO Check if we need to add an asynchronous pause to after playResolved
    if (this._playResolved) {
      this._videoElement?.pause();
    }
  }

  public dispose(): void {
    this._videoElement?.remove();
    this.cachedImageCanvasElement.remove();
    this._subscription.unsubscribe();
    this.removeEventListener('postrender', this._renderEvent.listener);
  }

  public get videoSourcePath(): string {
    return this._videoSourcePath;
  }

  public set videoSourcePath(value: string) {
    this._videoSourcePath = value;
    if (this._videoElement !== null) {
      (this._videoElement.firstChild as HTMLSourceElement).src = value;
      this._videoElement.load();
    }
    this.sampleCurrentFrameToCache();
  }

  public get ended(): boolean {
    return this._videoElement?.ended;
  }

  public get duration(): number {
    return this._videoElement?.duration;
  }

  public get currentTime(): number {
    return this._videoElement?.currentTime;
  }

  public set currentTime(value: number) {
    if (this._videoElement) {
      this._videoElement.currentTime = value;
    }
  }

  public get isVisible(): boolean {
    return this._extentIsVisible.value && this.getVisible();
  }

  private get height(): number {
    return this.getExtent()[3] - this.getExtent()[1];
  }

  private get hasToBeSynchronized(): boolean {
    return this.isVisible || this._isMainVideo;
  }

  private get hasCache(): boolean {
    return this.cachedImageCanvasElement && this.cachedImageCanvasElement.width !== 0;
  }

  private sampleCurrentFrameToCache() {
    if (!this._videoElement) {
      return;
    }
    this.cachedImageCanvasElement.width = this._videoElement.videoWidth;
    this.cachedImageCanvasElement.height = this._videoElement.videoHeight;
    const context = this.cachedImageCanvasElement.getContext('2d');
    context.drawImage(this._videoElement, 0, 0);
    this.getSource().changed();
  }

  private renderCachedImageToMap(event: RenderEvent) {
    if (!this.map || !this.hasCache) {
      return;
    }
    const frameState = event.frameState;
    const viewState = frameState.viewState;
    const imageExtent = this.getExtent();
    const imageResolution = this.height / this.cachedImageCanvasElement.height;
    const imagePixelRatio = 1;
    const pixelRatio = frameState.pixelRatio;
    const viewCenter = viewState.center;
    const viewResolution = viewState.resolution;
    const size = frameState.size;
    const width = Math.round(size[0] * pixelRatio);
    const height = Math.round(size[1] * pixelRatio);
    const scale = pixelRatio * imageResolution / (viewResolution * imagePixelRatio);

    const transform = Transform.compose(
      Transform.create(),
      width / 2,
      height / 2,
      scale,
      scale,
      0,
      (imagePixelRatio * (imageExtent[0] - viewCenter[0])) / imageResolution,
      (imagePixelRatio * (viewCenter[1] - imageExtent[3])) / imageResolution
    );

    const dw = this.cachedImageCanvasElement.width * transform[0];
    const dh = this.cachedImageCanvasElement.height * transform[3];
    const dx = transform[4];
    const dy = transform[5];

    const context = event.context;
    context.save();
    context.globalAlpha = this.getOpacity();
    context.drawImage(
      this.cachedImageCanvasElement,
      0,
      0,
      this.cachedImageCanvasElement.width,
      this.cachedImageCanvasElement.height,
      Math.round(dx),
      Math.round(dy),
      Math.round(dw),
      Math.round(dh)
    );
    context.restore();
  }

  private isExtentVisible(): boolean {
    if (!this.getExtent()) {
      return false;
    }

    if (this.getMinResolution() && this.map.getView().getResolution() < this.getMinResolution()) {
      return false;
    }

    if (this.getMaxResolution() && this.map.getView().getResolution() > this.getMaxResolution()) {
      return false;
    }

    const mapExtent = this.map.getView().calculateExtent(this.map.getSize());
    const thisExtent = this.getExtent();
    const thisExtentIsOutsideMapExtent = (thisExtent[2] < mapExtent[0] || thisExtent[0] > mapExtent[2])
      || (thisExtent[3] < mapExtent[1] || thisExtent[1] > mapExtent[3]);
    return !thisExtentIsOutsideMapExtent;
  }

  private updateTimeServiceStateWhilePlaying() {
    timer(0, 100)
      .pipe(
        filter(() => this.timeService.isPlaying && !!this._videoElement),
        tap(() => {
          if (!this._videoElement) {
            console.warn('Warning: The Main Video Layer does not have a Video Element.');
          }
        })
      ).subscribe(() => {
        this.timeService.currentVideoTime = this._videoElement.currentTime;
        if (this.ended) {
          this.timeService.isPlaying = false;
        }
      });
  }

  private toggleIsPlaying() {
    this.timeService.isPlaying$
      .pipe(filter(() => this.hasToBeSynchronized)).subscribe((isPlaying) => {
      if (isPlaying) {
        this.play();
      } else {
        this.pause();
      }
    });
  }

  private createVideoElementIfNotExistsAndSynchronize() {
    if (this.isVisible && this._videoElement === null) {
      this._videoElement = VideoLayer.createVideoElement(this._videoSourcePath);
    }

    if (this._isMainVideo) {
      return;
    }

    if (this.isVisible) {
      this.currentTime = this.timeService.currentVideoTime;
      if (this.timeService.isPlaying) {
        this.play();
      } else {
        this.pause();
      }
    } else {
      this.pause();
    }
  }
}
