import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ElementOrientation} from '../../enums/ElementOrientation';

/**
 * Zoom buttons for the map.
 */
@Component({
  selector: 'mob-zoom-buttons',
  templateUrl: './zoom-buttons.component.html',
  styleUrls: ['./zoom-buttons.component.scss']
})
export class ZoomButtonsComponent implements OnInit {
  @Input() readonly orientation: ElementOrientation;

  @Input() readonly tooltipZoomIn: string;
  @Input() readonly tooltipZoomOut: string;
  @Input() readonly tooltipZoomToOverview: string;

  @Output() readonly zoomChange = new EventEmitter<number>();
  @Output() readonly zoomToOverview = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  get containerClassNames() {
    const vertical = this.orientation === ElementOrientation.vertical;
    return `zoom-buttons-container ${vertical ? 'zoom-buttons-vertical' : 'zoom-buttons-horizontal'}`;
  }

}
