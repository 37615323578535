import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { IInfoPopupData, INFO_POPUP_DATA } from '../../../../../../mobilab-common/models/IInfoPopupData';

@Component({
  selector: 'how-info-popup-flowlines',
  templateUrl: './info-popup-flowlines.component.html',
  styleUrls: ['./info-popup-flowlines.component.scss']
})
export class InfoPopupFlowlinesComponent {
  readonly pathWithinCms = 'text_howadyn/infopopup/flowlines';

  constructor(
    private overlayRef: OverlayRef,
    @Inject(INFO_POPUP_DATA) public data: IInfoPopupData,
  ) { }

  closeOverlay() {
    this.overlayRef.dispose();
  }
}
