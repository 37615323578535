import {Injectable, Injector} from '@angular/core';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {IInfoPopupData, INFO_POPUP_DATA} from '../models/IInfoPopupData';


/**
 * Service to manage the info-popups as overlays.
 * {@see PositionedPopupComponent}
 */
@Injectable({
  providedIn: 'root'
})
export class InfoPopupService {
  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) {}

  public openOverlay<T>(data: IInfoPopupData, component: ComponentType<T>) {
    const overlayRef = this.overlay.create({ minHeight: '100%', minWidth: '100%' });
    const injector = this._createInjector(overlayRef, data);
    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);
  }

  private _createInjector(overlayRef: OverlayRef, data: IInfoPopupData) {
    const injectionTokens = new WeakMap();
    injectionTokens.set(OverlayRef, overlayRef);
    injectionTokens.set(INFO_POPUP_DATA, data);
    return new PortalInjector(this.injector, injectionTokens);
  }
}
