import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MapService } from '../map.service';
import Overlay from 'ol/Overlay';
import OverlayPositioning from 'ol/OverlayPositioning';
import { HoverFeature } from '../../../domain/map/hover-feature';

@Component({
  selector: 'how-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {

  @ViewChild('mapTip', { static: true }) readonly mapTipElement: ElementRef;
  private _mapTip: Overlay;
  mapTipVisible = false;
  mapTipText: string;
  subscription: any;

  constructor(private readonly mapService: MapService) {
  }

  ngOnInit() {
    this._mapTip = new Overlay({
      element: this.mapTipElement.nativeElement,
      offset: [23, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
    });
    this.mapService.map.addOverlay(this._mapTip);
    this.subscription = this.mapService.hoveredFeature$.subscribe(t => {
      if (t) {
        this._showMapTip(t);
      } else {
        this._hideMapTip();
      }
    });
  }

  private _showMapTip(hoverFeature: HoverFeature) {
    this.mapTipVisible = true;
    this._mapTip.setPosition(hoverFeature.coordinate);
    this.mapTipText = hoverFeature.label;
  }

  private _hideMapTip() {
    this.mapTipVisible = false;
  }

  ngOnDestroy(): void {
    this.mapService.map.removeOverlay(this._mapTip);
    this.subscription?.unsubscribe();
  }
}
