import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Inject, Injectable, OnInit} from '@angular/core';
import {LoggingService} from '../../logging/logging.service';
import * as _ from 'lodash';
import {LocaleService} from '../locale.service';
import {IMobilabCommonConfig, MOBILAB_COMMON_CONFIG} from '../../mobilab-common.config';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

interface IAuthenticationTokenResponse {
  token: string;
}

/**
 * Service to handle the authentication with the UESG (Ueberschwemmungsgedaechtnis) Server.
 * (that is a separate app from which we consume data).
 */
@Injectable({providedIn: 'root'})
export class UesgAuthenticationService implements OnInit {
  private _authenticationToken: string;

  constructor(
    private http: HttpClient,
    private logging: LoggingService,
    private localeService: LocaleService,
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig,
  ) {}

  ngOnInit() {
    this._registerClient$().subscribe();
  }

  public getHeaders$(): Observable<{headers?: HttpHeaders}> {
    const authenticationToken = this._authenticationToken;

    if (_.isEmpty(authenticationToken)) {
      return this._registerClient$().pipe(
        map(newToken => {
          if (_.isEmpty(newToken)) {
            this.logging.warn('No authorization-token stored for UESG-Service');
            return {};
          }

          return { headers: this.createHeaders(newToken) };
        })
      );
    }

    return of({
      headers: this.createHeaders(authenticationToken),
    });
  }

  private createHeaders(authenticationToken: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'UserClient ' + authenticationToken,
    });
  }

  private _registerClient$(): Observable<string> {
    const client = {
      browserString: this._getRandomString(),
      lang: this.clientLanguage,
    };

    const baseUrl =  this.mobilabConfig.uesg.url;
    if (_.isEmpty(baseUrl)) {
      this.logging.warn('No base-url for UESG defined in mobilab-config: cannot authenticate to UESG-Server');
      return;
    }

    const url = `${baseUrl}/api/client/register`;
    return this.http.post<IAuthenticationTokenResponse>(url, client).pipe(
      map(response => response.token),
      catchError(error => {
        this.logging.error('Failed to register to UESG-Service', error);
        return '';
      }),
      tap(authenticationToken => this._authenticationToken = authenticationToken)
    );
  }

  private _getRandomString(): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 25; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  private get clientLanguage(): string {
    const currentLanguage = this.localeService.currentLanguage;

    if (!_.isEmpty(currentLanguage)) {
      return currentLanguage;
    }

    try {
      return this.localeService.browserLanguage;
    } catch (error) {
      this.logging.error('Failed to create client for authentication-service.', error);
      return this.mobilabConfig.defaultLanguage;
    }
  }
}
