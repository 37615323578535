import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import OlMap from 'ol/Map';
import {CoordinateProjection} from '../../../enums/CoordinateProjection';
import VectorSource from 'ol/source/Vector';
import TopoJSON from 'ol/format/TopoJSON';
import VectorLayer from 'ol/layer/Vector';
import {FeatureDataService} from '../../../services/feature-data.service';
import StyleLike from 'ol/style/Style';
import * as _ from 'lodash';
import {IStaticMapConfiguration} from '../../../models/map/map-with-layers/IStaticMapConfiguration';
import {ILayerInformation} from '../../../models/map/map-with-layers/ILayerInformation';
import {MapBasicComponent} from '../map-basic/map-basic.component';
import {Observable} from 'rxjs';
import {GeoUnit} from '../../../enums/GeoUnit';
import {OrderFunction} from 'ol/render';

/*
  A generic OpenLayers map with extended functionality:
   - Based on ///<reference path="MapBasicComponent"/>
   - Takes care of map initialization.
   - Takes care of initializing layers.
   - Offers a public interface to access the underlying map and interact with the layers
  Does not have a specific positioning and can be put in an arbitrary container.

  Content can be passed via ng-content.
  It is, however, recommended to just set the map wo an absolute position with a low z-index to show other elements on top of it.

  Further information
    - https://stackoverflow.com/questions/37306548/how-to-show-one-label-per-multi-polygon-in-open-layers-3
    - https://openlayers.org/en/latest/examples/polygon-styles.html
    - http://openlayersbook.github.io/ch06-styling-vector-layers/example-07.html
 */
@Component({
  selector: 'mob-map-with-layers',
  templateUrl: './map-with-layers.component.html',
  styleUrls: ['./map-with-layers.component.scss']
})
export class MapWithLayersComponent implements OnInit {
  @ViewChild(MapBasicComponent, {static: true}) readonly mapComponent: MapBasicComponent;

  @Input() readonly configuration: IStaticMapConfiguration;
  @Input() readonly selectedLayer: GeoUnit;

  @Input() readonly styleLike: StyleLike;
  @Input() readonly renderOrderFunction: OrderFunction;

  @Output() readonly mapInitialized = new EventEmitter<OlMap>();
  @Output() readonly layerAdded = new EventEmitter<GeoUnit>();
  @Output() readonly layerLoaded = new EventEmitter<ILayerInformation>();

  private _layers: {[key in GeoUnit]?: VectorLayer} = {};
  private _layersInitialized = false;

  constructor(
    private featureDataService: FeatureDataService
  ) { }

  // =====================================================================
  // =============== Public Interface for Parent Component ===============
  // =====================================================================

  public get map$(): Observable<OlMap> {
    return this.mapComponent.map$;
  }

  public getLayer(layerKey: GeoUnit): VectorLayer {
    return this._layers[layerKey];
  }

  // Updates which layer is visible
  public updateLayerVisibility(selectedLayer: GeoUnit) {
    _.forOwn(this._layers, (layer, layerKey) => {
      const visibleBefore = layer.getVisible();
      const visibleAfter = layerKey === selectedLayer;

      if (visibleBefore !== visibleAfter) {
        layer.setVisible(visibleAfter);
        layer.changed();
      }
    });
  }

  public zoom(delta: number) {
    this.mapComponent.zoom(delta);
  }

  public zoomToOverview() {
    this.mapComponent.zoomToOverview();
  }

  // =====================================================================
  // ======================== Component Internals ========================
  // =====================================================================

  ngOnInit() {}

  initializeMapContent(map: OlMap) {
    this._addMapLayers(map);
    this._addMapListeners(map);
    this.mapInitialized.emit(map);
  }

  private _addMapLayers(map: OlMap) {
    _.forOwn(this.configuration.layerUrls, (url, layerKey) => {
      // it does not matter if the selected layer is not added first:
      // it will be loaded first in any case because the data is loaded as soon as the layer is rendered.
      this._addMapLayer(layerKey as GeoUnit, url, map);
    });

    // preload rest of the data once the initial layer loaded
    map.on('postrender', () => {
      if (!this._layersInitialized) {
        this._layersInitialized = true;
        _.forOwn(this._layers, (layer, layerKey) => {
          if (layerKey !== this.selectedLayer) {
            const url = this.configuration.layerUrls[layerKey];
            this._loadLayer(layerKey as GeoUnit, url, layer.getSource());
          }
        });
      }
    });
  }

  private _addMapLayer(layerKey: GeoUnit, url: string, map: OlMap) {
    const layer = this._getVectorLayer(layerKey, url);
    this._layers[layerKey] = layer;
    map.addLayer(layer);
    this.layerAdded.emit(layerKey);
  }

  private _getVectorLayer(layerKey: GeoUnit, url: string): VectorLayer {
    const self = this;

    const vectorSource = new VectorSource({
      format: new TopoJSON({
        dataProjection: CoordinateProjection.EPSG3857
      }),
      loader: () => self._loadLayer(layerKey, url, vectorSource),
      url: url
    });

    return new VectorLayer({
      source: vectorSource,
      style: self.styleLike,
      renderOrder: self.renderOrderFunction,
      visible: false
    });
  }

  private _loadLayer(layerKey: GeoUnit, url: string, source: VectorSource) {
    // only load the data if it has not been loaded yet
    if (source.getFeatures().length === 0) {
      const filterFunction = this.configuration.layerFilterFunction;
      const sanitizationFunction = this.configuration.layerSanitizationFunction;

      this.featureDataService.loadFeatures$(url, filterFunction, sanitizationFunction).subscribe(features => {
        source.addFeatures(features);
        this.layerLoaded.emit({layerKey, features});
      });
    }
  }

  private _addMapListeners(map: OlMap) {
    // Removes unwanted selections on other elements (e.g. textelements) for dragzoom (only an IE problem)
    map.on('pointerdrag', () => document.getSelection().removeAllRanges());
  }
}
