<mob-legend-base [config]="config" (noValueInfoIconClicked)="noValueInfoIconClicked.emit($event)">
  <svg>
    <g *ngFor="let item of config.rectangles">
      <rect [attr.x]="item.rect.x" [attr.y]="item.rect.y" [attr.width]="item.rect.width"
            [attr.height]="item.rect.height" [attr.fill]="item.rect.fill"
            [attr.stroke]="item.rect.stroke"></rect>
      <text *ngIf="item.label.label < integerMax"
            [attr.x]="item.label.x" [attr.y]="item.label.y" dy="0.5ex" [attr.dx]="item.label.length"
            [attr.text-anchor]="item.label.anchor"
      >
        {{ mapValueForDisplay(item.label.label) }}&nbsp;%
      </text>
    </g>
    <g *ngFor="let item of config.noValueRect">
      <rect [attr.x]="item.rect.x" [attr.y]="item.rect.y" [attr.width]="item.rect.width"
            [attr.height]="item.rect.height" [attr.fill]="item.rect.fill"
            [attr.stroke]="item.rect.stroke"></rect>
      <text [attr.x]="item.label.x" [attr.y]="item.label.y" dy="0.5ex">
        {{config.noValueDescription}}
      </text>
    </g>
  </svg>
</mob-legend-base>
