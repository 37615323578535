import {Component, Input, OnInit} from '@angular/core';
import {IMoreToolsMenuConfiguration} from '../../../models/menu/IMoreToolsMenuConfiguration';

/**
 * Generic component to show a "More tools" menu containing links to additional tools the user could explore.
 * Based on a list of the entries to display.
 */
@Component({
  selector: 'mob-generic-more-tools-menu',
  templateUrl: './generic-more-tools-menu.component.html',
  styleUrls: ['./generic-more-tools-menu.component.scss']
})
export class GenericMoreToolsMenuComponent implements OnInit {
  @Input() readonly config: IMoreToolsMenuConfiguration;
  constructor() { }
  ngOnInit() {}
}
