import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {switchMap, take} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {RoutingHelperService} from '../../services/routing/routing-helper.service';
import {LocaleRoutingService} from '../../services/routing/locale-routing.service';

/*
  Generic set of buttons to change the current language.
  Refreshes the URL and App-Title when the language is changed.
  Is independent of the translation framework.
  For that reason, the translation-language has to be changed by the parent reacting to "localeCodeChange".
 */
@Component({
  selector: 'mob-lang-switch',
  templateUrl: './language-switching.component.html',
  styleUrls: ['./language-switching.component.scss']
})
export class LanguageSwitchingComponent implements OnInit, OnDestroy {
  @Input() readonly languageToLocaleMap: {[key: string]: string}; // key: url-language-code (e.g. 'de'); value: locale-code (e.g. 'de-CH')

  @Input() readonly localeCode: string;
  @Output() readonly localeCodeChange = new EventEmitter<string>();

  @Input() set appTitle(appTitle: string) {
    document.title = appTitle;
  }

  private subscription: Subscription;

  constructor (
    private routingHelper: RoutingHelperService,
    private route: ActivatedRoute,
    private localeRoutingService: LocaleRoutingService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.route.paramMap.subscribe(params => this._setLocale(params));
  }

  ngOnDestroy(): void {
    if (!_.isNil(this.subscription)) {
      this.subscription.unsubscribe();
    }
  }

  get languageKeys(): string[] {
    return _.keys(this.languageToLocaleMap);
  }

  private _setLocale(params: ParamMap) {
    const localeRoutingData = this.localeRoutingService.getLocaleFromRouteParams(params);

    const rootHtml = document.getElementById('rootHtml');
    if (!_.isNil(rootHtml)) {
      rootHtml.lang = localeRoutingData.language;
    }

    if (localeRoutingData.routeNeedsChanging) {
      this.navigateToNewUrl(localeRoutingData.language);
    } else {
      this.localeCodeChange.emit(localeRoutingData.locale);
    }
  }

  navigateToNewUrl(languageSegment: string) {
    this.route.url.pipe(
      take(1),
      switchMap(urlSegments => {
        const previousRouteSegments = _.map(urlSegments, segment => segment.path);

        if (!_.isEmpty(previousRouteSegments)) {
          previousRouteSegments[0] = languageSegment; // replace language-segment
        }

        const newRouteSegment = ['/', ...previousRouteSegments];
        return fromPromise(this.routingHelper.navigate(newRouteSegment));
      })
    ).subscribe();
  }
}
