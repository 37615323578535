<div class="header">
  <div class="label">{{subtitleKey| translate}}</div>
  <div class="value">{{translationKey | translate}}</div>
</div>
<div class="table" *ngIf="values$ | async as values">
  <div class="impact">
    <div class="header">
      <div class="name">{{'impact.buildings.impacted' | translate}}</div>
      <img class="building-icon" src="../../../assets/logos/building.svg"/>
    </div>
    <div *ngFor="let impact of impactGroups.buildings">
      <div
        class="field"
        [ngClass]="{'selected': selectedImpact === impact}"
        (click)="selectImpact(impact)">
        <div class="name">{{impact | impactTranslate}}</div>
        <div class="content">
          <div class="info">
            <div class="label">{{impact | impactUnit:true}}</div>
            <div class="value" *ngIf="!(impact==='damageToBuildings')">{{values[impact] | valueDisplay}}</div>
            <div class="value" *ngIf="(impact==='damageToBuildings')">{{1e6 * values[impact] | valueDisplay:false:false:true}}</div>
          </div>
          <div class="arrow"><mat-icon>arrow_right_alt</mat-icon></div>
        </div>
      </div>
    </div>
  </div>
  <div class="impact">
    <div class="header">
      <div class="name">{{'impact.personsAndWorkplaces.impacted' | translate}}</div>
      <img class="person-icon" src="../../../assets/logos/people.svg"/>
    </div>
    <div *ngFor="let impact of impactGroups.personsAndWorkplaces">
      <div
        class="field"
        [ngClass]="{'selected': selectedImpact === impact}"
        (click)="selectImpact(impact)">
        <div class="name">{{impact | impactTranslate}}</div>
        <div class="content">
          <div class="info">
            <div class="label">{{impact | impactUnit:true}}</div>
            <div class="value">{{values[impact] | valueDisplay}}</div>
          </div>
          <div class="arrow"><mat-icon>arrow_right_alt</mat-icon></div>
        </div>
      </div>
    </div>
  </div>
  <div class="impact">
    <div class="header">
      <div class="name">{{'impact.streets.impacted' | translate}}</div>
      <img class="street-icon" src="../../../assets/logos/street.svg"/>
    </div>
    <div *ngFor="let impact of impactGroups.streets">
      <div
        class="field"
        [ngClass]="{'selected': selectedImpact === impact}"
        (click)="selectImpact(impact)">
        <div class="name">{{impact | impactTranslate}}</div>
        <div class="content">
          <div class="info">
            <div class="label">{{impact | impactUnit:true}}</div>
            <div class="value">{{values[impact] | valueDisplay}}</div>
          </div>
          <div class="arrow"><mat-icon>arrow_right_alt</mat-icon></div>
        </div>
      </div>
    </div>
  </div>
</div>
