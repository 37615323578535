import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggingService } from '../../../../mobilab-common/logging/logging.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private logger: LoggingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(x => x, err => {
        if (!err.url.includes('logging')) {
          this.logger.error(`Error performing request, status code = ${err.status}`, err);
        }
      })
    );
  }
}
