import {Injectable} from '@angular/core';
import {RoutingHelperService} from './routing-helper.service';
import {LoggingService} from '../../logging/logging.service';

/**
 * This service is meant to be overridden by a subclass in each specific app which wants to use it.
 * Helper-Service to deal with errors occurring during routing.
 */
@Injectable({
  providedIn: 'root'
})
export class RoutingErrorHandlingService {
  protected constructor(
    private routingHelper: RoutingHelperService,
    private logger: LoggingService,
  ) {}

  public async handleRoutingError(error?: Error): Promise<boolean> {
    if (error) {
      this.logger.error('Routing-Error', error);
    }

    this._showErrorDialog();
    return await this.routingHelper.goToHome();
  }

  // =====================================================================
  // ======================== App-Specific Code ==========================
  // =====================================================================

  protected _showErrorDialog() {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(RoutingErrorHandlingService.name,  '_showErrorDialog');
  }
}
