import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '../../../../mobilab-common/logging/logging.service';
import { AdditionalInformationService } from '../../../../mobilab-common/services/additional-information.service';
import { RoutingHelperService } from '../../../../mobilab-common/services/routing/routing-helper.service';
import * as _ from 'lodash';
import { appMenuItems, cmsBasePathMenuItems } from './grav-config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { commonRouteSegments } from '../../../../mobilab-common/common-routing-constants';
import { IAdditionalInformationData } from '../../../../mobilab-common/models/additional-information/IAdditionalInformationData';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdditionalInformationHowadynService extends AdditionalInformationService {

  constructor(
    private translate: TranslateService,
    private routingHelper: RoutingHelperService,
    logger: LoggingService,
  ) {
    super(logger);
  }

  get cmsMenuItemBasePath(): string {
    return cmsBasePathMenuItems;
  }

  get validCmsMenuItemPaths(): string[] {
    return _.values(appMenuItems);
  }

  getRouterLink$(cmsPath: string): Observable<string[]> {
    const basePath = `${cmsBasePathMenuItems}/`;
    const cmsResourceName = _.replace(cmsPath, basePath, '');
    return this.routingHelper.relativeBaseRouteWithLanguageStream$
      .pipe(map(baseRoute => [...baseRoute, commonRouteSegments.additionalInformation, cmsResourceName]));
  }

  createAdditionalInformationData$(pathWithinCms: string, triggerStatisticsTracker?: boolean): Observable<IAdditionalInformationData> {
    return this.translate.stream('howadyn.cmsContentOnError')
      .pipe(map(cmsContentOnError => ({
          cmsUrl: environment.gravUrl,
          languageCode: this.translate.currentLang,
          contentOnCmsError: cmsContentOnError,
          pathWithinCms,
          triggerStatisticsTracker
        })
      ));
  }
}
