import { Component, Input, OnInit } from '@angular/core';
import { Map } from 'ol';
import { Scenario } from '../../openapi/models/scenario';
import { MapService } from '../../scenario-page/main-map/map.service';

@Component({
  selector: 'how-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss']
})
export class DetailHeaderComponent implements OnInit {

  @Input() scenario: Scenario;
  @Input() flowlineKey: string;

  redirectUrl: string;
  map: Map;

  constructor(private readonly mapService: MapService) { }

  ngOnInit() {
    if (this.flowlineKey === 'switzerland') {
      this.redirectUrl = '..';
    } else {
      this.redirectUrl = '../../..';
    }
    this.map = this.mapService.map;
  }

  resetMapExtent() {
    if (this.flowlineKey === 'switzerland') {
      this.mapService.setDefaultExtentInstant();
    }
  }

}
