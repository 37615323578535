<div
  #popup
  class="ol-popup"
  [class.invisible]="!visible"
  [style.minWidth]="width"
  [style.maxWidth]="width"
>
  <mob-popup-content
    (closeButtonClicked)="closePopup()"
  >
    <div class="mob-map-popup-title">
      <ng-content select=".mob-map-popup-title"></ng-content>
    </div>

    <div class="mob-map-popup-content">
        <ng-content select=".mob-map-popup-content"></ng-content>

        <button
          *ngIf="showDetailsButton"
          mat-button
          class="details-button"
          (click)="onDetailsButtonClicked()"
        >
          <span>{{detailsButtonLabel}}</span>
          <mat-icon aria-hidden="true">arrow_right_alt</mat-icon>
        </button>
    </div>
  </mob-popup-content>
</div>
