import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Impact } from '../../../shared/enums/Impact';
import { Map, View } from 'ol';
import { MapService } from '../../../scenario-page/main-map/map.service';
import { Image as ImageLayer } from 'ol/layer';
import { ImageWMS, Vector } from 'ol/source';
import BaseLayer from 'ol/layer/Base';
import * as Projection from 'ol/proj';
import { CoordinateProjection } from '../../../../../mobilab-common/enums/CoordinateProjection';
import { ImpactLayer } from '../../../domain/map-mode-switzerland/impact-layer';
import { environment } from '../../../../environments/environment';
import GeoJSON from 'ol/format/GeoJSON';
import { ScenariosService } from '../../../openapi/services/scenarios.service';
import { TimeServiceMaximumMock } from '../../../mocks/time.service.maximum.mock';
import { ScenarioService } from '../../../domain/scenario/scenario.service';
import { LegendConfigurationService } from '../../../domain/legend/legend-configuration.service';
import { Observable } from 'rxjs';
import { ILegendWithTextConfig } from '../../../domain/legend/legend-with-text-config';
import { map } from 'rxjs/operators';

@Component({
  selector: 'how-detail-map-switzerland',
  templateUrl: './detail-map-switzerland.component.html',
  styleUrls: ['./detail-map-switzerland.component.scss']
})
export class DetailMapSwitzerlandComponent implements OnInit {
  public readonly map = new Map({
    view: new View({
      projection: Projection.get(CoordinateProjection.EPSG3857),
      center: MapService.defaultCenter,
      resolution: this.getResolutionForScreenWidth(window.innerWidth),
      constrainOnlyCenter: true,
    }),
    controls: [],
    interactions: [],
  });
  impactLegend$: Observable<ILegendWithTextConfig>;

  private _impact: Impact;
  @Input()
  set impact(value: Impact | undefined) {
    if (!value) {
      return;
    }
    this._impact = value;
    this.impactLegend$ = this.legendConfigurationService.impactLegendConfiguration(value).pipe(
      map((impactLegend => {
          impactLegend.entries[impactLegend.entries.length - 1].infoType = null;
          return impactLegend;
      }))
    );
    if (this.impactLayer) {
      this.impactLayer.impact = value;
    }
  }
  private impactLayer: ImpactLayer;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.map.getView().setResolution(this.getResolutionForScreenWidth(event.target.innerWidth));
  }

  constructor(
    private scenarioService: ScenarioService,
    private scenariosService: ScenariosService,
    private legendConfigurationService: LegendConfigurationService,
) { }

  ngOnInit() {
    this.impactLayer = new ImpactLayer({
      source: new Vector({
        url: `${environment.backendUrl}/geoserver/Hochwasserdynamik/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=Hochwasserdynamik%3Afloodplain&outputFormat=application/json&srsname=EPSG:3857&propertyName=is_modelled,key`,
        format: new GeoJSON(),
      }),
      zIndex: 11,
    }, this.scenariosService, new TimeServiceMaximumMock());
    this.impactLayer.impact = this._impact;
    this.scenarioService.currentScenario$.subscribe(scenario => this.impactLayer.scenario = scenario);
    this.map.addLayer(this.impactLayer);
    DetailMapSwitzerlandComponent.layers.forEach(x => this.map.addLayer(x));
    setTimeout(() => this.map.updateSize(), 0); // Else the map is not centered.
  }

  private getResolutionForScreenWidth(width: number): number {
    return width < 1200 ? 1200 : 800;
  }

  private static get layers(): BaseLayer[] {
    return [
      new ImageLayer({
        source: new ImageWMS({
          url: 'https://wms.geo.admin.ch/?service=WMS&version=1.3.0&request=GetMap',
          params: { layers: 'ch.swisstopo.pixelkarte-grau', },
        }),
        zIndex: 1,
      }),
      MapService.borderSwitzerland,
      MapService.maskSwitzerland,
    ];
  }

}
