import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { commonRouteParams, commonRouteSegments } from '../../mobilab-common/common-routing-constants';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ScenarioPageComponent } from './scenario-page/scenario-page.component';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { DetailPageFlowlinesComponent } from './detail-page/detail-page-flowlines.component';
import { AdditionalInformationRoutedComponent } from '../../mobilab-common/components/additional-information/additional-information-routed/additional-information-routed.component';


const routes: Routes = [
  { path: `:${commonRouteParams.languageId}/scenarios/:scenarioId/flowlines/:flowlineId/details`,
    component: DetailPageFlowlinesComponent },
  { path: `:${commonRouteParams.languageId}/scenarios/:scenarioId/details`, component: DetailPageComponent },
  { path: `:${commonRouteParams.languageId}/scenarios/:scenarioId`, component: ScenarioPageComponent },
  { path: `:${commonRouteParams.languageId}/scenarios`, component: LandingPageComponent },
  { path: `:${commonRouteParams.languageId}/${commonRouteSegments.additionalInformation}/:${commonRouteParams.cmsPath}`,
    component: AdditionalInformationRoutedComponent },
  { path: `:${commonRouteParams.languageId}`, redirectTo: `:${commonRouteParams.languageId}/scenarios`, pathMatch: 'full' },
  { path: '**',   redirectTo: `${commonRouteSegments.languagePlaceholder}/scenarios`, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
