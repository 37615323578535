import {Component, Input, OnInit} from '@angular/core';
import {IMenuChapterConfiguration} from '../../../../models/menu/IMenuChapterConfiguration';
import * as _ from 'lodash';
import {IMenuItemConfiguration} from '../../../../models/menu/IMenuItemConfiguration';

/**
 * Generic component to display a chapter (separate part) of a menu.
 */
@Component({
  selector: 'mob-menu-chapter',
  templateUrl: './menu-chapter.component.html',
  styleUrls: ['./menu-chapter.component.scss']
})
export class MenuChapterComponent implements OnInit {
  @Input() readonly config: IMenuChapterConfiguration;
  constructor() {}
  ngOnInit() {}

  isButton(menuItem: IMenuItemConfiguration): boolean {
    return !_.isNil(menuItem.clicked);
  }


  isInternalLink(menuItem: IMenuItemConfiguration): boolean {
    return !_.isNil(menuItem.routerLink$);
  }


  isExternalLink(menuItem: IMenuItemConfiguration): boolean {
    return !_.isNil(menuItem.href$);
  }
}
