import {Observable, of} from 'rxjs';
import {IAdditionalInformationData} from '../models/additional-information/IAdditionalInformationData';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {LoggingService} from '../logging/logging.service';

/**
 * This service is meant to be overridden by a subclass in each specific app which wants to use it.
 * Service to load data from a CMS to be displayed as HTML.
 * {@see AdditionalInformationComponent}
 */
@Injectable({
  providedIn: 'root'
})
export class AdditionalInformationService {
  constructor(
    private logger: LoggingService,
  ) {}

  validateCmsPath(cmsPath: string) {
    const whitelist = this.validCmsMenuItemPaths;
    const valid = _.includes(whitelist, cmsPath);

    if (!valid) {
      throw Error(`Could not load additional information due to invalid cms-path: '${cmsPath}'.`);
    }
  }

  // =====================================================================
  // ======================== App-Specific Code ==========================
  // =====================================================================

  get cmsMenuItemBasePath(): string {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(AdditionalInformationService.name, 'cmsMenuItemBasePath');
    return '';
  }

  get validCmsMenuItemPaths(): string[] {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(AdditionalInformationService.name,  'validCmsMenuItemPaths');
    return [];
  }

  getRouterLink$(cmsPath: string): Observable<string[]> {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(AdditionalInformationService.name, 'getRouterLink$');
    return of([]);
  }

  createAdditionalInformationData$(pathWithinCms: string, triggerStatisticsTracker?: boolean): Observable<IAdditionalInformationData> {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(AdditionalInformationService.name, 'createAdditionalInformationData$');
    return of(null);
  }
}
