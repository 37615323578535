import { Injectable } from '@angular/core';
import { StatisticsTrackerService } from '../../../mobilab-common/services/statistics-tracker.service';
import { MatomoTracker } from '../../../matomo/matomo-tracker.service';
import { LoggingService } from '../../../mobilab-common/logging/logging.service';
import { LocaleService } from '../../../mobilab-common/services/locale.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsTrackerHowadynService extends StatisticsTrackerService {

  constructor(
    private matomo: MatomoTracker,
    logger: LoggingService,
    localeService: LocaleService,
  ) {
    super(logger, localeService);
  }

  protected setCustomUrl(url: string) {
    this.matomo.setCustomUrl(url);
  }

  protected trackPageView() {
    this.matomo.trackPageView();
  }

  public trackEvent(category: string, action: string, name?: string, value?: number) {
    this.matomo.trackEvent(category, action, name, value);
  }
}
