import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from '../../../../mobilab-common/services/locale.service';
import { IValueDisplayPreparationData } from './IValueDisplayPreparationData';


@Injectable({
  providedIn: 'root'
})
export class ValueDisplayService {

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService,
  ) { }

  // =====================================================================
  // ====================== Create Display String ========================
  // =====================================================================

  /**
   * Does the same as {@see rawValueToDisplayString} but returns an {@see Observable} which changes when the current locale changes.
   */
  public rawValueToDisplayStream$(value: number): Observable<string> {
    const preparationData = this.prepareValueForDisplay(value);
    return this.preparedValueToDisplayStream$(preparationData);
  }

  /**
   * Prepares the {@param value} (rounding, etc.).
   * Then converts it to a localized string based on the current locale.
   * @param addMillionText
   * @param percentage
   */
  public rawValueToDisplayString(value: number, addMillionText = true, percentage = false, forceLargeNumber = false): string {
    const locale = this.translate.currentLang;
    const preparationData = this.prepareValueForDisplay(value, percentage, forceLargeNumber);
    let displayValue = this._localizePreparedValue(preparationData, locale);
    if (this.isLargeValue(value) && addMillionText) {
      displayValue = displayValue + ' ' + (this.translate.translations[this.translate.currentLang]['millions'] ?? '');
    }
    return displayValue;
  }

  /**
   * Converts an integer to a localized string based on the current locale. Does not round.
   */
  public integerToDisplayString(value: number): string {
    const locale = this.translate.currentLang;
    const preparationData = {
      value,
      minFractionDigits: 0,
      maxFractionDigits: 0,
    };
    return this._localizePreparedValue(preparationData, locale);
  }

  /**
   * Does the same as {@see _preparedValueToDisplayString} but returns an {@see Observable} which changes when the current locale changes.
   */
  public preparedValueToDisplayStream$(preparationData: IValueDisplayPreparationData): Observable<string> {
    return this.localeService.currentLocaleStream$
      .pipe(map(locale => this._localizePreparedValue(preparationData, locale)));
  }

  // =====================================================================
  // ================ Preparation without localization ===================
  // =====================================================================

  public prepareValueForDisplay(value: number, percentage = false, forceLargeNumber = false): IValueDisplayPreparationData {
    value = value || 0;
    if (this.isLargeValue(value)) {
      return this._roundLargeValue(value);
    } else if (forceLargeNumber) {
      return this._roundLargeValue(value, 2);
    } else {
      return this._roundSmallValue(value, percentage);
    }
  }

  // =====================================================================
  // =========================== Localization ============================
  // =====================================================================

  /**
   * @return a localized string of {@param value} as an observable which changes with the selected language of the application.
   * Rounding can be added using {@param options}.
   */
   public toLocaleStringStream$(value: number, options?: Intl.NumberFormatOptions): Observable<string> {
    return this.localeService.currentLocaleStream$
      .pipe(map(locale => this._toLocaleString(value, locale, options)));
  }

  private _toLocaleString(value: number, locale: string, options?: Intl.NumberFormatOptions): string {
    return (value || 0).toLocaleString(locale, options);
  }

  private _localizePreparedValue(preparationData: IValueDisplayPreparationData, locale: string) {
    // minFractionDigits and maxFractionDigits need to be set explicitly; otherwise, additional rounding can occur during localization.

    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: Math.max(preparationData.minFractionDigits, 0),  // numbers <0 are allowed for normal rounding but not here
      maximumFractionDigits: Math.max(preparationData.maxFractionDigits, 0),
    };
    return this._toLocaleString(preparationData.value, locale, options);
  }

  // =====================================================================
  // ================== Absolute and Relative Values =====================
  // =====================================================================

  public isLargeValue(value: number): boolean {
    return Math.abs(value) >= 1e6;
  }

  public isSmallValue(value: number): boolean {
    return Math.abs(value) < 1e6;
  }

  private _getMaxFractionDigits(value: number): number {
    if (value >= 1e8) {
      return 0;
    } else if (value >= 1e7) {
      return 1;
    } else if (value >= 1e6) {
      return 2;
    } else if (value >= 1e1) {
      return 0;
    } else if (value >= 1e-1) {
      return 1;
    } else if (value >= 0.005) {
      return 2;
    } else {
      return 0;
    }
  }

  private _roundLargeValue(value: number, forceMaxDigits = null): IValueDisplayPreparationData {
    const minDigits = 0;
    let maxDigits = null;

    if (forceMaxDigits) {
      maxDigits = forceMaxDigits;
    } else {
      maxDigits = this._getMaxFractionDigits(value);
    }

    return {
      value: _.round(value / 1e6, maxDigits),
      minFractionDigits: minDigits,
      maxFractionDigits: maxDigits
    };
  }

  private _roundSmallValue(value: number, percentage = false): IValueDisplayPreparationData {
    let minDigits = 0;
    let maxDigits = this._getMaxFractionDigits(value);

    if ((value >= 1) && (value <= 100) && percentage) {
      minDigits = 1;
      maxDigits = 1;
    }

    if (value > 1e5) {
      return {
        value: (_.round(value / 1e4, maxDigits) * 1e4),
        minFractionDigits: minDigits,
        maxFractionDigits: maxDigits
      };
    } else if (value > 1e4) {
      return {
        value: (_.round(value / 1e3, maxDigits) * 1e3),
        minFractionDigits: minDigits,
        maxFractionDigits: maxDigits
      };
    } else if (value > 1e3) {
      return {
        value: (_.round(value / 1e2, maxDigits) * 1e2),
        minFractionDigits: minDigits,
        maxFractionDigits: maxDigits
      };
    } else if (value > 1e2) {
      return {
        value: (_.round(value / 1e1, maxDigits) * 1e1),
        minFractionDigits: minDigits,
        maxFractionDigits: maxDigits
      };
    } else if (value > 1e-1) {
      return {
        value: _.round(value, maxDigits),
        minFractionDigits: minDigits,
        maxFractionDigits: maxDigits
      };
    } else if (value >= 0.005) {
      return {
        value: _.round(value, maxDigits),
        minFractionDigits: minDigits,
        maxFractionDigits: maxDigits
      };
    } else {
      return {
        value: 0,
        minFractionDigits: minDigits,
        maxFractionDigits: maxDigits
      };
    }
  }
}
