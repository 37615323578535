import { Inject, Injectable } from '@angular/core';
import { LocaleService } from '../../../../mobilab-common/services/locale.service';
import { MOBILAB_COMMON_CONFIG, IMobilabCommonConfig } from '../../../../mobilab-common/mobilab-common.config';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggingService } from '../../../../mobilab-common/logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class LocaleHowadynService extends LocaleService {
  constructor(
    private translate: TranslateService,
    logger: LoggingService,
    @Inject(MOBILAB_COMMON_CONFIG) mobilabConfig: IMobilabCommonConfig,
  ) {
    super(logger, mobilabConfig);
  }

  public get currentLocale(): string {
    return this.translate.currentLang;
  }

  public get onLocaleChanged$(): Observable<string> {
    return this.translate.onLangChange.pipe(map(event => event.lang));
  }

  protected changeLocale(locale: string): void {
    this.translate.use(locale);
  }

  get browserLocale(): string {
    return this.translate.getBrowserCultureLang();
  }
}
