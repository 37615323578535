<ng-container *ngIf="config">
  <div class="sub-title">{{config.title}}</div>
  <div [className]="containerClass">
    <svg [attr.width]="config.svgDimension.width" [attr.height]="config.svgDimension.height">
      <ng-content></ng-content>
    </svg>

    <!-- info-button with tooltip -->
    <div
      *ngIf="config.showInfoIcon"
      [className]="infoButtonClass"
      [style.left.px]="config.infoIconX"
    >
      <div
        #tooltip="matTooltip"
        class="i-button-svg-container"
        matTooltipClass="mobilab-tooltip"
        [matTooltip]="config.noValueTooltip"
        (click)="tooltip.toggle()"
      >
        <mob-info-icon
          [compensateBaseline]="false"
          (clicked)="noValueInfoIconClicked.emit($event)"
        ></mob-info-icon>
      </div>
    </div>
  </div>
</ng-container>
