export enum Impact {
  Buildings = 'buildings',
  DamageToBuildings = 'damageToBuildings',
  Hospitals = 'hospitals',
  Schools = 'schools',
  RestHomes = 'restHomes',
  Persons = 'persons',
  RestHomeBeds = 'restHomeBeds',
  Workplaces = 'workPlaces',
  StreetsNotUsed = 'noAccessRoadKms',
  StreetsLimitedlyUsed = 'exposedRoadKms',
  StreetsWithEvasiveTraffic = 'detourRoadKms',
}
