import {Injectable} from '@angular/core';
import {commonRouteSegments} from '../common-routing-constants';
import {LocaleService} from './locale.service';
import {LoggingService} from '../logging/logging.service';

/**
 * Service to deal with all communication the the statistics tracker (matomo in this case).
 * The goal is to reference specific tracking-library in as few places as possible to make it more easily replaceable.
 * Beware: the initialization using the MatomoInjector still needs to be done in the AppComponent for some reason.
 *
  * This service is meant to be overridden by a subclass in each specific app which wants to use it.
 */
@Injectable({
  providedIn: 'root'
})
export class StatisticsTrackerService {

  constructor(
    private logger: LoggingService,
    private localeService: LocaleService,
  ) {}

  public trackBasePage() {
    const customUrl = `${window.location.origin}/${this.localeService.currentLanguage}/${commonRouteSegments.map}`;
    this.trackCustomPage(customUrl);
  }

  public trackFakePage(customPageName: string) {
    const customUrl = `${window.location.origin}/${this.localeService.currentLanguage}/${customPageName}`;
    this.trackCustomPage(customUrl);
  }

  public trackInternalPage(relativePath: string) {
    const customUrl = `${window.location.origin}/${relativePath}`;
    this.trackCustomPage(customUrl);
  }

  public trackCustomPage(customUrl: string) {
    if (!customUrl) {
      return;
    }

    this.setCustomUrl(customUrl);
    this.trackPageView();
  }


  // =====================================================================
  // ======================== App-Specific Code ==========================
  // =====================================================================

  public trackEvent(category: string, action: string, name?: string, value?: number) {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(StatisticsTrackerService.name,  'trackEvent');
  }

  protected setCustomUrl (url: string) {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(StatisticsTrackerService.name,  'setCustomUrl');
  }

  protected trackPageView () {
    // Override this in subclasses in specific apps
    this.logger.warnAboutEmptyServiceDefaultMethod(StatisticsTrackerService.name,  'trackPageView');
  }
}
