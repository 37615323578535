import { Component, OnDestroy, OnInit } from '@angular/core';
import Overlay from 'ol/Overlay';
import { MapService } from '../map.service';
import { ScenarioService } from '../../../domain/scenario/scenario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Feature } from 'ol';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ScenariosService } from '../../../openapi/services/scenarios.service';
import { Impact as ImpactDto } from '../../../openapi/models/impact';
import { Impact } from '../../../shared/enums/Impact';
import { MapModeSwitzerlandService } from '../../../domain/map-mode-switzerland/map-mode-switzerland.service';
import { MapMode } from '../../../shared/enums/MapMode';
import { Scenario } from '../../../openapi/models/scenario';
import * as _ from 'lodash';
import { ValueDisplayService } from '../../../domain/numbers/value-display.service';

enum PopUpType {
  Switzerland,
  Floodplain
}

@Component({
  selector: 'how-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit, OnDestroy {
  popUpTypeEnum = PopUpType;
  overlay: any;
  dataAvailable$: Observable<boolean | null>;
  maxImpactSwitzerland$: Observable<number>;
  visible$: Observable<boolean>;
  coordinates$: Observable<number[] | undefined>;
  flowline$: Observable<string>;
  maxImpactFlowline$: Observable<number>;
  popUpType: PopUpType;
  scenario$: Observable<Scenario>;
  impact$: Observable<Impact>;
  percentageImpact$: Observable<number>;
  private flowlineKey: string;

  constructor(
    private mapService: MapService,
    private scenarioService: ScenarioService,
    private scenariosService: ScenariosService,
    private route: ActivatedRoute,
    private router: Router,
    private valueService: ValueDisplayService,
    private mapModeSwizerlandService: MapModeSwitzerlandService) {
  }

  onPopupOverlayInitialized(overlay: Overlay) {
    this.overlay = overlay;
    this.overlay.id = 'PopUp';
    this.mapService.map.addOverlay(overlay);
  }

  ngOnDestroy(): void {
    this.mapService.selectedFeature = null;
    if (this.overlay) {
      this.mapService.map.removeOverlay(this.overlay);
    }
  }

  ngOnInit() {
    const feature$ = this.mapService.selectedFeature$;
    this.impact$ = feature$.pipe(
      map(f => this.getPopUpType(f?.feature)),
      switchMap(p => this.mapService.mapMode$.pipe(
        take(1),
        switchMap(m => p === PopUpType.Switzerland || m === MapMode.Switzerland
          ? this.mapModeSwizerlandService.impact$
          : of(Impact.Buildings)))
      ),
      shareReplay(1));
    this.scenario$ = this.scenarioService.currentScenario$;
    const scenarioKey$ = this.scenario$.pipe(map(s => s?.key));
    this.maxImpactSwitzerland$ =
      combineLatest([this.impact$, this.scenarioService.maxImpacts$])
        .pipe(map(([impact, maxImpacts]) => maxImpacts[impact]));

    this.visible$ = feature$.pipe(map(s => s?.feature !== null));
    this.coordinates$ = feature$.pipe(map(s => s?.feature ? s?.coordinate : null));

    const flowlineKey$ = feature$.pipe(map(s => s?.feature?.get('key') as string), tap(k => this.flowlineKey = k));
    this.flowline$ = flowlineKey$.pipe(map(k => `flowline.${k}`));

    this.maxImpactFlowline$ = combineLatest([scenarioKey$, flowlineKey$]).pipe(
      switchMap(([s, f]) => this.getFloodplainImpacts(s, f)),
      switchMap((maxImpacts) => this.impact$.pipe(map(i => maxImpacts ? maxImpacts[i] as number : null))),
      shareReplay(1),
    );

    this.dataAvailable$ = this.maxImpactFlowline$.pipe(map(maxImpacts => {
      if (this.popUpType === PopUpType.Switzerland) {
        return true;
      } else if (_.isUndefined(maxImpacts)) {
        return null;
      } else {
        return !!Number.isFinite(maxImpacts);
      }
    }));

    this.percentageImpact$ = combineLatest([this.maxImpactFlowline$, this.maxImpactSwitzerland$]).pipe(
      map(([flowline, switzerland]) => {
        const flowlineRounded = this.valueService.prepareValueForDisplay(flowline).value;
        const switzerlandRounded = this.valueService.prepareValueForDisplay(switzerland).value;
        return switzerland ? flowlineRounded / switzerlandRounded * 100 : 0;
      })
    );

    feature$.subscribe(f => {
      this.popUpType = this.getPopUpType(f?.feature);
    });
  }

  private getFloodplainImpacts(scenarioKey: string, floodplainKey: string): Observable<ImpactDto> {
    if (!scenarioKey || !floodplainKey) {
      return of({ });
    }
    return this.scenariosService
      .apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Json({ scenarioKey, floodplainKey})
      .pipe(catchError(() => of({ })));
  }

  getPopUpType(feature: Feature): PopUpType {
    const layerId = feature?.getId().toString();
    if (!layerId) {
      return null;
    }

    if (layerId.startsWith('MaskSwitzerland')) {
      return PopUpType.Switzerland;
    }

    return PopUpType.Floodplain;
  }

  closePopUp() {
    this.mapService.selectedFeature = null;
  }

  detailClicked() {
    if (this.popUpType === PopUpType.Switzerland) {
      this.router.navigate(['details'], { relativeTo: this.route });
      return;
    }

    if (this.flowlineKey) {
      this.router.navigate(['flowlines', this.flowlineKey, 'details'], { relativeTo: this.route });
    }
  }

  getButtonLabel() {
    switch (this.popUpType) {
      case PopUpType.Floodplain:
        return 'overviewFloodplain';
      case PopUpType.Switzerland:
      default:
        return 'overviewCatchmentArea';
    }
  }
}
