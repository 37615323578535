<how-hideable [visible]="precipitationIsVisible$ | async" (visibleChange)="precipitationIsVisibleChange($event)">
  <div hideable-header class="hideable-header">
    <span class="precipitation-label">{{'precipitation' | translate}}</span>
    <mob-info-icon [compensateBaseline]="false" (clicked)="onClickPrecipitationInfoIcon($event)" (click)="$event.stopPropagation()"></mob-info-icon>
  </div>

  <div hideable-body *ngIf="activePrecipitation$ | async as activePrecipitation" class="hideable-body">
    <mat-button-toggle-group [value]="activePrecipitation" (change)="precipitationChange($event.value)" class="howadyn-toggle-group" [hideSingleSelectionIndicator]="true">
      <mat-button-toggle [value]="precipitation.Intensity" class="howadyn-toggle-button">{{precipitation.Intensity | translate}}</mat-button-toggle>
      <mat-button-toggle [value]="precipitation.Sum" class="howadyn-toggle-button">{{precipitation.Sum | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="legend" >
      <ng-container *ngIf="activePrecipitation === precipitation.Intensity">
        <mob-legend-panel-absolute *ngIf="precipitationIntensityLegend$ | async as precipitationIntensityLegend"
                                   [config]="precipitationIntensityLegend"
        ></mob-legend-panel-absolute>
      </ng-container>
      <ng-container *ngIf="activePrecipitation === precipitation.Sum">
        <mob-legend-panel-absolute *ngIf="precipitationSumLegend$ | async as precipitationSumLegend"
                                   [config]="precipitationSumLegend"
        ></mob-legend-panel-absolute>
      </ng-container>
    </div>
  </div>
</how-hideable>

<div class="impact">
  <span class="impact-label">{{'impactOn' | translate}}</span>

  <mat-select *ngIf="activeImpact$ | async as activeImpact" [ngModel]="activeImpact"
    [disableOptionCentering]='true'
    (selectionChange)="impactChange($event.value)">
    <mat-optgroup *ngFor="let impactGroup of impactGroups | keyvalue" [label]="'impact.' + impactGroup.key + '.name' | translate" >
      <mat-option *ngFor="let impact of impactGroup.value" [value]="impact">
        {{'impact.' + impactGroup.key + '.' + impact | translate}}
      </mat-option>
    </mat-optgroup>
  </mat-select>

  <div class="legend">
    <how-legend-with-text *ngIf="impactLegend$ | async as impactLegend" [config]="impactLegend">
    </how-legend-with-text>
  </div>

  <div class="legend">
    <how-legend-with-text [config]="legendConfiguration">
    </how-legend-with-text>
  </div>
</div>
