import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ScenarioPopupComponent } from '../../landing-page/scenario-popup/scenario-popup.component';
import { CmsAccessHowadynService } from '../cms-access/cms-access-howadyn.service';
import { IScenarioPopup, SCENARIO_CONTENT_DATA } from './IScenarioPopup';

@Injectable({
  providedIn: 'root'
})
export class ScenarioPopupService {

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private cmsAccessService: CmsAccessHowadynService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  renderer: Renderer2;
  private overlayRef: OverlayRef;
  private content$: Observable<string>;

  openScenarioPopup(scenarioPopupInfo: IScenarioPopup, locale: string) {
    if (scenarioPopupInfo.rect && scenarioPopupInfo.scenario_key) {
      this.content$ = this.changeData(scenarioPopupInfo.scenario_key, locale);
      const posStrat = this.overlay.position()
      .flexibleConnectedTo(scenarioPopupInfo.rect)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 10,
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top',
          offsetX: -10,
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetX: 10,
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetX: -10,
        },
      ]);
      this.overlayRef = this.overlay.create({ positionStrategy: posStrat });
      const injector = this._createInjector(this.overlayRef, this.content$);
      const portal = new ComponentPortal(ScenarioPopupComponent, null, injector);
      this.overlayRef.attach(portal);
    }
  }

  closeScenarioPopup() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  private changeData(scenario_key: string, locale: string) {
    const grav_path = 'text_howadyn/scenario_popup/' + scenario_key;
    return this.cmsAccessService.getContentFromCmsOrDefault$(environment.gravUrl, grav_path, locale);
  }

  private _createInjector(overlayRef: OverlayRef, content$: Observable<string>) {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: OverlayRef, useValue: overlayRef },
        { provide: SCENARIO_CONTENT_DATA, useValue: content$ },
      ]
    });
  }
}
