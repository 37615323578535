import { Injectable } from '@angular/core';
import { MapService } from '../../scenario-page/main-map/map.service';
import { TimeMode } from '../time/time-mode.enum';
import { ScenarioService } from '../scenario/scenario.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { TimeService } from '../time/time.service';
import { Flooding } from '../../shared/enums/Flooding';
import { FloodingVideoLayer } from './flooding-video-layer';
import { FloodingMaximumLayer } from './flooding-maximum-layer';
import { ScenariosService as ApiScenarioService } from '../../openapi/services';
import { map, mergeMap } from 'rxjs/operators';
import { FloodplainScenario } from '../../openapi/models/floodplain-scenario';
import { Extent } from 'ol/extent';
import BaseLayer from 'ol/layer/Base';
import { Scenario } from '../../openapi/models/scenario';
import { RoadImpactLayer } from './road-impact-layer';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { environment } from '../../../environments/environment';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { Image as ImageLayer } from 'ol/layer';
import { ImageWMS } from 'ol/source';
import { ILegendWithTextConfig } from '../legend/legend-with-text-config';
import { LegendConfigurationService } from '../legend/legend-configuration.service';


interface FloodingLayer {
  timeMode: TimeMode;
  flooding: Flooding;
  layer: BaseLayer;
}

interface FloodplainsScenario {
  floodPlains: FloodplainScenario[];
  scenario: Scenario;
}

@Injectable({
  providedIn: 'root'
})
export class MapModeFlowlinesService {
  private _streetsImpactIsVisible = new BehaviorSubject(false);
  private _flooding = new BehaviorSubject(Flooding.WaterDepth);
  private _impactLegendConfig: Observable<ILegendWithTextConfig>;

  private _floodingLayers: Array<FloodingLayer> = [];
  private readonly impactLayer: RoadImpactLayer;
  private readonly staticLayer: ImageLayer;

  constructor(
    private mapService: MapService,
    private scenarioService: ScenarioService,
    private timeService: TimeService,
    private apiScenarioService: ApiScenarioService,
    private legendConfigService: LegendConfigurationService) {
    this.impactLayer = new RoadImpactLayer({
      visible: false,
      maxResolution: 100,
      zIndex: 2,
      source: new VectorSource({
        format: new GeoJSON(),
        url: function (extent) {
          return (
            `${environment.backendUrl}/geoserver/Hochwasserdynamik/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=Hochwasserdynamik%3Aroad&outputFormat=application/json&srsname=EPSG:3857&bbox=${extent.join(',')},EPSG:3857`);
        },
        strategy: bboxStrategy,
      })}, timeService, apiScenarioService);
    this.mapService.addLayer(this.impactLayer);
    this.scenarioService.currentScenario$.subscribe(s => this.impactLayer.scenario = s);

    this.staticLayer = MapModeFlowlinesService.HospitalsRestHomesSchools;
    this.mapService.addLayer(this.staticLayer);

    combineLatest([this.timeService.timeMode$, this.flooding$])
      .subscribe(([timeMode, flooding]) => this.setVisibleFloodingLayer(timeMode, flooding));

    this.streetsImpactIsVisible$.subscribe(v => {
      this.impactLayer.setVisible(v);
      this.staticLayer.setVisible(!v);
    });

    combineLatest([
      this.scenarioService.currentScenario$
        .pipe(mergeMap(scenario => this.apiScenarioService.apiScenariosScenarioKeyFloodplainsGet$Json({ scenarioKey: scenario.key }))),
      this.scenarioService.currentScenario$,
    ]).subscribe(([floodPlains, scenario]) => this.configureLayers({ floodPlains, scenario }));

    this._impactLegendConfig = combineLatest([
      this.mapService.zoomLevel$,
      this.streetsImpactIsVisible$,
    ]).pipe(map(([zoomLevel, streetsImpactsVisible]) => {
      if (zoomLevel >= 4 && !streetsImpactsVisible) {
        return this.legendConfigService.buildingImpactsLegendConfiguration;
      }
      return this.legendConfigService.flowlineImpactsLegendConfiguration;
    }));
  }

  public static createMaximumFloodingLayer(flooding: Flooding, floodplain: FloodplainScenario, scenario: Scenario): FloodingLayer {
    return {
      timeMode: TimeMode.Maximum,
      flooding: flooding,
      layer: new FloodingMaximumLayer({
        zIndex: 4,
        visible: false,
        flooding,
        floodplainKey: floodplain.floodplainKey,
        scenario: scenario,
        extent: floodplain.extent as Extent,
        opacity: .7,
        maxResolution: 100,
      }),
    };
  }

  get flooding$(): Observable<Flooding> {
    return this._flooding.asObservable();
  }

  set flooding(flooding: Flooding) {
    this._flooding.next(flooding);
  }

  get streetsImpactIsVisible$(): Observable<boolean> {
    return this._streetsImpactIsVisible.asObservable();
  }

  set streetsImpactIsVisible(value: boolean) {
    this._streetsImpactIsVisible.next(value);
  }

  get impactLegendConfig$(): Observable<ILegendWithTextConfig> {
    return this._impactLegendConfig;
  }

  static get HospitalsRestHomesSchools(): ImageLayer {
    return new ImageLayer({
      source: new ImageWMS({
        url: `${environment.backendUrl}/geoserver/Hochwasserdynamik/wms`,
        params: { layers: 'Hospital,RestHome,School' },
      }),
      zIndex: 20,
      maxResolution: 20,
    });
  }

  private createScenarioFloodingLayers(floodplainsScenario: FloodplainsScenario): Array<FloodingLayer> {
    return floodplainsScenario.floodPlains.flatMap(floodPlain => [
      this.createDynamicFloodingLayer(Flooding.WaterDepth, floodPlain, floodplainsScenario.scenario),
      this.createDynamicFloodingLayer(Flooding.HazardClasses, floodPlain, floodplainsScenario.scenario),
      MapModeFlowlinesService.createMaximumFloodingLayer(Flooding.HazardClasses, floodPlain, floodplainsScenario.scenario),
      MapModeFlowlinesService.createMaximumFloodingLayer(Flooding.WaterDepth, floodPlain, floodplainsScenario.scenario),
    ]);
  }

  private createDynamicFloodingLayer(flooding: Flooding, floodplain: FloodplainScenario, scenario: Scenario): FloodingLayer {
    return {
      timeMode: TimeMode.Dynamic,
      flooding: flooding,
      layer: new FloodingVideoLayer({
        zIndex: 4,
        visible: false,
        flooding,
        floodplainKey: floodplain.floodplainKey,
        scenario: scenario,
        extent: floodplain.extent as Extent,
        opacity: .7,
        maxResolution: 100,
      },
        this.mapService.map,
        this.timeService),
    };
  }

  private setVisibleFloodingLayer(timeMode: TimeMode, flooding: Flooding): void  {
    for (const layer of this._floodingLayers) {
      const visible = layer.timeMode === timeMode && layer.flooding === flooding;
      layer.layer.setVisible(visible);
    }
  }

  private configureLayers(floodplainsScenario: FloodplainsScenario) {
    this._floodingLayers.forEach(floodingLayer => {
      this.mapService.map.removeLayer(floodingLayer.layer);
    });
    this._floodingLayers = this.createScenarioFloodingLayers(floodplainsScenario);
    this._floodingLayers.forEach(pl => {
      this.mapService.addLayer(pl.layer);
    });

    this.setVisibleFloodingLayer(this.timeService.timeMode, this._flooding.value);
  }

  public reset() {
    this.streetsImpactIsVisible = false;
    this.flooding = Flooding.WaterDepth;
  }
}
