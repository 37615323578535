import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IMenuConfiguration } from '../../../../../../mobilab-common/models/menu/IMenuConfiguration';
import { AdditionalInformationService } from '../../../../../../mobilab-common/services/additional-information.service';
import { appMenuItems } from '../../../../domain/cms-access/grav-config';

@Component({
  selector: 'how-application-menu',
  templateUrl: './application-menu.component.html',
  styleUrls: ['./application-menu.component.scss']
})
export class HowadynApplicationMenuComponent implements OnInit {

  config: IMenuConfiguration;

  constructor(
    private translate: TranslateService,
    private additionalInformationService: AdditionalInformationService,
  ) { }

  ngOnInit(): void {
    this.config = this._createConfig;
  }

  private _getRouterLink$(cmsPath: string): Observable<string[]> {
    return this.additionalInformationService.getRouterLink$(cmsPath);
  }

  // =====================================================================
  // =========================== Menu Config =============================
  // =====================================================================

  // caching this would cause problems when changing the language (the change would not be applied to the menu itself)
  private get _createConfig(): IMenuConfiguration {
    const baseKey = 'sidenav.appMenu.';

    return {
      chapters: [
        {
          title$: this.translate.stream(baseKey + 'chapterTitles.about'),
          endWithHorizontalLine: true,
          items: [
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.introduction'),
              routerLink$: this._getRouterLink$(appMenuItems.introduction)
            },
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.motivation'),
              routerLink$: this._getRouterLink$(appMenuItems.motivation)
            },
          ],
        },
        {
          title$: this.translate.stream(baseKey + 'chapterTitles.background'),
          endWithHorizontalLine: true,
          items: [
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.method'),
              routerLink$: this._getRouterLink$(appMenuItems.method)
            },
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.storyline'),
              routerLink$: this._getRouterLink$(appMenuItems.storyline)
            },
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.findings'),
              routerLink$: this._getRouterLink$(appMenuItems.findings)
            },
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.data'),
              routerLink$: this._getRouterLink$(appMenuItems.data)
            },
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.software'),
              routerLink$: this._getRouterLink$(appMenuItems.software)
            },
          ],
        },
        {
          endWithHorizontalLine: true,
          items: [
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.aboutus'),
              routerLink$: this._getRouterLink$(appMenuItems.aboutus)
            },
          ],
        },
        {
          endWithHorizontalLine: true,
          items: [
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.contact'),
              routerLink$: this._getRouterLink$(appMenuItems.contact)
            },
          ],
        },
        {
          endWithHorizontalLine: true,
          items: [
            {
              displayText$: this.translate.stream(baseKey + 'elementTexts.legal'),
              routerLink$: this._getRouterLink$(appMenuItems.legalNotice)
            },
          ],
        },
      ]
    };
  }
}
