import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BrowserAndDeviceService } from '../../mobilab-common/services/browser-and-device.service';
import { environment } from '../environments/environment';
import { appConfiguration } from './shared/app-configuration';
import { DeprecatedBrowserWarningComponent } from './shared/components/deprecated-browser-warning/deprecated-browser-warning.component';
import { NotSupportedBrowserWarningComponent } from './shared/components/not-supported-browser-warning/not-supported-browser-warning.component';
import { MatomoInjector } from '../../matomo/matomo-injector.service';

@Component({
  selector: 'how-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'howadyn';

  constructor(
    private translate: TranslateService,
    private browser: BrowserAndDeviceService,
    private dialog: MatDialog,
    private matomoInjector: MatomoInjector,
    private meta: Meta
  ) {
    this.matomoInjector.init(environment.matomoUrl, appConfiguration.matomoId);
    const locale = appConfiguration.languageToLocaleMap[appConfiguration.defaultLanguage];
    this.translate.setDefaultLang(locale);
    this.translate.use(locale);  // this is necessary to ensure that "translate.currentLang" is set (see uses in project)
    this.updateMetaTags();

    this.disableAnimationsAfterResize();
  }

  ngOnInit() {
    this._checkAndWarnDeprecatedBrowser();
  }

  private updateMetaTags() {
    if (!environment.useMetaTags) {
      return;
    }

    this.translate.stream('meta.keywords').subscribe(k => this.meta.updateTag({ name: 'keywords', content: k}));
    this.translate.stream('meta.description').subscribe(k => this.meta.updateTag({ name: 'description', content: k}));
  }

  private _checkAndWarnDeprecatedBrowser() {
    if (this.browser.isSafari || this.browser.isInternetExplorer) {
      setTimeout(() => this.dialog.open(DeprecatedBrowserWarningComponent, { width: '350px' }), 1);
    }

    if (this.browser.isFirefox) {
      setTimeout(() => this.dialog.open(NotSupportedBrowserWarningComponent, { width: '350px' }), 1);
    }
  }

  /**
  * The timeslider animation looks bad when resizing the width of the application.
  * This approach disables all animations for a short period of time after a resize.
  */
  private disableAnimationsAfterResize(): void {
    let resizeTimer;
    window.addEventListener('resize', () => {
      document.body.classList.add('resize-animation-stopper');
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        document.body.classList.remove('resize-animation-stopper');
      }, 400);
    });
  }

}
