<mob-map-detail-popup-generic
  (detailsButtonClicked)="detailClicked()"
  (overlayInitialized)="onPopupOverlayInitialized($event)"
  (popupClosed)="closePopUp()"
  [clickedCoordinates]="coordinates$ | async"
  [detailsButtonLabel]="getButtonLabel() | translate"
  [showDetailsButton]="dataAvailable$ | async"
  [visible]="visible$ | async"
>
  <div [ngSwitch]="popUpType" class="mob-map-popup-title">
    <span *ngSwitchCase="popUpTypeEnum.Switzerland" translate>catchmentArea</span>
    <span *ngSwitchCase="popUpTypeEnum.Floodplain">{{'flowlines' | translate}} {{ flowline$ | async | translate}}</span>
  </div>

  <div class="mob-map-popup-content">
    <div class="container">
      <div class="scenario" *ngIf="scenario$ | async as scenario">
        {{'scenario' | translate}}: {{'scenarioName.' + scenario.key | translate}}, {{'duration' | translate}}: {{scenario.modelledDuration / 24 - 3}} {{'days' | translate}}
      </div>
      <div [ngSwitch]="popUpType">
        <div *ngSwitchCase="popUpTypeEnum.Switzerland">
          <div class="impact">
            {{impact$ | async | impactTranslate:'PopUpSwitzerland'}}:
          </div>
          <div class="impact-value">
            <span *ngIf="!((impact$ | async) === 'damageToBuildings')">{{maxImpactSwitzerland$ | async | valueDisplay}} </span>
            <span *ngIf="((impact$ | async) === 'damageToBuildings')">{{(maxImpactSwitzerland$ | async) * 1e6 | valueDisplay:false:false:true}} </span>
            <span>{{impact$ | async | impactUnit}} </span>
          </div>
        </div>
        <div *ngSwitchCase="popUpTypeEnum.Floodplain">
          <div *ngIf="dataAvailable$ | async">
            <div class="floodplain-impact">
              <div class="impact">
                {{impact$ | async | impactTranslate:'PopUp' }}
              </div>
              <div class="impact-value one-line">
                <span *ngIf="!((impact$ | async) === 'damageToBuildings')">{{maxImpactFlowline$ | async | valueDisplay}} </span>
                <span *ngIf="((impact$ | async) === 'damageToBuildings')">{{(maxImpactFlowline$ | async) * 1e6 | valueDisplay:false:false:true}} </span>
                <span>{{impact$ | async | impactUnit}} </span>
              </div>
            </div>
            <hr>
            <div class="floodplain-impact">
              <div class="impact">
                {{impact$ | async | impactTranslate: 'PopUpPercentage'}}
              </div>
              <div class="impact-value one-line">
                {{ percentageImpact$ | async | valueDisplay:true:true}} %
              </div>
            </div>
          </div>
          <div *ngIf="(dataAvailable$ | async) === false" class="no-values">
            <hr>
            <span>{{'popup.noData' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</mob-map-detail-popup-generic>
