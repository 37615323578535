import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import Overlay from 'ol/Overlay';
import {Coordinate} from 'ol/coordinate';
import * as _ from 'lodash';

/*
  Generic popup shown on top of a OpenLayers map aimed at showing details of a feature on the map.
   - Takes care of positioning of the popup and offers a close-button in the top right corner.
   - The title and content can be passed via ng-content using the following css-class
     - .mob-map-popup-title
     - .mob-map-popup-content
   - A "details" button can be shown at the bottom.
 */
@Component({
  selector: 'mob-map-detail-popup-generic',
  templateUrl: './map-detail-popup-generic.component.html',
  styleUrls: ['./map-detail-popup-generic.component.scss']
})
export class MapDetailPopupGenericComponent implements OnInit {
  @ViewChild('popup', {static: true}) popupElement: ElementRef;

  @Input() readonly visible: boolean;
  @Input() readonly showDetailsButton: boolean;

  @Input() readonly popupWidth: string;
  @Input() readonly detailsButtonLabel: string;

  @Input() set clickedCoordinates(clickedCoordinate: Coordinate) {
    if (clickedCoordinate) {
      this._setCoordinates(clickedCoordinate);
    } else {
      this.closePopup();
    }
  }

  @Output() readonly detailsButtonClicked = new EventEmitter<void>();
  @Output() readonly overlayInitialized = new EventEmitter<Overlay>();
  @Output() readonly popupClosed = new EventEmitter<void>();

  overlay: Overlay;

  constructor() {}

  ngOnInit() {
    this._initializeOverlay();
  }

  private _initializeOverlay() {
    this.overlay = new Overlay({
      element: this.popupElement.nativeElement,
      autoPan: true,
      autoPanAnimation: <any>{
        duration: 250
      }
    });

    this.overlayInitialized.emit(this.overlay);
  }

  private _setCoordinates(clickedCoordinate: Coordinate) {
    setTimeout(() => {
      this.overlay.setPosition(clickedCoordinate);
    }, 10);
  }

  // =====================================================================
  // ============================== Getters ==============================
  // =====================================================================

  get width(): string {
    return _.isEmpty(this.popupWidth) ? '' : this.popupWidth;
  }

  // =====================================================================
  // ======================== Actions / Listeners ========================
  // =====================================================================

  closePopup(): void {
    this.popupClosed.emit();

    if (!_.isNil(this.overlay)) {
      this.overlay.setPosition(undefined);
    }
  }

  onDetailsButtonClicked() {
    this.detailsButtonClicked.emit();
  }
}
