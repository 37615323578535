import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BadBrowser } from '../../../../../mobilab-common/enums/BadBrowser';
import { PositionedPopupLatchOrientation } from '../../../../../mobilab-common/enums/PositionedPopupLatchOrientation';
import { IBoxClickEvent } from '../../../../../mobilab-common/models/info-icon/IBoxClickEvent';
import { BrowserAndDeviceService } from '../../../../../mobilab-common/services/browser-and-device.service';
import { environment } from '../../../../environments/environment';
import { CmsAccessHowadynService } from '../../../domain/cms-access/cms-access-howadyn.service';


@Component({
  selector: 'how-info-popup-base',
  templateUrl: './info-popup-base.component.html',
  styleUrls: ['./info-popup-base.component.scss']
})
export class InfoPopupBaseComponent {
  @Input() readonly titleTranslationKey: string;
  @Input() readonly clickEvent: IBoxClickEvent;
  @Input() readonly popupLatchOrientation: PositionedPopupLatchOrientation;

  @Input() set pathWithinCms(cmsPath: string) {
    this._loadContent(cmsPath);
  }

  @Output() readonly popupClosed = new EventEmitter<void>();

  readonly applyCorrectionForBrowser: BadBrowser;
  contentHtml: string;

  constructor(
    private cmsAccessService: CmsAccessHowadynService,
    private browser: BrowserAndDeviceService,
    private translate: TranslateService,
  ) {
    this.applyCorrectionForBrowser = this._browserCorrection;
  }

  get showPopup(): boolean {
    return !_.isNil(this.contentHtml);
  }

  private get _browserCorrection(): BadBrowser {
    if (this.browser.isIPadChrome) {
      return BadBrowser.iPadChrome;
    }

    if (this.browser.isTabletSafari) {
      return BadBrowser.iPadSafari;
    }

    return null;
  }

  private _loadContent(cmsPath: string) {
    const locale = this.translate.currentLang;
    this.cmsAccessService.getContentFromCmsOrDefault$(environment.gravUrl, cmsPath, locale)
      .subscribe(content => {
        this.contentHtml = content;
      });
  }

  closePopup() {
    this.popupClosed.emit();
  }
}
