import { Component, OnInit } from '@angular/core';
import { SessionStorageAccessService } from '../../../../../mobilab-common/services/session-storage-access.service';

@Component({
  selector: 'how-info-strip',
  templateUrl: './info-strip.component.html',
  styleUrls: ['./info-strip.component.scss']
})
export class InfoStripComponent implements OnInit {
  private readonly hideMapInfoStripKey = 'hideMapInfoStrip';
  show = true;

  constructor(private sessionStorageAccessService: SessionStorageAccessService) { }

  ngOnInit(): void {
    if (this.sessionStorageAccessService.getBoolean(this.hideMapInfoStripKey)) {
      this.show = false;
    }
  }

  close() {
    this.sessionStorageAccessService.setProperty(this.hideMapInfoStripKey, true);
    this.show = false;
  }
}
