<div class="white-background" (click)="closeOnBoarding()">
  <div class="onboarding-background">
    <div *ngIf="content" class="onboarding" (click)="ignoreClick($event)">
      <div class="content" [innerHTML]="content"></div>
      <div class="footer">
        <mat-slide-toggle labelPosition="after" [checked]="hideOnBoarding" (change)="onHideInfoPopupChanged($event)">
          {{'onboarding.dontShow' | translate}}
        </mat-slide-toggle>
        <button mat-button class="button" (click)="buttonClicked()">
          <div class="button-content">
            <div>{{'onboarding.continue' | translate}}</div>
            <mat-icon aria-hidden="true">arrow_right_alt</mat-icon>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
