import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { take } from 'rxjs/operators';
import { ValueDisplayService } from '../../../domain/numbers/value-display.service';
import { ScenarioService } from '../../../domain/scenario/scenario.service';
import { Impact } from '../../../shared/enums/Impact';
import { ImpactGroupings } from '../../../domain/map-mode-switzerland/impact';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'how-detail-graph',
  templateUrl: './detail-graph.component.html',
  styleUrls: ['./detail-graph.component.scss']
})
export class DetailGraphComponent implements  OnInit, OnChanges, OnDestroy {
  private readonly margin = { top: 20, right: 20, bottom: 60, left: 0 };
  private readonly width = 750;
  private readonly height = 400;
  private readonly legendHeight = 50;
  private readonly chartHeight = this.height - this.legendHeight;
  private readonly axisWidth = 60;
  private readonly chartWidth = this.width - this.axisWidth;
  private readonly maximumTicks = 18;
  private chart: any;
  private svg: any;
  private xAxis: any;
  private yAxis: any;

  private colors = { 'bar': '#2C4C8F', 'line': '#60A3A5', 'black': '#000000', 'horizontal': '#D1D1D1' };

  private hours: number;
  private rawBarData: Object;
  private rawLineData: Object;
  private barData = [];
  private lineData = [];
  private subscriptions = [];

  @Input() impact: Impact;
  @Input() data$: Observable<Object>;

  constructor(
    private valueDisplayService: ValueDisplayService,
    private scenarioService: ScenarioService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.data$) {
      this.subscriptions.push(this.data$.subscribe(data => {
        this.rawLineData = data[0];
        this.rawBarData = data[1];
        this.subscriptions.push(this.scenarioService.currentScenario$.pipe(take(1)).subscribe(scenario => {
          this.hours = scenario.modelledDuration;
          this.initializeSVG('#graph');
          this.drawChart();
        }));
      }));
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => this.drawChart()));
    }
  }

  ngOnChanges() {
    if (this.rawLineData) {
      this.drawChart();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  drawChart() {
    if (!this.svg) {
      return;
    }
    if (this.chart) {
      this.chart.remove();
    }
    this.initializeChart();
    this.updateBarData(this.rawBarData, this.hours);
    this.updateLineData(this.rawLineData, this.hours);
    this.createXAxis();
    this.createYAxis();
    this.drawLines();
    if (this.impact !== Impact.DamageToBuildings) {
      this.drawBars();
    }
    const barLegendKey = this.getBarLegendKey(this.impact);
    const lineLegendKey = this.getLineLegendKey(this.impact);
    this.subscriptions.push(this.translate.get([barLegendKey, lineLegendKey]).subscribe(translations =>
      this.addLegend(translations[barLegendKey], translations[lineLegendKey])
    ));
    this.addMouseOver();
  }

  updateLineData(values: Object, hours: number) {
    this.lineData = [];
    if (values[this.impact]) {
      for (let i = 6; i <= hours; i += 6) {
        let valueToRound = values[this.impact][i - 1];
        if (this.impact === Impact.DamageToBuildings && valueToRound >= 1) {
          valueToRound *= 1e6;
        }
        const roundedValue = this.valueDisplayService.prepareValueForDisplay(valueToRound).value;
        this.lineData.push(
          { 'key': i + 'h', 'value': roundedValue }
        );
      }
    }
  }

  updateBarData(values: Object, hours: number) {
    this.barData = [];
    if (values[this.impact]) {
      for (let i = 6; i <= hours; i += 6) {
        let valueToRound = values[this.impact][i - 1];
        if (this.impact === Impact.DamageToBuildings && valueToRound >= 1) {
          valueToRound *= 1e6;
        }
        const roundedValue = this.valueDisplayService.prepareValueForDisplay(valueToRound).value;
        this.barData.push(
          { 'key': i + 'h', 'value': roundedValue }
        );
      }
    }
  }

  initializeSVG(name: string) {
    // create svg
    this.svg = d3.select(name)
      .append('svg')
      .attr('viewBox', '0 0 ' +
        (this.width + this.margin.right + this.margin.left) + ' ' +
        (this.height + this.margin.top + this.margin.bottom))
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  initializeChart() {
    this.chart = this.svg.append('g')
      .attr('class', 'chart')
      .attr('transform', 'translate(' + this.axisWidth + ',0)');
  }

  createXAxis(padding = 0.6) {
    // prepartion for x-Axis
    this.xAxis = d3.scaleBand()
      .range([0, this.chartWidth])
      .domain(this.barData.map((x) => x['key']))
      .padding(padding);

    // create x-Axis
    const xGrid = this.chart.append('g')
      .attr('class', 'xGrid')
      .call(d3.axisBottom(this.xAxis));


    // remove domain and ticks
    xGrid.select('.domain').remove();
    xGrid.selectAll('.tick').select('line').remove();

    // remove unnecessary tick texts
    this.removeTicks();

    // styling of names
    xGrid.selectAll('.tick').select('text')
      .attr('style', 'width:60px;text-align:center;');

    // rearranging the position of the names
    xGrid.attr('text-anchor', 'middle')
      .attr('transform', 'translate(0,' + (this.chartHeight + this.margin.top) + ')');

    // styling of x-Axis
    xGrid
      .attr('font-family', 'lato')
      .attr('font-size', '14px')
      .attr('class', 'text-grey')
      .attr('color', 'black');

  }

  removeTicks() {
    let factor = 1;
    let tickCount = this.hours / 6;

    while (tickCount > this.maximumTicks) {
      tickCount /= 2;
      factor *= 2;
    }

    const ticks = d3.selectAll('.tick text');
    ticks.each(function(d, i) {
        if ((i - 1) % factor !== 0) {
          d3.select(this).remove();
        }
    });
  }

  createYAxis() {
    const { upperBound, ticks } = this.getUpperBound();
    const range = this.getyAxisTicks(upperBound, ticks);

    // preparation for yAxis Lines
    this.yAxis = d3.scaleLinear()
      .domain([upperBound, 0])
      .range([0, this.chartHeight]);

    const gridLines = d3.axisLeft()
      .tickValues(range)
      .tickSize(-this.chartWidth)
      .tickFormat(elem => this.valueDisplayService.rawValueToDisplayString(elem))
      .scale(this.yAxis);

    // create yAxis
    const yGrid = this.chart.append('g')
      .attr('class', 'yGrid')
      .call(gridLines)
      .attr('transform', 'translate(0,' + this.margin.top + ')');

    // remove vertical bar
    yGrid.select('.domain').remove();

    // set color of horizontal lines
    yGrid.selectAll('.tick')
      .select('line')
      .attr('stroke', this.colors.horizontal);

    // styling of y-Axis
    yGrid
      .attr('font-family', 'lato')
      .attr('font-size', '14px')
      .attr('class', 'text-grey')
      .attr('color', 'black');
  }

  getUpperBound(): { upperBound: number, ticks: number } {
    const rangeData: number[] = this.lineData.map((x) => x['value']);
    const maxValue: number = Math.max(...rangeData) < 5 ? 5 : Math.max(...rangeData);
    let upperBound = maxValue;

    let factor = 1;
    let ticks;

    while (upperBound >= 50) {
      upperBound /= 10;
      factor *= 10;
    }
    if (upperBound <= 8) {
      upperBound = Math.ceil(upperBound);
      ticks = upperBound;
    } else if (upperBound <= 16) {
      upperBound = 2 * Math.ceil(upperBound / 2);
      ticks = upperBound / 2;
    } else if (upperBound <= 28) {
      upperBound = 4 * Math.ceil(upperBound / 4);
      ticks = upperBound / 4;
    } else if (upperBound <= 40) {
      upperBound = 5 * Math.ceil(upperBound / 5);
      ticks = upperBound / 5;
    } else {
      upperBound = 10 * Math.ceil(upperBound / 10);
      ticks = upperBound / 10;
    }

    return { upperBound: upperBound * factor, ticks: ticks };
  }

  getyAxisTicks(upperBound, ticks): number[] {
    const range = Array.from(Array(ticks)).map((elem, index) => index * upperBound / ticks);
    range.push(upperBound);
    return range;
  }

  drawBars() {
    const bars = this.chart.append('g')
      .attr('class', 'barRect')
      .selectAll('barRect')
      .data(this.barData)
      .enter()
      .append('g');

    // create bars for barchart
    bars.append('rect')
      .attr('class', 'bars')
      .attr('x', (d) => this.xAxis(d['key']))
      .attr('y', (d) => (this.yAxis(0) + this.yAxis(d['value']) - this.chartHeight + this.margin.top))
      .attr('width', this.xAxis.bandwidth())
      .attr('height', (d) => (this.chartHeight - this.yAxis(d['value'])))
      .attr('fill', this.colors.bar);
  }

  drawLines() {
    this.chart.append('path')
      .attr('class', 'lines')
      .datum(this.lineData)
      .attr('d', d3.line()
        .x((d) => this.xAxis(d['key']))
        .y((d) => (this.yAxis(0) + this.yAxis(d['value']) - this.chartHeight + this.margin.top))
      )
      .attr('stroke', this.colors.line)
      .attr('stroke-width', 4)
      .attr('fill', 'none')
      .attr('transform', 'translate(' + 0.5 * this.xAxis.bandwidth() + ')');
  }

  addLegend(barText: string, lineText: string) {

    const legendDef = [];
    if (this.impact !== Impact.DamageToBuildings) {
      legendDef.push(
        { 'text': barText, 'color': this.colors.bar }
      );
    }
    legendDef.push(
      { 'text': lineText, 'color': this.colors.line }
    );

    const lineLength = 60;
    const lineWidth = 5;

    const legend = this.chart.append('g')
      .attr('class', 'legend')
      .attr('transform', 'translate(-130,' + (270 + this.legendHeight) + ')');

    legend.selectAll('squares')
      .data(legendDef)
      .enter()
      .append('rect')
        .attr('x', 100)
        .attr('y', (d, i) => (100 + i * (lineWidth + 15)))
        .attr('width', lineLength)
        .attr('height', lineWidth)
        .style('fill', (d) => d['color']);

    legend.selectAll('label')
      .data(legendDef)
      .enter()
      .append('text')
        .attr('x', 100 + lineLength + 15)
        .attr('y', (d, i) => (100 + i * (lineWidth + 15) + lineWidth * 1.5))
        .text((d) => d['text'])
        .attr('font-family', 'lato')
        .attr('font-size', '14px')
        .attr('color', 'black');
  }

  getBarLegendKey(impact: Impact) {
    if (ImpactGroupings.buildings.includes(impact)) {
      return 'impact.buildings.' + impact + 'Impacted';
    } else if (ImpactGroupings.personsAndWorkplaces.includes(impact)) {
      return 'impact.personsAndWorkplaces.' + impact + 'Impacted';
    } else if (ImpactGroupings.streets.includes(impact)) {
      return 'impact.streets.' + impact + 'Impacted';
    } else {
      return 'Unknown Impact';
    }
  }

  getLineLegendKey(impact: Impact) {
    if (ImpactGroupings.buildings.includes(impact)) {
      return 'impact.buildings.' + impact + 'Sum';
    } else if (ImpactGroupings.personsAndWorkplaces.includes(impact)) {
      return 'impact.personsAndWorkplaces.' + impact + 'Sum';
    } else if (ImpactGroupings.streets.includes(impact)) {
      return 'impact.streets.' + impact + 'Sum';
    } else {
      return 'Unknown Impact';
    }
  }

  addMouseOver() {
    const rect = this.chart.selectAll('.bars');
    const g = this.svg;
    const valueDisplayService = this.valueDisplayService;
    const bandwidth = this.xAxis.bandwidth();
    const axisWidth = this.axisWidth;

    rect.on('mouseover', function (d, i) {
      const elem = d3.select(this);
      const xPos = elem.attr('x');
      const yPos = elem.attr('y');
      const displayValue = valueDisplayService.rawValueToDisplayString(d['value']);
      const width = 5 + displayValue.length * 10;
      const height = 20;

      elem.attr('opacity', 0.7)
        .attr('stroke', 'black')
        .attr('stroke-width', 1);

      g.append('rect')
        .attr('id', 'indicator')
        .attr('fill', 'white')
        .attr('x', parseFloat(xPos) + axisWidth + bandwidth / 2 - width / 2)
        .attr('y', yPos - 3 - height)
        .attr('width', width)
        .attr('height', height);

      g.append('text')
        .attr('id', 'indicator')
        .attr('text-anchor', 'middle')
        .attr('x', parseFloat(xPos) + axisWidth + bandwidth / 2)
        .attr('y', yPos - 8)
        .attr('font-family', 'lato')
        .attr('font-size', '14px')
        .attr('color', 'black')
        .text(displayValue);

    });

    rect.on('mouseout', function (d, i) {
      d3.select(this)
        .attr('opacity', 1)
        .attr('stroke', null);
      g.selectAll('#indicator').remove();
    });
  }
}
