import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skip, switchMap } from 'rxjs/operators';
import { Scenario } from '../../openapi/models/scenario';
import { ScenariosService } from '../../openapi/services';
import { commonRouteSegments } from '../../../../mobilab-common/common-routing-constants';
import { MapService } from '../../scenario-page/main-map/map.service';
import { Impact } from '../../openapi/models/impact';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {
  private readonly _scenario = new BehaviorSubject<Scenario>(null);
  private readonly _scenarios = new BehaviorSubject<Scenario[]>([]);
  private readonly _scenario$: Observable<Scenario>;
  private _maxImpacts$: Observable<Impact>;

  constructor(
      private scenariosApiService: ScenariosService,
      private router: Router,
      private mapService: MapService
      ) {
    this.scenariosApiService.apiScenariosGet$Json().subscribe(s => this._scenarios.next(s));
    this._scenario$ = this._scenario.asObservable().pipe(filter(x => !!x), distinctUntilChanged());
    combineLatest([
      this._scenario,
      this.scenarios$.pipe(skip(1)),
    ]).subscribe(([currentScenario, _]) => this.adjustRouteIfNecessary(currentScenario));
    combineLatest([
      this.scenarios$.pipe(skip(1)),
      this.router.events.pipe(filter(e => e instanceof NavigationEnd), map(x  => (x as NavigationStart).url)),
    ]).subscribe(([scenarios, url]) => this.adjustScenarioIfNecessary(scenarios, url));

    this._maxImpacts$ = this.currentScenario$.pipe(
      switchMap(s => this.scenariosApiService.getSwissImpacts$Json({ scenarioKey: s.key})),
      shareReplay(1)
    );
  }

  public get currentScenario$(): Observable<Scenario> {
    return this._scenario$;
  }

  public get maxImpacts$(): Observable<Scenario> {
    return this._maxImpacts$;
  }

  public set currentScenario(value: Scenario) {
    this.mapService.setDefaultExtent();
    this._scenario.next(value);
  }

  public get scenarios$() {
    return this._scenarios.asObservable();
  }

  private adjustRouteIfNecessary(scenario: Scenario | null): void {
    if (scenario === null) {
      return;
    }

    const routeSegments = this.router.routerState.snapshot.url.split('/').filter(x => x.length > 0);
    const languageTag = routeSegments.length >= 1 ? routeSegments[0] : commonRouteSegments.languagePlaceholder;

    if (routeSegments.length >= 3 && routeSegments[2] === scenario.key) {
      return;
    }

    this.router.navigate([languageTag, 'scenarios', scenario.key]);
  }

  private adjustScenarioIfNecessary(scenarios: Scenario[], url: string): void {
    const routeSegments = url.split('/').filter(x => x.length > 0);
    const languageTag = routeSegments.length >= 1 ? routeSegments[0] : commonRouteSegments.languagePlaceholder;

    if (routeSegments.length < 2 || routeSegments[1] !== 'scenarios') {
      return;
    }

    if (routeSegments.length >= 3) {
      const keyInUrl = routeSegments[2];
      const scenarioFromUrl = scenarios.find(s => s.key === keyInUrl);

      if (scenarioFromUrl !== undefined && this._scenario.value?.key !== keyInUrl) {
        this._scenario.next(scenarioFromUrl);
        return;
      }
    }

    if (this._scenario.value === null) {
      this.router.navigate([languageTag, 'scenarios']);
    }
  }
}
