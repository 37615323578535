import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMoreToolsMenuConfiguration } from '../../../../../../mobilab-common/models/menu/IMoreToolsMenuConfiguration';

@Component({
  selector: 'how-more-tools-menu',
  templateUrl: './more-tools-menu.component.html',
  styleUrls: ['./more-tools-menu.component.scss']
})
export class HowadynMoreToolsMenuComponent implements OnInit {
  config: IMoreToolsMenuConfiguration;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.config = this._createConfig;
  }

  // =====================================================================
  // =========================== Menu Config =============================
  // =====================================================================

  private get _createConfig(): IMoreToolsMenuConfiguration {
    const baseKey = 'sidenav.moreToolsMenu.';

    return {
      landingPageLink$: this.translate.get(baseKey + 'landingPage.url'),
      landingPageTitle$: this.translate.get(baseKey + 'landingPage.title'),
      landingPageSubtitle$: this.translate.get(baseKey + 'landingPage.subtitle'),

      chapters: [
        {
          title$: this.translate.get(baseKey + 'moreTools.title'),
          items: [
            {
              displayText$: this.translate.get(baseKey + 'moreTools.elementTexts.damageSimulator'),
              href$: this.translate.get(baseKey + 'moreTools.urls.damageSimulator')
            },
            {
              displayText$: this.translate.get(baseKey + 'moreTools.elementTexts.damagePotential'),
              href$: this.translate.get(baseKey + 'moreTools.urls.damagePotential')
            },
            {
              displayText$: this.translate.get(baseKey + 'moreTools.elementTexts.surfaceRunoff'),
              href$: this.translate.get(baseKey + 'moreTools.urls.surfaceRunoff')
            },
            {
              displayText$: this.translate.get(baseKey + 'moreTools.elementTexts.floodingMemory'),
              href$: this.translate.get(baseKey + 'moreTools.urls.floodingMemory')
            },
            {
              displayText$: this.translate.get(baseKey + 'moreTools.elementTexts.riskDynamic'),
              href$: this.translate.get(baseKey + 'moreTools.urls.riskDynamic')
            },
          ],
        }
      ]
    };
  }
}
