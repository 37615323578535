import { Component, OnInit } from '@angular/core';
import { MapService } from './map.service';
import { Map } from 'ol';
import { ElementOrientation } from '../../../../mobilab-common/enums/ElementOrientation';

@Component({
  selector: 'how-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['./main-map.component.scss']
})
export class MainMapComponent implements OnInit {
  zoomButtonOrientation = ElementOrientation.vertical;
  map: Map;

  constructor(private readonly mapService: MapService) { }

  ngOnInit() {
    this.map = this.mapService.map;
  }

  zoomToOverview() {
    this.mapService.setDefaultExtent();
  }

  zoom(value: number) {
    this.mapService.zoom(value);
  }
}
