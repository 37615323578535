'use strict';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MapModeFlowlinesService } from '../../../domain/map-mode-flowlines/map-mode-flowlines.service';
import { Flooding } from '../../../shared/enums/Flooding';
import { ILegendAbsoluteDataConfiguration } from '../../../../../mobilab-common/models/legend/configuration/ILegendDataConfiguration';
import { LegendConfigurationService } from '../../../domain/legend/legend-configuration.service';
import { ILegendWithTextConfig } from '../../../domain/legend/legend-with-text-config';
import { IBoxClickEvent } from '../../../../../mobilab-common/models/info-icon/IBoxClickEvent';
import { IInfoPopupData } from '../../../../../mobilab-common/models/IInfoPopupData';
import { InfoPopupService } from '../../../../../mobilab-common/services/info-popup.service';
import { InfoPopupHazardClassesComponent } from '../../info-popup/implementations/info-popup-hazard-classes/info-popup-hazard-classes.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'how-control-flowlines',
  templateUrl: './control-flowlines.component.html',
  styleUrls: ['./control-flowlines.component.scss']
})
export class ControlFlowlinesComponent implements OnInit {
  flooding = Flooding;
  activeFlooding$: Observable<Flooding>;
  impactIsVisible$: Observable<boolean> = of(false);
  impactTranslationKey$: Observable<string>;

  readonly flowDepthLegend$: Observable<ILegendAbsoluteDataConfiguration>;
  readonly hazardClassesLegend: ILegendWithTextConfig;
  readonly streetsImpactsLegend: ILegendWithTextConfig;
  readonly impactsLegend$: Observable<ILegendWithTextConfig>;

  constructor(
    private readonly mapModeFlowlinesService: MapModeFlowlinesService,
    private readonly legendConfigurationService: LegendConfigurationService,
    private readonly infoPopupService: InfoPopupService,
    ) {
    this.flowDepthLegend$ = this.legendConfigurationService.flowDepthLegendConfiguration$;
    this.hazardClassesLegend = this.legendConfigurationService.hazardClassesLegendConfiguration;
    this.streetsImpactsLegend = this.legendConfigurationService.streetsImpactsLegendConfiguration;
    this.impactsLegend$ = this.mapModeFlowlinesService.impactLegendConfig$;
  }

  ngOnInit() {
    this.activeFlooding$ = this.mapModeFlowlinesService.flooding$;
    this.impactIsVisible$ = this.mapModeFlowlinesService.streetsImpactIsVisible$;
    this.impactTranslationKey$ = this.impactIsVisible$.pipe(map(visibility => visibility ? 'impactOn' : 'impactOnStreets'));
  }

  floodingChange(value: Flooding): void {
    this.mapModeFlowlinesService.flooding = value;
  }

  impactVisibilityChange(visible: boolean): void {
    this.mapModeFlowlinesService.streetsImpactIsVisible = visible;
  }

  onClickHazardClassesInfoIcon(event: IBoxClickEvent) {
    const popupConfig: IInfoPopupData = {
      clickEvent: event
    };

    this.infoPopupService.openOverlay(popupConfig, InfoPopupHazardClassesComponent);
  }
}
