import { HttpClient } from '@angular/common/http';
import {Inject, Injectable, OnInit} from '@angular/core';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import {Coordinate} from 'ol/coordinate';
import {UesgAuthenticationService} from './uesg-authentication.service';
import {combineLatest, Observable, of} from 'rxjs';
import {LoggingService} from '../../logging/logging.service';
import {IUesgDefaultImageResponse} from '../../models/uesg/IUesgDefaultImageResponse';
import GeometryType from 'ol/geom/GeometryType';
import * as _ from 'lodash';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {IUesgBboxImageRequestBody} from '../../models/uesg/IUesgBboxImageRequestBody';
import {IUesgBboxImageResponse} from '../../models/uesg/IUesgBboxImageResponse';
import {IMobilabCommonConfig, MOBILAB_COMMON_CONFIG} from '../../mobilab-common.config';
import {CoordinatesTransformationService} from '../coordinates-transformation.service';

/**
 * Service to handle the load images from the UESG (Ueberschwemmungsgedaechtnis) Server.
 * (that is a separate app from which we consume data).
 */
@Injectable({providedIn: 'root'})
export class UesgGalleryService implements OnInit {
  private defaultImages: IUesgBboxImageResponse[] = [];

  constructor(
    private http: HttpClient,
    private logging: LoggingService,
    private authentication: UesgAuthenticationService,
    private coordinatesTransformation: CoordinatesTransformationService,
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig,
  ) {}

  ngOnInit() {
    if (!this._hasConfig) {
      this.logging.warn('The config for UESG is not defined: cannot get images from UESG-Server');
    }

    this._initializeDefaultImages();
  }

  private get _hasConfig(): boolean {
    return !_.isNil(this.mobilabConfig.uesg);
  }

  public getDefaultImages$(): Observable<IUesgBboxImageResponse[]> {
    if (!this._hasConfig) {
      return of([]);
    }

    if (_.isEmpty(this.defaultImages)) {
      return this._loadDefaultImages$().pipe(tap(images => this.defaultImages = images));
    }

    return of(this.defaultImages);
  }

  // =====================================================================
  // ============================ Load Images ============================
  // =====================================================================

  public getImagesInPolygon(polygon: MultiPolygon): Observable<IUesgBboxImageResponse[]> {
    if (!this._hasConfig) {
      return of([]);
    }

    const body = this._createUesgBboxImageRequest(polygon);
    const url = this.mobilabConfig.uesg.url + '/api/images/bbox';

    return this.authentication.getHeaders$().pipe(
      switchMap(headers => this.http.post<IUesgBboxImageResponse[]>(url, body, headers)),
    );
  }

  private _createUesgBboxImageRequest(polygon: MultiPolygon): IUesgBboxImageRequestBody {
    const bbox = this.coordinatesTransformation.transformExtent(
      polygon.getExtent(), this.mobilabConfig.basicMapConfiguration.epsg, this.mobilabConfig.uesg.epsg
    );
    const centroid = this.coordinatesTransformation.transformCoordinates(
      this._getCentroid(polygon), this.mobilabConfig.basicMapConfiguration.epsg, this.mobilabConfig.uesg.epsg
    );

    return {
      bbox: {
        minLng: bbox[0],
        minLat: bbox[1],
        maxLng: bbox[2],
        maxLat: bbox[3]
      },
      centroid: {
        lng: centroid[0],
        lat: centroid[1]
      },
      maxCount: this.mobilabConfig.uesg.maxImg
    };
  }

  private _getCentroid(geometry: SimpleGeometry): Coordinate {
    const geometryType = geometry.getType();

    switch (geometryType) {
      case GeometryType.POLYGON:
        return (<Polygon>geometry).getInteriorPoint().getCoordinates();
      case GeometryType.MULTI_POLYGON:
        const multiPolygon = geometry as MultiPolygon;
        const biggestPolygon = _.maxBy(multiPolygon.getPolygons(), polygon => polygon.getArea());
        return biggestPolygon.getInteriorPoint().getCoordinates();
      default:
        throw Error(`Invalid geometry-type: '${geometryType}'`);
    }
  }

  // =====================================================================
  // ======================= Load Default Images =========================
  // =====================================================================

  private _initializeDefaultImages() {
    this._loadDefaultImages$().subscribe(images => this.defaultImages = images || []);
  }

  private _loadDefaultImages$(): Observable<IUesgBboxImageResponse[]> {
    const imageRequests$ = _.map(this.mobilabConfig.uesg.defaultImageIDs, imageId => {
      const url = `${this.mobilabConfig.uesg.url}/api/images/small/${imageId}`;
      return this.authentication.getHeaders$().pipe(
        switchMap(headers => this.http.get<IUesgDefaultImageResponse>(url, headers)),
      );
    });

    return combineLatest(imageRequests$).pipe(
      catchError(error => {
        this.logging.error('Failed to retrieve default images from UESG-Server', error);
        return [];
      }),
      map(images => _.map(images, image => (<IUesgBboxImageResponse> {
            id: image._id,
            author: image.author,
            location: image.location,
            copyright: image.copyright,
            date: image.date,
            thumbnail: image.image
      }))),
    );
  }
}
