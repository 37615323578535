import { ComponentType } from '@angular/cdk/portal';
import { Component, Input } from '@angular/core';
import { IInfoPopupData } from '../../../../../mobilab-common/models/IInfoPopupData';
import { IBoxClickEvent } from '../../../../../mobilab-common/models/info-icon/IBoxClickEvent';
import { InfoPopupService } from '../../../../../mobilab-common/services/info-popup.service';
import { ILegendWithTextConfig } from '../../../domain/legend/legend-with-text-config';

@Component({
  selector: 'how-legend-with-text',
  templateUrl: './legend-with-text.component.html',
  styleUrls: ['./legend-with-text.component.scss']
})
export class LegendWithTextComponent {
  @Input() config: ILegendWithTextConfig;

  constructor(
    private readonly infoPopupService: InfoPopupService
  ) { }

  onClickInfoIcon(event: IBoxClickEvent, infoType: ComponentType<any>) {
    const popupConfig: IInfoPopupData = {
      clickEvent: event
    };
    this.infoPopupService.openOverlay(popupConfig, infoType);
  }
}
