'use strict';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IInfoPopupData } from '../../../../mobilab-common/models/IInfoPopupData';
import { IBoxClickEvent } from '../../../../mobilab-common/models/info-icon/IBoxClickEvent';
import { InfoPopupService } from '../../../../mobilab-common/services/info-popup.service';
import { ScenarioService } from '../../domain/scenario/scenario.service';
import { Scenario } from '../../openapi/models/scenario';
import { MapMode } from '../../shared/enums/MapMode';
import { MapService } from '../main-map/map.service';
import { InfoPopupSwitzerlandComponent } from '../info-popup/implementations/info-popup-switzerland/info-popup-switzerland.component';
import { InfoPopupFlowlinesComponent } from '../info-popup/implementations/info-popup-flowlines/info-popup-flowlines.component';
import { map } from 'rxjs/operators';
import { LocaleService } from '../../../../mobilab-common/services/locale.service';

@Component({
  selector: 'how-control-mapmode',
  templateUrl: './control-mapmode.component.html',
  styleUrls: ['./control-mapmode.component.scss'],
})
export class ControlMapModeComponent implements OnInit {
  readonly mapMode = MapMode;
  activeMapMode$: Observable<MapMode>;
  scenario$: Observable<Scenario>;
  scenariosUrl$: Observable<string[]>;

  constructor(
    private readonly scenarioService: ScenarioService,
    private readonly mapService: MapService,
    private readonly infoPopupService: InfoPopupService,
    private readonly  localeService: LocaleService
  ) { }

  ngOnInit() {
    this.activeMapMode$ = this.mapService.mapMode$;
    this.scenario$ = this.scenarioService.currentScenario$;
    this.scenariosUrl$ = this.localeService.currentLanguageStream$.pipe(map(x => ['/', x, 'scenarios']));
  }

  public onClickSwitzerland() {
    this.mapService.setDefaultExtent();
  }

  onClickFlowlinesInfoIcon(event: IBoxClickEvent) {
    const popupConfig: IInfoPopupData = {
      clickEvent: event
    };

    this.infoPopupService.openOverlay(popupConfig, InfoPopupFlowlinesComponent);
  }

  onClickSwitzerlandInfoIcon(event: IBoxClickEvent) {
    const popupConfig: IInfoPopupData = {
      clickEvent: event
    };

    this.infoPopupService.openOverlay(popupConfig, InfoPopupSwitzerlandComponent);
  }
}
