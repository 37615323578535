import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ILegendDataConfiguration} from '../../../models/legend/configuration/ILegendDataConfiguration';
import {ValueType} from '../../../enums/ValueType';
import * as _ from 'lodash';
import {IBoxClickEvent} from '../../../models/info-icon/IBoxClickEvent';
import {LocaleService} from '../../../services/locale.service';

@Component({
  selector: 'mob-legend-panel-relative',
  templateUrl: './legend-generic-relative.component.html',
  styleUrls: ['./legend-generic-relative.component.scss']
})
export class LegendGenericRelativeComponent {
  @Input() readonly config: ILegendDataConfiguration;
  @Output() readonly noValueInfoIconClicked = new EventEmitter<IBoxClickEvent>();

  readonly integerMax = Number.MAX_SAFE_INTEGER;

  constructor(private localeService: LocaleService) {}

  mapValueForDisplay(value: number): string {
    if (_.isNil(value)) {
      return '';
    }

    if (_.isNil(this.config.valueMappingFunction)) {
      return this.defaultValueMappingFunction(value);
    }

    return this.config.valueMappingFunction(value, ValueType.Relative).displayValue;
  }


  defaultValueMappingFunction(value: number): string {
    return _.round(value, 1).toLocaleString(this.localeService.currentLocale);
  }
}
