import { Component } from '@angular/core';

@Component({
  selector: 'how-deprecated-browser-warning',
  templateUrl: './deprecated-browser-warning.component.html',
  styleUrls: ['./deprecated-browser-warning.component.scss']
})
export class DeprecatedBrowserWarningComponent {

}
