import { Options as BaseOptions } from 'ol/layer/BaseVector';
import { Impact } from '../../shared/enums/Impact';
import { DynamicLayer } from '../time/dynamic-layer';
import { ScenarioLayer } from '../scenario/scenario-layer';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { FeatureLike } from 'ol/Feature';
import { ScenariosService as ScenariosApiService } from '../../openapi/services/scenarios.service';
import { ImpactValueType } from '../../openapi/models/impact-value-type';
import { TimeMode } from '../time/time-mode.enum';
import { Scenario } from '../../openapi/models/scenario';
import { ImpactMapping, ImpactStrokeWidths } from './impact';
import { Observable } from 'rxjs';

interface IGiveTime {
  currentRealTimeFullHours$: Observable<number>;
  timeMode$: Observable<TimeMode>;
}

export class ImpactLayer extends VectorLayer implements DynamicLayer, ScenarioLayer {
  public static toolBlau = 'rgb(44, 76, 143)';

  constructor(options: BaseOptions, private scenarioApiService: ScenariosApiService, private timeService: IGiveTime) {
    super(options);
    this.timeService.currentRealTimeFullHours$.subscribe(t => this.currentTime = t);
    this.setStyle((feature, resolution) => this.getDynamicStyle(feature, resolution));
    this.timeService.timeMode$.subscribe(t => this.timeMode = t);
  }

  get scenario(): Scenario {
    return this._scenario;
  }

  set scenario(value: Scenario) {
    this._scenario = value;
    this.loadImpactValues();
  }

  get impactValueType(): ImpactValueType {
    switch (this._timeMode) {
      case TimeMode.Dynamic: return ImpactValueType.Actual;
      case TimeMode.Maximum: return ImpactValueType.Aggregated;
    }
  }

  get timeMode(): TimeMode {
    return this._timeMode;
  }

  set timeMode(value: TimeMode) {
    this._timeMode = value;
    this.loadImpactValues();
  }

  get currentTime(): number {
    return this._currentTime;
  }

  set currentTime(value: number) {
    this._currentTime = value;
    this.changed();
  }

  get impact(): Impact {
    return this._impact;
  }

  set impact(value: Impact) {
    this._impact = value;
    this.loadImpactValues();
  }

  get defaultStyle(): Style {
    return new Style({
      stroke: new Stroke({
        color: ImpactLayer.toolBlau,
        width: ImpactStrokeWidths[0],
      }),
    });
  }

  get dottedStyle(): Style {
    return new Style({
      stroke: new Stroke({
        lineDash: [3, 6],
        color: ImpactLayer.toolBlau,
        width: ImpactStrokeWidths[0],
      }),
    });
  }

  private _styles = {
    0: ImpactLayer.createStyle(ImpactStrokeWidths[0]),
    1: ImpactLayer.createStyle(ImpactStrokeWidths[1]),
    2: ImpactLayer.createStyle(ImpactStrokeWidths[2]),
    3: ImpactLayer.createStyle(ImpactStrokeWidths[3]),
    4: ImpactLayer.createStyle(ImpactStrokeWidths[4])
  };

  private _scenario: Scenario;
  private _timeMode: TimeMode;
  private _currentTime: number;
  private _impact: Impact;
  private _flowlinesImpacts: { [key: string]: Array<any> };

  private static createStyle(width: number) {
    return new Style({
      stroke: new Stroke({
        color: ImpactLayer.toolBlau,
        width,
      }),
    });
  }

  getDynamicStyle(feature: FeatureLike, _: number) {
    // There are no impacts
    if (!this._flowlinesImpacts) {
      return this.defaultStyle;
    }

    // Feature is not modelled
    if (!feature.get('is_modelled')) {
      return this.dottedStyle;
    }

    const flowlineKey = feature.get('key');
    const flowlineImpacts = this._flowlinesImpacts[flowlineKey];

    // Flowline not present
    if (flowlineImpacts === undefined) {
      return this.defaultStyle;
    }

    let value: number;
    if (this.impactValueType === ImpactValueType.Aggregated) {
      value = flowlineImpacts[flowlineImpacts.length - 1];
    } else {
      // Undefined time
      if (this.currentTime === undefined) {
        return this.defaultStyle;
      }
      const currentTimeInteger = Math.round(this.currentTime);
      // Default values for time 0 or out of range
      if (currentTimeInteger === 0 || currentTimeInteger > flowlineImpacts.length - 1) {
        return this.defaultStyle;
      }
      // Map time [1:max] to array id [0:max-1]
      value = flowlineImpacts[currentTimeInteger - 1];
    }

    const styleIndex = ImpactMapping[this._impact].limits.findIndex((e) => value <= e);
    return this._styles[styleIndex];
  }

  private loadImpactValues() {
    if (!this.scenario || !this.impact) {
      return;
    }

    this.scenarioApiService.getScenarioImpacts$Json(
      { scenarioKey: this._scenario.key, impact: this._impact, valueType: this.impactValueType })
      .subscribe(v => {
        this._flowlinesImpacts = v;
        this.changed();
      });
  }
}
