<div class="map-container" *ngIf="map">
  <how-map [map]="map"></how-map>
  <how-scaleline [map]="map"></how-scaleline>

  <mob-zoom-buttons
    class="zoom-buttons"
    [tooltipZoomIn]="'zoomIn' | translate"
    [tooltipZoomToOverview]="'defaultView' | translate"
    [tooltipZoomOut]="'zoomOut' | translate"
    [orientation]="zoomButtonOrientation"
    (zoomChange)="zoom($event)"
    (zoomToOverview)="zoomToOverview()"
  ></mob-zoom-buttons>
</div>

<how-tooltip></how-tooltip>
<how-pop-up></how-pop-up>
