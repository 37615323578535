import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Options } from '@angular-slider/ngx-slider';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TimeService } from '../../domain/time/time.service';
import { TimeMode } from '../../domain/time/time-mode.enum';
import { MapService } from '../main-map/map.service';
import { MapMode } from '../../shared/enums/MapMode';
import { MapModeFlowlinesService } from '../../domain/map-mode-flowlines/map-mode-flowlines.service';
import { MapModeSwitzerlandService } from '../../domain/map-mode-switzerland/map-mode-switzerland.service';
import { appMenuItems } from '../../domain/cms-access/grav-config';
import { AdditionalInformationService } from '../../../../mobilab-common/services/additional-information.service';
import { ScenarioService } from '../../domain/scenario/scenario.service';

@Component({
  selector: 'how-time-slider',
  templateUrl: './time-slider.component.html',
  styleUrls: ['./time-slider.component.scss']
})
export class TimeSliderComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  timeMode = TimeMode;
  mapMode = MapMode;
  activeTimeMode$;
  activeMapMode$: Observable<MapMode>;
  precipitationVisible$: Observable<string>;
  precipitationMode$: Observable<string>;
  streetsVisible$: Observable<string>;
  floodingMode$: Observable<string>;
  methodLink$: Observable<string[]>;
  resetTime: number;

  currentTime$: Observable<number>;
  isPlaying$: Observable<boolean>;
  maximumTime$: Observable<number>;
  options: Options = {
    floor: 0,
    ceil: 1,
    step: .5,
    showSelectionBar: true,
    ticksArray: [],
    hideLimitLabels: true,
    hidePointerLabels: true,
    enforceStep: false,
    animate: true,
    getTickColor: (_: number): string => {
      return '#D5DBE9'; // color-app-main-lighter
    },
  };

  constructor(
    private timeService: TimeService,
    private translate: TranslateService,
    private mapService: MapService,
    private scenarioService: ScenarioService,
    private mapModeSwitzerlandService: MapModeSwitzerlandService,
    private mapModeFlowlinesService: MapModeFlowlinesService,
    private additionalInformationService: AdditionalInformationService,
  ) {
      this.methodLink$ = this.additionalInformationService.getRouterLink$(appMenuItems.method);
    }

  ngOnInit() {
    this.currentTime$ = this.timeService.currentRealTime$;
    this.isPlaying$ = this.timeService.isPlaying$;
    this.translateLegend(this.translate.translations[this.translate.currentLang]);
    this.subscriptions.push(this.translate.onLangChange.subscribe(x => {
      this.translateLegend(x.translations);
    }));
    this.maximumTime$ = this.timeService.duration$.pipe(
      tap(duration => {
        this.updateOptions('ceil', duration);
        this.updateOptions('ticksArray', Array.from(Array(Math.floor(duration / 24)).keys()).map(x => (x + 1) * 24));
        this.resetTime = duration;
      }));
    this.activeTimeMode$ = this.timeService.timeMode$;

    this.activeMapMode$ = this.mapService.mapMode$;
    this.precipitationMode$ = this.mapModeSwitzerlandService.precipitation$;
    this.floodingMode$ = this.mapModeFlowlinesService.flooding$;
    this.precipitationVisible$ = this.mapModeSwitzerlandService.precipitationIsVisible$.pipe(map(visibility =>
      visibility ? 'prec' : 'noPrec'));
    this.streetsVisible$ = this.mapModeFlowlinesService.streetsImpactIsVisible$.pipe(map(visibility =>
      visibility ? 'streets' : 'noStreets'));
    this.subscriptions.push(this.scenarioService.currentScenario$.subscribe(
      () => {
        this.timeService.timeMode = this.timeMode.Dynamic;
        this.timeService.currentRealTime = 0;
        this.timeService.isPlaying = true;
      })
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  timeModeChange(value: TimeMode) {
    this.timeService.timeMode = value;
  }

  userChange(value: number) {
    this.pause();
    this.setCurrentTime(value);
  }

  setCurrentTime(value: number) {
    this.timeService.currentRealTime = value;
  }

  play() {
    if (this.timeService.currentRealTime === this.resetTime) {
      this.timeService.currentRealTime = 0;
    }
    this.timeService.isPlaying = true;
  }

  pause() {
    this.timeService.isPlaying = false;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      this.timeService.isPlaying ? this.pause() : this.play();
    }
  }

  private translateLegend(translations: any) {
    this.updateOptions('getLegend', (value: number): string => {
      if (value < 24) {
        return '';
      } else if (value === 24) {
        return `1 ${translations.day ?? ''}`;
      }
      return `${value / 24} ${translations.days ?? ''}`;
    });
  }

  private updateOptions(key: string, value: any) {
    const newOptions: Options = Object.assign({ }, this.options);
    newOptions[key] = value;
    this.options = newOptions;
  }
}
