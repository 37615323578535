import { Injectable } from '@angular/core';
import {BoundingBoxProvider} from '../../models/BoundingBoxProvider';
import {Extent} from 'ol/extent';
import * as _ from 'lodash';

/**
 * Provides the current bounding-box of the map (if available).
 */
@Injectable({
  providedIn: 'root'
})
export class BoundingBoxProviderService {
  private _boundingBoxProvider: BoundingBoxProvider;

  constructor() {}

  set boundingBoxProvider(provider: BoundingBoxProvider) {
    this._boundingBoxProvider = provider;
  }

  get currentBoundingBox(): Extent | null {
    if (_.isNil(this._boundingBoxProvider)) {
      return null;
    }

    return this._boundingBoxProvider();
  }
}
