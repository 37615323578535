import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, sampleTime } from 'rxjs/operators';
import { Feature, Map, MapBrowserEvent, View } from 'ol';
import * as Projection from 'ol/proj';
import { CoordinateProjection } from '../../../../mobilab-common/enums/CoordinateProjection';
import TileLayer from 'ol/layer/Tile';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent, containsExtent, Extent } from 'ol/extent';
import { HttpClient } from '@angular/common/http';
import BaseLayer from 'ol/layer/Base';
import { GeoJSON, WMTSCapabilities } from 'ol/format';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import * as Interaction from 'ol/interaction';
import { MapMode } from '../../shared/enums/MapMode';
import { zoomByDelta } from 'ol/interaction/Interaction';
import { ImageWMS, Vector } from 'ol/source';
import { Image as ImageLayer } from 'ol/layer';
import { environment } from '../../../environments/environment';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import OlMap from 'ol/Map';
import { Pixel } from 'ol/pixel';
import { HoverFeature } from '../../domain/map/hover-feature';
import { StatisticsTrackerService } from '../../../../mobilab-common/services/statistics-tracker.service';
import { appConfiguration } from '../../shared/app-configuration';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public readonly map = new Map({
    view: MapService.defaultView,
    controls: [],
    interactions: Interaction.defaults({ altShiftDragRotate: false, pinchRotate: false })
  });
  private _resolution = new BehaviorSubject<number>(600);
  private _hoveredFeature = new BehaviorSubject<HoverFeature | null>(null);
  private _selectedFeature = new BehaviorSubject<HoverFeature | null>(null);

  private readonly _modelledFloodPlainsLayer: VectorLayer;
  private readonly _borderSwitzerland: VectorLayer;

  constructor(private httpClient: HttpClient, private statisticsTracker: StatisticsTrackerService) {
    this.map.on('moveend', e => this._resolution.next(e.map.getView().getResolution()));

    this.backgroundLayers$.subscribe(layers => {
      for (const layer of layers) {
        this.map.addLayer(layer);
      }
    });

    this._modelledFloodPlainsLayer = new VectorLayer({
      source: new Vector({
        url: MapService.featureUrl('FloodplainBoundariesModelled'),
        format: new GeoJSON(),
      }),
      zIndex: 10,
      visible: true,
      style: new Style({ }),
    });
    this._borderSwitzerland = MapService.borderModelledArea;

    this.staticDataLayers.forEach(layer => this.map.addLayer(layer));

    combineLatest([
      // @ts-ignore
      fromEvent(this.map, 'pointermove').pipe(sampleTime(10)),
      this._resolution,
    ]).subscribe(([ev, _]: [MapBrowserEvent, any])  => this.onHoverOverFeature(ev));
    this._resolution.subscribe(() => {
      this._modelledFloodPlainsLayer.setStyle(this.flowlineDefaultStyle);
    });

    // @ts-ignore
    fromEvent(this.map, 'singleclick').pipe(
      map((event: MapBrowserEvent) => {
        const features = this.map.getFeaturesAtPixel(
          event.pixel,
          { layerFilter: layer => layer === this._borderSwitzerland || layer === this._modelledFloodPlainsLayer, hitTolerance: 5 });
        if (!features.length && this._resolution.value >= 100) {
          features.push(MapService.getFeatureAtPixel(this.map, event.pixel, this._modelledFloodPlainsLayer, 5));
        }
        return {
          feature: features.length ? features[0] : null,
          coordinate: event.coordinate} as HoverFeature;
      }),
    ).subscribe((feature) => this.selectedFeature = feature);
  }

  get zoomLevel$(): Observable<number> {
    return this._resolution.pipe(map(MapService.computeZoomLevel));
  }

  get mapMode$(): Observable<MapMode> {
    return this.zoomLevel$.pipe(
      map(zoomLevel => MapService.zoomLevelToMapMode(zoomLevel)),
      distinctUntilChanged());
  }

  get selectedFeature$(): Observable<HoverFeature | null> {
    return this._selectedFeature.asObservable().pipe(distinctUntilChanged());
  }

  get selectedFeature(): HoverFeature | null {
    return this._hoveredFeature.value;
  }

  set selectedFeature(value: HoverFeature | null) {
    const style = new Style({
      stroke: new Stroke({
        color: '#000',
        width: 4,
      }),
    });
    this._selectedFeature.value?.feature?.setStyle(null);
    if (value?.feature) {
      const feature = value?.feature;
      feature.setStyle(style);
      if (feature.getId().toString().startsWith('MaskSwitzerland')) {
        this.setDefaultExtent();
      } else if (this._resolution.value < 100) {
        this.zoomToExtentWithPopUp(feature.getGeometry().getExtent());
      }
      this.statisticsTracker.trackEvent(
        appConfiguration.trackedEvents.mapFeatureSelect,
        `${this._resolution.value}-${feature.getId()}`);
    }

    this._selectedFeature.next(value);
  }

  get hoveredFeature$(): Observable<HoverFeature | null> {
    return this._hoveredFeature.asObservable().pipe(distinctUntilChanged());
  }

  get hoveredFeature(): HoverFeature | null {
    return this._hoveredFeature.value;
  }

  set hoveredFeature(value: HoverFeature | null) {
    if (this._hoveredFeature.value?.feature !== this._selectedFeature.value?.feature) {
      this._hoveredFeature.value?.feature?.setStyle(null);
    }
    if (value?.feature !== this._selectedFeature.value?.feature) {
      value?.feature?.setStyle(new Style({
        stroke: new Stroke({
          color: '#000',
          width: 4,
        }),
      }));
    }

    this._hoveredFeature.next(value);
  }

  private get backgroundLayers$(): Observable<Array<BaseLayer>> {
    return this.httpClient
      .get('https://wmts.geo.admin.ch/EPSG/3857/1.0.0/WMTSCapabilities.xml', { responseType: 'text'})
      .pipe(
        map(w => MapService.createBaseLayer(w)),
      );
  }

  private static createBaseLayer(capabilities: string): Array<BaseLayer> {
    const parser = new WMTSCapabilities();
    const parsedCapabilities = parser.read(capabilities);
    return [
      new TileLayer({
        source: new WMTS(optionsFromCapabilities(parsedCapabilities, { layer: 'ch.swisstopo.pixelkarte-grau'})),
        minResolution: 20,
        opacity: 0.5,
      }),
      new TileLayer({
        source: new WMTS(optionsFromCapabilities(parsedCapabilities, { layer: 'ch.swisstopo.swisstlm3d-karte-grau'})),
        maxResolution: 20,
        opacity: 1
      })
    ];
  }

  private get staticDataLayers(): Array<BaseLayer> {
    return [
      MapService.borderSwitzerland,
      MapService.lakes,
      MapService.rivers,
      this._modelledFloodPlainsLayer,
      MapService.maskFloodplains,
      MapService.maskSwitzerland,
      this._borderSwitzerland,
    ];
  }

  public static get borderModelledArea(): VectorLayer {
    return new VectorLayer({
      source: new Vector({
        url: MapService.featureUrl('MaskSwitzerland'),
        format: new GeoJSON(),
      }),
      style: new Style({
        stroke: new Stroke({ color : 'rgba(246,99,13,.5)', width: 4 }),
      }),
      minResolution: 100,
      zIndex: 6,
    });
  }

  public static get maskSwitzerland(): VectorLayer {
    return new VectorLayer({
      source: new Vector({
        url: MapService.featureUrl('MaskSwitzerland'),
        format: new GeoJSON(),
      }),
      style: new Style({
        fill: new Fill({ color : 'rgba(120,120,120,.5)' })
      }),
      minResolution: 100,
      zIndex: 6,
    });
  }

  public static get maskFloodplains(): VectorLayer {
    return new VectorLayer({
      source: new Vector({
        url: MapService.featureUrl('MaskFloodplain'),
        format: new GeoJSON(),
      }),
      style: new Style({
        stroke: new Stroke({ color : 'rgba(50,50,50,.5)' }),
        fill: new Fill({ color : 'rgba(100,100,100,.5)' })
      }),
      maxResolution: 100,
      zIndex: 7,
    });
  }

  public static get lakes(): ImageLayer {
    return new ImageLayer({
      source: new ImageWMS({
        url: `${environment.backendUrl}/geoserver/Hochwasserdynamik/wms`,
        params: { layers: 'Lake' },
      }),
      zIndex: 9,
    });
  }

  public static get rivers(): ImageLayer {
    return new ImageLayer({
      source: new ImageWMS({
        url: `${environment.backendUrl}/geoserver/Hochwasserdynamik/wms`,
        params: { layers: 'River' },
      }),
      zIndex: 8,
      maxResolution: 100,
    });
  }

  public static get borderSwitzerland(): VectorLayer {
    return new VectorLayer({
      source: new Vector({
        url: MapService.featureUrl('BorderSwitzerland'),
        format: new GeoJSON(),
      }),
      zIndex: 11,
      style: new Style({
        stroke: new Stroke({
          color: 'rgb(56,168,0)',
          width: 2,
        }),
      }),
    });
  }

  public static get defaultView(): View {
    return new View({
      projection: Projection.get(CoordinateProjection.EPSG3857),
      center: this.defaultCenter,
      resolution: 600,
      extent: this.defaultExtent,
      constrainOnlyCenter: true,
      resolutions: [600, 300, 100, 50, 20, 10, 5, 2.5, 2, 1]
    });
  }

  public static featureUrl(layerName: string): string {
    return `${environment.backendUrl}/geoserver/Hochwasserdynamik/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=Hochwasserdynamik%3A${layerName}&outputFormat=application/json&srsname=EPSG:3857`;
  }

  /**
   * Retrieve feature at given pixel. In contrast to map.getFeaturesAtPixel(),
   * which also looks how the feature is rendered, this one only considers the geometry of
   * features in the selected layer.
   */
  public static getFeatureAtPixel(olMap: OlMap, pixel: Pixel, layer: VectorLayer, hitTolerance: number): Feature {
    const pixel1 = [pixel[0] - hitTolerance, pixel[1] - hitTolerance];
    const pixel2 = [pixel[0] + hitTolerance, pixel[1] + hitTolerance];
    const extent = boundingExtent([olMap.getCoordinateFromPixel(pixel1), olMap.getCoordinateFromPixel(pixel2)]);
    if (layer.getSource().forEachFeatureInExtent(extent, x => x)) {
      return layer.getSource().getClosestFeatureToCoordinate(olMap.getCoordinateFromPixel(pixel));
    }
  }

  public static isPixelInsideFeature(olMap: OlMap, pixel: Pixel, layer: VectorLayer, hitTolerance: number): boolean {
    const pixel1 = [pixel[0] - hitTolerance, pixel[1] - hitTolerance];
    const pixel2 = [pixel[0] + hitTolerance, pixel[1] + hitTolerance];
    const extent = boundingExtent([olMap.getCoordinateFromPixel(pixel1), olMap.getCoordinateFromPixel(pixel2)]);
    let foundFeature = false;
    layer.getSource().getFeatures().forEach(x => {
      if (x.getGeometry().intersectsExtent(extent)) {
        foundFeature = true;
      }
    });
    return foundFeature;
  }

  public static get defaultExtent(): Extent {
    return Projection.transformExtent(
      [5.9, 45.8, 10.5, 47.9],
      CoordinateProjection.EPSG4326,
      CoordinateProjection.EPSG3857
    );
  }

  public static get defaultCenter(): Coordinate {
    return Projection.fromLonLat([8.1, 46.783417]);
  }

  private static computeZoomLevel(resolution: number): number {
    if (resolution >= 100) {
      return 1;
    } else if (resolution >= 50) {
      return 2;
    } else if (resolution >= 20) {
      return 3;
    } else {
      return 4;
    }
  }

  private static zoomLevelToMapMode(zoomLevel: number): MapMode {
    switch (zoomLevel) {
      case 1:
        return MapMode.Switzerland;
      case 2:
      case 3:
      case 4:
        return MapMode.Floodplain;
      default:
        throw new Error(`Illegal zoom level: ${zoomLevel}`);
    }
  }

  private get flowlineDefaultStyle() {
    return this._resolution.value < 100 ?
      new Style({
            stroke: new Stroke({
              color: 'rgb(0,132,129)',
              width: 1,
            })}) : new Style({ });
  }

  private onHoverOverFeature(event: MapBrowserEvent) {
    const borderSwitzerlandFeatures = this.map.getFeaturesAtPixel(
      event.pixel,
      { layerFilter: layer => layer === this._borderSwitzerland, hitTolerance: 5 });
    const switzerlandFeature = borderSwitzerlandFeatures.length ? borderSwitzerlandFeatures[0] as Feature : null;
    if (switzerlandFeature) {
      this.hoveredFeature = {
        feature: switzerlandFeature,
        coordinate: event.coordinate,
        label: 'catchmentArea',
      };
      return;
    }

    let flowlineFeature: Feature;
    if (this._resolution.value < 100) {
      flowlineFeature = this.map.forEachFeatureAtPixel(
        event.pixel,
        f => f,
        { layerFilter: layer => layer === this._modelledFloodPlainsLayer, hitTolerance: 15 }) as Feature;
      if (!flowlineFeature && !MapService.isPixelInsideFeature(this.map, event.pixel, this._modelledFloodPlainsLayer, 5)) {
        this.hoveredFeature = {
          feature: null,
          coordinate: event.coordinate,
          label: 'outsideModelledPerimeter',
        };
        return;
      }
    } else {
      flowlineFeature = MapService.getFeatureAtPixel(this.map, event.pixel, this._modelledFloodPlainsLayer, 5);
    }

    if (flowlineFeature) {
      this.hoveredFeature = {
        feature: flowlineFeature,
        coordinate: event.coordinate,
        label: 'flowline.' + flowlineFeature.get('key'),
      };
      return;
    }

    this.hoveredFeature = null;
  }

  public setDefaultExtent(): void {
    this.map.getView().animate({
      center: MapService.defaultCenter,
      resolution: 600,
    });
  }

  public setDefaultExtentInstant(): void {
    this.map.setView(MapService.defaultView);
  }

  public addLayer(layer: BaseLayer): void {
    this.map.addLayer(layer);
  }

  public zoom(value: number): void {
    zoomByDelta(this.map.getView(), value, null, 250);
  }

  public zoomToExtent(extent: Array<number>) {
    this.map.getView().animate({
      center: [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2],
      resolution: 50,
    });
  }

  public zoomToExtentWithPopUp(extent: Array<number>) {
    this.zoomToExtent(extent);
    this.panToPopUp();
  }

  private panToPopUp() {
    setTimeout(() => {
      const overlay = this.map.getOverlayById('PopUp');
      const element = overlay.getElement();
      const mapRect = this._getRect(this.map.getTargetElement(), this.map.getSize());
      const overlayRect = this._getRect(element, [element.clientWidth, element.clientHeight]);
      const margin = 20;

      if (!containsExtent(mapRect, overlayRect)) {
        // the overlay is not completely inside the viewport, so pan the map
        const offsetLeft = overlayRect[0] - mapRect[0];
        const offsetRight = mapRect[2] - overlayRect[2];
        const offsetTop = overlayRect[1] - mapRect[1];
        const offsetBottom = mapRect[3] - overlayRect[3];

        const delta = [0, 0];
        if (offsetLeft < 0) {
          // move map to the left
          delta[0] = offsetLeft - margin;
        } else if (offsetRight < 0) {
          // move map to the right
          delta[0] = Math.abs(offsetRight) + margin;
        }
        if (offsetTop < 0) {
          // move map up
          delta[1] = offsetTop - margin;
        } else if (offsetBottom < 0) {
          // move map down
          delta[1] = Math.abs(offsetBottom) + margin;
        }

        if (delta[0] !== 0 || delta[1] !== 0) {
          const center = this.map.getView().getCenter();
          const centerPx = this.map.getPixelFromCoordinate(center);
          const newCenterPx = [
            centerPx[0] + delta[0],
            centerPx[1] + delta[1]
          ];

          this.map.getView().animate({
            center: this.map.getCoordinateFromPixel(newCenterPx),
            duration: overlay.getOptions().autoPanAnimation.duration,
          });
        }
      }
    }, 1100);
  }

  private _getRect(element, size): Extent {
    const box = element.getBoundingClientRect();
    const offsetX = box.left + window.pageXOffset;
    const offsetY = box.top + window.pageYOffset;
    return [
      offsetX,
      offsetY,
      offsetX + size[0],
      offsetY + size[1]
    ];
  }
}
