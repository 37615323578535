import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IInfoPopupData, INFO_POPUP_DATA } from '../../../../../../mobilab-common/models/IInfoPopupData';
import { AdditionalInformationService } from '../../../../../../mobilab-common/services/additional-information.service';
import { appMenuItems } from '../../../../domain/cms-access/grav-config';

@Component({
  selector: 'how-info-popup-unmodelled-flowlines',
  templateUrl: './info-popup-unmodelled-flowlines.component.html',
  styleUrls: ['./info-popup-unmodelled-flowlines.component.scss']
})
export class InfoPopupUnmodelledFlowlinesComponent {
  readonly pathWithinCms = 'text_howadyn/infopopup/unmodelled_flowlines';
  methodLink$: Observable<string[]>;

  constructor(
    private overlayRef: OverlayRef,
    private additionalInformationService: AdditionalInformationService,
    @Inject(INFO_POPUP_DATA) public data: IInfoPopupData,
  ) {
    this.methodLink$ = this.additionalInformationService.getRouterLink$(appMenuItems.method);
  }

  closeOverlay() {
    this.overlayRef.dispose();
  }
}
