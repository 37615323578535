import {Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';

export const browsers = {
  firefox: 'firefox',
  internetExplorer: 'ie',
  edgeOld: 'ms-edge',
  edgeChromium: 'ms-edge-chromium',
  safari: 'safari',
  chrome: 'chrome'
};

export const operatingSystems = {
  apple: [
    'mac',    // tablet and desktop
    'ios',    // iPhone
  ],
};

/**
 * Service to track the device, browser, media, etc. the application is running on.
 */
@Injectable({
  providedIn: 'root'
})
export class BrowserAndDeviceService {

  constructor(
    private deviceService: DeviceDetectorService,
  ) {}

  // =====================================================================
  // =========================== Device Type =============================
  // =====================================================================

  public get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  // =====================================================================
  // ============================= Browser ===============================
  // =====================================================================

  get browserName(): string {
    return _.toLower(this.deviceService.browser);
  }

  get isDeprecatedBrowser(): boolean {
    return this.isInternetExplorer;
  }

  get isInternetExplorer(): boolean {
    return (_.toLower(this.deviceService.browser) === browsers.internetExplorer);
  }

  get isFirefox(): boolean {
    return (_.toLower(this.deviceService.browser) === browsers.firefox);
  }

  get isChrome(): boolean {
    return (_.toLower(this.deviceService.browser) === browsers.chrome);
  }

  get isSafari(): boolean {
    return (_.toLower(this.deviceService.browser) === browsers.safari);
  }

  // =====================================================================
  // ========================= Operating System ==========================
  // =====================================================================

  get isAppleOs(): boolean {
    const operatingSystem = _.toLower(this.deviceService.os);
    const appleOperatingSystems = _.values(operatingSystems.apple);

    return _.includes(appleOperatingSystems, operatingSystem);
  }

  // =====================================================================
  // =========================== Other / Mixed ===========================
  // =====================================================================

  get isTabletSafari(): boolean {
    return this.isTablet && this.isSafari;
  }

  get isIPad(): boolean {
    return this.isTablet && this.isAppleOs;
  }

  get isIPadChrome(): boolean {
    return this.isIPad && this.isChrome;
  }
}
