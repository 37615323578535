<div class="header">
  <mat-icon class="eye-icon" *ngIf="visible" (click)="setVisibility(false)">visibility</mat-icon>
  <mat-icon class="eye-icon" *ngIf="!visible"(click)="setVisibility(true)">visibility_off</mat-icon>

  <div class="header-content" (click)="setVisibility(!visible)">
    <ng-content select="[hideable-header]"></ng-content>
  </div>

</div>

<ng-content select="[hideable-body]" *ngIf="visible"></ng-content>
