/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FloodplainScenario } from '../models/floodplain-scenario';
import { Impact } from '../models/impact';
import { ImpactValueType } from '../models/impact-value-type';
import { Scenario } from '../models/scenario';

@Injectable({
  providedIn: 'root',
})
export class ScenariosService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiScenariosGet
   */
  static readonly ApiScenariosGetPath = '/api/Scenarios';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Scenario>>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Scenario>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosGet$Plain(params?: {
  }): Observable<Array<Scenario>> {

    return this.apiScenariosGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Scenario>>) => r.body as Array<Scenario>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Scenario>>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Scenario>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosGet$Json(params?: {
  }): Observable<Array<Scenario>> {

    return this.apiScenariosGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Scenario>>) => r.body as Array<Scenario>)
    );
  }

  /**
   * Path part for operation apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet
   */
  static readonly ApiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGetPath = '/api/Scenarios/{scenarioKey}/floodplains/{floodplainKey}/aggregated-impacts/max';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Plain$Response(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<StrictHttpResponse<Impact>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGetPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('floodplainKey', params.floodplainKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Impact>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Plain(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<Impact> {

    return this.apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Impact>) => r.body as Impact)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Json$Response(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<StrictHttpResponse<Impact>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGetPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('floodplainKey', params.floodplainKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Impact>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Json(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<Impact> {

    return this.apiScenariosScenarioKeyFloodplainsFloodplainKeyAggregatedImpactsMaxGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Impact>) => r.body as Impact)
    );
  }

  /**
   * Path part for operation apiScenariosScenarioKeyFloodplainsGet
   */
  static readonly ApiScenariosScenarioKeyFloodplainsGetPath = '/api/Scenarios/{scenarioKey}/floodplains';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosScenarioKeyFloodplainsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsGet$Plain$Response(params: {
    scenarioKey: string;
  }): Observable<StrictHttpResponse<Array<FloodplainScenario>>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosScenarioKeyFloodplainsGetPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FloodplainScenario>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosScenarioKeyFloodplainsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsGet$Plain(params: {
    scenarioKey: string;
  }): Observable<Array<FloodplainScenario>> {

    return this.apiScenariosScenarioKeyFloodplainsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FloodplainScenario>>) => r.body as Array<FloodplainScenario>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosScenarioKeyFloodplainsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsGet$Json$Response(params: {
    scenarioKey: string;
  }): Observable<StrictHttpResponse<Array<FloodplainScenario>>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosScenarioKeyFloodplainsGetPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FloodplainScenario>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosScenarioKeyFloodplainsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsGet$Json(params: {
    scenarioKey: string;
  }): Observable<Array<FloodplainScenario>> {

    return this.apiScenariosScenarioKeyFloodplainsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FloodplainScenario>>) => r.body as Array<FloodplainScenario>)
    );
  }

  /**
   * Path part for operation getFloodplainScenario
   */
  static readonly GetFloodplainScenarioPath = '/api/Scenarios/{scenarioKey}/floodplains/{floodplainKey}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFloodplainScenario$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFloodplainScenario$Plain$Response(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<StrictHttpResponse<FloodplainScenario>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetFloodplainScenarioPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('floodplainKey', params.floodplainKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FloodplainScenario>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFloodplainScenario$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFloodplainScenario$Plain(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<FloodplainScenario> {

    return this.getFloodplainScenario$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FloodplainScenario>) => r.body as FloodplainScenario)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFloodplainScenario$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFloodplainScenario$Json$Response(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<StrictHttpResponse<FloodplainScenario>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetFloodplainScenarioPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('floodplainKey', params.floodplainKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FloodplainScenario>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFloodplainScenario$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFloodplainScenario$Json(params: {
    scenarioKey: string;
    floodplainKey: string;
  }): Observable<FloodplainScenario> {

    return this.getFloodplainScenario$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FloodplainScenario>) => r.body as FloodplainScenario)
    );
  }

  /**
   * Path part for operation getScenarioImpacts
   */
  static readonly GetScenarioImpactsPath = '/api/Scenarios/{scenarioKey}/impacts/{impact}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScenarioImpacts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenarioImpacts$Plain$Response(params: {
    scenarioKey: string;
    impact: string;
    valueType?: ImpactValueType;
  }): Observable<StrictHttpResponse<{ [key: string]: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetScenarioImpactsPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('impact', params.impact, {});
      rb.query('valueType', params.valueType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: Array<any> }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScenarioImpacts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenarioImpacts$Plain(params: {
    scenarioKey: string;
    impact: string;
    valueType?: ImpactValueType;
  }): Observable<{ [key: string]: Array<any> }> {

    return this.getScenarioImpacts$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: Array<any> }>) => r.body as { [key: string]: Array<any> })
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScenarioImpacts$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenarioImpacts$Json$Response(params: {
    scenarioKey: string;
    impact: string;
    valueType?: ImpactValueType;
  }): Observable<StrictHttpResponse<{ [key: string]: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetScenarioImpactsPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('impact', params.impact, {});
      rb.query('valueType', params.valueType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: Array<any> }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScenarioImpacts$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScenarioImpacts$Json(params: {
    scenarioKey: string;
    impact: string;
    valueType?: ImpactValueType;
  }): Observable<{ [key: string]: Array<any> }> {

    return this.getScenarioImpacts$Json$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: Array<any> }>) => r.body as { [key: string]: Array<any> })
    );
  }

  /**
   * Path part for operation apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet
   */
  static readonly ApiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGetPath = '/api/Scenarios/{scenarioKey}/floodplains/{floodplainKey}/impacts/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Plain$Response(params: {
    scenarioKey: string;
    floodplainKey: string;
    valueType?: ImpactValueType;
  }): Observable<StrictHttpResponse<{ [key: string]: Array<number> }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGetPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('floodplainKey', params.floodplainKey, {});
      rb.query('valueType', params.valueType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: Array<number> }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Plain(params: {
    scenarioKey: string;
    floodplainKey: string;
    valueType?: ImpactValueType;
  }): Observable<{ [key: string]: Array<number> }> {

    return this.apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: Array<number> }>) => r.body as { [key: string]: Array<number> })
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Json$Response(params: {
    scenarioKey: string;
    floodplainKey: string;
    valueType?: ImpactValueType;
  }): Observable<StrictHttpResponse<{ [key: string]: Array<number> }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.ApiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGetPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.path('floodplainKey', params.floodplainKey, {});
      rb.query('valueType', params.valueType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: Array<number> }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Json(params: {
    scenarioKey: string;
    floodplainKey: string;
    valueType?: ImpactValueType;
  }): Observable<{ [key: string]: Array<number> }> {

    return this.apiScenariosScenarioKeyFloodplainsFloodplainKeyImpactsAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: Array<number> }>) => r.body as { [key: string]: Array<number> })
    );
  }

  /**
   * Path part for operation getSwissImpactsDetailed
   */
  static readonly GetSwissImpactsDetailedPath = '/api/Scenarios/{scenarioKey}/impacts/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSwissImpactsDetailed$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpactsDetailed$Plain$Response(params: {
    scenarioKey: string;
    valueType?: ImpactValueType;
  }): Observable<StrictHttpResponse<{ [key: string]: Array<number> }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetSwissImpactsDetailedPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.query('valueType', params.valueType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: Array<number> }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSwissImpactsDetailed$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpactsDetailed$Plain(params: {
    scenarioKey: string;
    valueType?: ImpactValueType;
  }): Observable<{ [key: string]: Array<number> }> {

    return this.getSwissImpactsDetailed$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: Array<number> }>) => r.body as { [key: string]: Array<number> })
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSwissImpactsDetailed$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpactsDetailed$Json$Response(params: {
    scenarioKey: string;
    valueType?: ImpactValueType;
  }): Observable<StrictHttpResponse<{ [key: string]: Array<number> }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetSwissImpactsDetailedPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.query('valueType', params.valueType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: Array<number> }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSwissImpactsDetailed$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpactsDetailed$Json(params: {
    scenarioKey: string;
    valueType?: ImpactValueType;
  }): Observable<{ [key: string]: Array<number> }> {

    return this.getSwissImpactsDetailed$Json$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: Array<number> }>) => r.body as { [key: string]: Array<number> })
    );
  }

  /**
   * Path part for operation getSwissImpacts
   */
  static readonly GetSwissImpactsPath = '/api/Scenarios/{scenarioKey}/aggregated-impacts/max';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSwissImpacts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpacts$Plain$Response(params: {
    scenarioKey: string;
  }): Observable<StrictHttpResponse<Impact>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetSwissImpactsPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Impact>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSwissImpacts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpacts$Plain(params: {
    scenarioKey: string;
  }): Observable<Impact> {

    return this.getSwissImpacts$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Impact>) => r.body as Impact)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSwissImpacts$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpacts$Json$Response(params: {
    scenarioKey: string;
  }): Observable<StrictHttpResponse<Impact>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetSwissImpactsPath, 'get');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Impact>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSwissImpacts$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSwissImpacts$Json(params: {
    scenarioKey: string;
  }): Observable<Impact> {

    return this.getSwissImpacts$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Impact>) => r.body as Impact)
    );
  }

  /**
   * Path part for operation getRoadImpacts
   */
  static readonly GetRoadImpactsPath = '/api/Scenarios/{scenarioKey}/road-impacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoadImpacts$Plain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRoadImpacts$Plain$Response(params: {
    scenarioKey: string;
    body?: Array<number>
  }): Observable<StrictHttpResponse<{ [key: string]: { [key: string]: number } }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetRoadImpactsPath, 'post');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: { [key: string]: number } }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRoadImpacts$Plain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRoadImpacts$Plain(params: {
    scenarioKey: string;
    body?: Array<number>
  }): Observable<{ [key: string]: { [key: string]: number } }> {

    return this.getRoadImpacts$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: { [key: string]: number } }>) => r.body as { [key: string]: { [key: string]: number } })
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoadImpacts$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRoadImpacts$Json$Response(params: {
    scenarioKey: string;
    body?: Array<number>
  }): Observable<StrictHttpResponse<{ [key: string]: { [key: string]: number } }>> {

    const rb = new RequestBuilder(this.rootUrl, ScenariosService.GetRoadImpactsPath, 'post');
    if (params) {
      rb.path('scenarioKey', params.scenarioKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: { [key: string]: number } }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRoadImpacts$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getRoadImpacts$Json(params: {
    scenarioKey: string;
    body?: Array<number>
  }): Observable<{ [key: string]: { [key: string]: number } }> {

    return this.getRoadImpacts$Json$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: { [key: string]: number } }>) => r.body as { [key: string]: { [key: string]: number } })
    );
  }

}
