import {Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {IUesgImageData} from '../../../models/uesg/IUesgImageData';
import {IUesgTranslatedTexts} from '../../../models/uesg/IUesgTranslatedTexts';
import * as _ from 'lodash';
import {IMobilabCommonConfig, MOBILAB_COMMON_CONFIG} from '../../../mobilab-common.config';

@Component({
  selector: 'mob-uesg-image',
  templateUrl: './uesg-image.component.html',
  styleUrls: ['./uesg-image.component.scss']
})
export class UesgImageComponent {
  @ViewChild('imageContent', {static: true}) imageContent: ElementRef;

  @Input() readonly translations: IUesgTranslatedTexts;
  @Input() readonly isScreenNarrow: boolean;

  @Input() set inputImage(image: IUesgImageData) {
    this._originalImage = _.clone(image);
    this.image = _.clone(image);
    // initially, the height is not yet set correctly => do not adjust the texts yet
  }

  private _originalImage: IUesgImageData;
  image: IUesgImageData;

  constructor(
    @Inject(MOBILAB_COMMON_CONFIG) private mobilabConfig: IMobilabCommonConfig,
  ) {}

  private get _displayHeightPx(): number {
    return this.imageContent ? this.imageContent.nativeElement.clientHeight : null;
  }

  updateImageTextByHeight() {
    // hide some texts if there is not enough space
    const imageHeight = this._displayHeightPx;
    const image = _.clone(this._originalImage);

    if (imageHeight < this.mobilabConfig.uesg.minHeightShowLocationPx) {
      image.location = null;
    }

    if (imageHeight < this.mobilabConfig.uesg.minHeightShowDatePx) {
      image.date = null;
    }

    image.location = this._shortenLocationDescription(image.location);

    this.image = image;
  }

  // =====================================================================
  // ======================= Location Description ========================
  // =====================================================================

  private _shortenLocationDescription(location: string): string {
    location = location || '';
    location = _.trim(location);
    const maxLength = this._deduceLocationMaxLength();

    // happy case: short enough
    if (_.size(location) <= maxLength) {
      return location;  // nothing to remove
    }

    const partSeparator = this._deduceLocationPartSeparator(location);
    const separatorLength = _.size(partSeparator);

    // unhappy case: too long and cannot separate nicely
    if (!_.includes(location, partSeparator)) {
      return (location || '').substr(0, maxLength); // cannot do any fancy cutting at part-boundaries
    }

    // middle case: too long, but nicely separable
    const parts = _.split(location, partSeparator);
    const trimmedParts = _.map(parts, part => (part || '').trim());
    let length = 0;

    const filteredParts = _.filter(trimmedParts, part => {
      const partLength = _.size(part) + separatorLength;
      length += partLength;
      return length <= (maxLength - separatorLength); // if this is the last element to be taken, no additional separator is needed
    });

    const shortenedLocation = _.join(filteredParts, ', ');

    // if the first element is already too long...
    if (_.isEmpty(shortenedLocation)) {
      return this._shortenLocationDescription(_.first(trimmedParts));
    }

    return shortenedLocation;
  }

  /**
   * The location (often) consists of several parts which usually seem to be separated by a comma.
   * Try to guess which character is used as a separator to cut the text in a nicer way.
   */
  private _deduceLocationPartSeparator(location: string): string {
    if (_.includes(location, ',')) {
      return ', ';
    }
    if (_.includes(location, ';')) {
      return '; ';
    }
    return ' ';
  }

  private _deduceLocationMaxLength(): number {
    const imageHeightPx = this._displayHeightPx;
    const thresholds = _.reverse(_.sortBy(this.mobilabConfig.uesg.maxLocationLengthChars, elem => elem.minHeightPx));

    const matchingThreshold = _.find(thresholds, elem => imageHeightPx > elem.minHeightPx);
    return _.isNil(matchingThreshold) ? 0 : matchingThreshold.maxLength;
  }
}
