import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {IAdditionalInformationData} from '../../../models/additional-information/IAdditionalInformationData';
import {map, switchMap, tap} from 'rxjs/operators';
import {RoutingHelperService} from '../../../services/routing/routing-helper.service';
import {commonRouteParams} from '../../../common-routing-constants';
import {StatisticsTrackerService} from '../../../services/statistics-tracker.service';
import {LocaleService} from '../../../services/locale.service';
import {LocaleRoutingService} from '../../../services/routing/locale-routing.service';
import {AdditionalInformationService} from '../../../services/additional-information.service';
import {RoutingErrorHandlingService} from '../../../services/routing/routing-error-handling.service';

/**
 * Helper-Component to determine the content to be loaded from the CMS and displayed using in-app routing.
 */
@Component({
  selector: 'mob-additional-information-routed',
  templateUrl: './additional-information-routed.component.html',
  styleUrls: ['./additional-information-routed.component.scss']
})
export class AdditionalInformationRoutedComponent implements OnInit, OnDestroy {
  additionalInformationData: IAdditionalInformationData;
  private readonly _subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private localeService: LocaleService,
    private routingHelper: RoutingHelperService,
    private routingErrorHandler: RoutingErrorHandlingService,
    private statisticsTracker: StatisticsTrackerService,
    private localeRoutingService: LocaleRoutingService,
    private additionalInformationService: AdditionalInformationService,
  ) {}

  ngOnInit(): void {
    this._initializeLocaleListener();
    this._initializeCmsPathListener();

    const relativeRoute = this.routingHelper.relativeRoute;
    this.statisticsTracker.trackInternalPage(relativeRoute);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * The language-switching component does not exist here: this component needs take care of the locale by itself
   */
  private _initializeLocaleListener() {
    this._subscriptions.push(this.route.paramMap.subscribe(params => {
      const localeRoutingData = this.localeRoutingService.getLocaleFromRouteParams(params);
      this.localeService.setLocale(localeRoutingData.locale);
    }));
  }

  private _initializeCmsPathListener() {
    this._subscriptions.push(this.route.paramMap
      .pipe(
        map(params => `${this.additionalInformationService.cmsMenuItemBasePath}/${params.get(commonRouteParams.cmsPath)}`),
        tap(cmsPath => this.additionalInformationService.validateCmsPath(cmsPath)),
        switchMap(cmsPath => this.additionalInformationService.createAdditionalInformationData$(cmsPath, false)),
      ).subscribe(
        additionalInformationData => {
          this.additionalInformationData = additionalInformationData;
        },
        error => this.onClose(error)
      )
    );
  }

  onClose(error?: Error) {
    if (error) {
      this.routingErrorHandler.handleRoutingError(error);
    } else {
      this.routingHelper.returnToPreviousRoute();
    }
  }
}
