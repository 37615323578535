export const cmsBasePathMenuItems = 'text_howadyn/menuitem';

export const appMenuItems = {
    introduction: `${cmsBasePathMenuItems}/introduction`,
    motivation: `${cmsBasePathMenuItems}/motivation`,
    method: `${cmsBasePathMenuItems}/method`,
    storyline: `${cmsBasePathMenuItems}/storyline`,
    findings: `${cmsBasePathMenuItems}/findings`,
    data: `${cmsBasePathMenuItems}/data`,
    software: `${cmsBasePathMenuItems}/software`,
    contact: `${cmsBasePathMenuItems}/contact`,
    legalNotice: `${cmsBasePathMenuItems}/legalnotice`,
    aboutus: `${cmsBasePathMenuItems}/aboutus`,
  };

export const onboardingPath = 'text_howadyn/onboarding';
