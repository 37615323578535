import { Inject, Injectable } from '@angular/core';
import { ValueType } from '../../../../mobilab-common/enums/ValueType';
import { ValueMappingFunction } from '../../../../mobilab-common/models/ValueMappingFunction';
import { ColorAsArray, getRgbaColorFromArray } from '../../../../mobilab-common/models/configuration/ColorAsArray';
import { ILegendRectangleElement } from '../../../../mobilab-common/models/legend/ILegendRectangleElement';
import {
  IconPosition,
  ILegendAbsoluteDataConfiguration
} from '../../../../mobilab-common/models/legend/configuration/ILegendDataConfiguration';
import { GeoUnit } from '../../../../mobilab-common/enums/GeoUnit';
import { IMobilabCommonConfig, MOBILAB_COMMON_CONFIG } from '../../../../mobilab-common/mobilab-common.config';
import { IColorThreshold } from '../../../../mobilab-common/models/configuration/IColorThreshold';
import { NumberUtilityService } from '../../../../mobilab-common/services/utilities/number-utility.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ILegendWithTextConfig, ILegendWithTextElement } from './legend-with-text-config';
import { Impact } from '../../shared/enums/Impact';
import { GetImpactTranslationKey, ImpactMapping, ImpactStrokeWidths } from '../map-mode-switzerland/impact';
import { ValueDisplayService } from '../numbers/value-display.service';
import { ComponentType } from '@angular/cdk/portal';
import { InfoPopupDetourRoadsComponent } from '../../scenario-page/info-popup/implementations/info-popup-detour-roads/info-popup-detour-roads.component';
import { InfoPopupUnmodelledFlowlinesComponent } from '../../scenario-page/info-popup/implementations/info-popup-unmodelled-flowlines/info-popup-unmodelled-flowlines.component';

interface IColorText {
  text: string;
  color: ColorAsArray;
  shape: Shape;
  infoType?: ComponentType<any>;
}

enum Shape {
  Rectangle,
  RectangleUnfilled,
  Dot,
  Line,
}

@Injectable({
  providedIn: 'root'
})
export class LegendConfigurationService {

  constructor(
    private numberUtility: NumberUtilityService,
    private translate: TranslateService,
    private valDispService: ValueDisplayService,
    @Inject(MOBILAB_COMMON_CONFIG) mobilabConfig: IMobilabCommonConfig) {
    this.mobilabConfig = mobilabConfig;
  }

  public get precipitationIntensityLegendConfiguration$(): Observable<ILegendAbsoluteDataConfiguration> {
    if (this.precipitationIntensity.value === null) {
      this.createSquareLegendConfiguration(
        this.mobilabConfig,
        this.precipitationIntensityColors,
        'precipitationIntensityLegendTitle',
        (x) => this.valueAsString(x)).subscribe(x => this.precipitationIntensity.next(x));
    }
    return this.precipitationIntensity.asObservable();
  }

  public get precipitationSumLegendConfiguration$(): Observable<ILegendAbsoluteDataConfiguration> {
    if (this.precipitationSum.value === null) {
      this.createSquareLegendConfiguration(
        this.mobilabConfig,
        this.precipitationSumColors,
        'precipitationSumLegendTitle',
        (x) => this.valueAsString(x)).subscribe(x => this.precipitationSum.next(x));
    }
    return this.precipitationSum.asObservable();
  }

  public get flowDepthLegendConfiguration$(): Observable<ILegendAbsoluteDataConfiguration> {
    if (this.flowDepth.value === null) {
      this.createSquareLegendConfiguration(
        this.mobilabConfig,
        this.flowDepthColors,
        'flowDepthLegendTitle',
        (x) => this.valueAsString(x)).subscribe(x => this.flowDepth.next(x));
    }
    return this.flowDepth.asObservable();
  }

  public get hazardClassesLegendConfiguration(): ILegendWithTextConfig {
    return this.createSquareLegendWithTextConfiguration(
      this.mobilabConfig,
      this.hazardClassesColors,
      'hazardClassesLegendTitle');
  }

  public get streetsImpactsLegendConfiguration(): ILegendWithTextConfig {
    return this.createSquareLegendWithTextConfiguration(
      this.mobilabConfig,
      this.streetsImpactColors,
      'impact.streets.name'
    );
  }

  public get buildingImpactsLegendConfiguration(): ILegendWithTextConfig {
    return this.createSquareLegendWithTextConfiguration(
      this.mobilabConfig,
      this.buildingsImpactColors);
  }

  public get flowlineImpactsLegendConfiguration(): ILegendWithTextConfig {
    return this.createSquareLegendWithTextConfiguration(
      this.mobilabConfig,
      this.buildingsImpactColors.filter(x => x.text === 'modelledFlowlines'));
  }

  private readonly mobilabConfig: IMobilabCommonConfig;

  private precipitationIntensity = new BehaviorSubject<ILegendAbsoluteDataConfiguration>(null);
  private precipitationSum = new BehaviorSubject<ILegendAbsoluteDataConfiguration>(null);
  private flowDepth = new BehaviorSubject<ILegendAbsoluteDataConfiguration>(null);

  private precipitationIntensityColors = [
    { threshold: 60, color: [175, 0, 221, 0.5] as ColorAsArray },
    { threshold: 40, color: [255, 25, 0, 0.5] as ColorAsArray },
    { threshold: 20, color: [255, 125, 1, 0.5] as ColorAsArray },
    { threshold: 10, color: [255, 199, 3, 0.5] as ColorAsArray },
    { threshold: 6, color: [254, 255, 1, 0.5] as ColorAsArray },
    { threshold: 4, color: [5, 255, 5, 0.5] as ColorAsArray },
    { threshold: 2, color: [5, 140, 45, 0.5] as ColorAsArray },
    { threshold: 1, color: [0, 1, 252, 0.5] as ColorAsArray },
  ];

  private precipitationSumColors = [
    { threshold: 400, color: [106, 44 , 90, 0.5] as ColorAsArray },
    { threshold: 300, color: [44, 2, 70, 0.5] as ColorAsArray },
    { threshold: 250, color: [10, 31, 150, 0.5] as ColorAsArray },
    { threshold: 210, color: [5, 80, 140, 0.5] as ColorAsArray },
    { threshold: 180, color: [5, 112, 176, 0.5] as ColorAsArray },
    { threshold: 150, color: [50, 150, 180, 0.5] as ColorAsArray },
    { threshold: 120, color: [50, 166, 150, 0.5] as ColorAsArray },
    { threshold: 100, color: [83, 189, 159, 0.5] as ColorAsArray },
    { threshold: 80, color: [153, 240, 178, 0.5] as ColorAsArray },
    { threshold: 60, color: [205, 255, 205, 0.5] as ColorAsArray },
    { threshold: 40, color: [237, 250, 194, 0.5] as ColorAsArray },
  ];

  private flowDepthColors = [
    { threshold: 200, color: [8, 48, 107, 0.7] as ColorAsArray },
    { threshold: 150, color: [8, 81, 156, 0.7] as ColorAsArray },
    { threshold: 100, color: [33, 113, 181, 0.7] as ColorAsArray },
    { threshold: 60, color: [66, 146, 198, 0.7] as ColorAsArray },
    { threshold: 30, color: [107, 174, 214, 0.7] as ColorAsArray },
    { threshold: 1, color: [158, 202, 225, 0.7] as ColorAsArray },
  ];

  private hazardClassesColors = [
    { text: 'hazardClasses.high', color: [153, 52, 4, 0.7] as ColorAsArray, shape: Shape.Rectangle },
    { text: 'hazardClasses.medium', color: [217, 95, 14, 0.7] as ColorAsArray, shape: Shape.Rectangle },
    { text: 'hazardClasses.low', color: [254, 153, 41, 0.7] as ColorAsArray, shape: Shape.Rectangle },
    { text: 'hazardClasses.none', color: [254, 217, 142, 0.7] as ColorAsArray, shape: Shape.Rectangle },
  ];

  private streetsImpactColors = [
    { text: 'impact.streets.noAccessRoadKms', color: [165, 15, 21, 1] as ColorAsArray, shape: Shape.Line },
    { text: 'impact.streets.exposedRoadKms', color: [251, 106, 74, 1] as ColorAsArray, shape: Shape.Line },
    { text: 'impact.streets.detourRoadKms', color: [128, 128, 0, 1] as ColorAsArray, shape: Shape.Line,
      infoType: InfoPopupDetourRoadsComponent },
  ];

  private buildingsImpactColors = [
    { text: 'impact.buildings.restHomes', color: [76, 0, 115, 1] as ColorAsArray, shape: Shape.Dot },
    { text: 'impact.buildings.schools', color: [169, 0, 230, 1] as ColorAsArray, shape: Shape.RectangleUnfilled },
    { text: 'impact.buildings.hospitals', color: [255, 115, 223, 1] as ColorAsArray, shape: Shape.RectangleUnfilled },
    { text: 'modelledFlowlines', color: [0, 132, 129, 1] as ColorAsArray, shape: Shape.RectangleUnfilled },
  ];

  private static getDimensionsForSquareBasedLegend(
    numberOfCategories: number,
    svgWidth: number,
    squareSize: number): { width: number, height: number } {
    const addendLastRect = 1;
    return {
      width: svgWidth,
      height: squareSize * (numberOfCategories + addendLastRect) + 1
    };
  }

  public valueAsString(value: number) {
    const displayValue = this.valDispService.rawValueToDisplayString(value);
    return ({ value: value, displayValue: displayValue});
  }

  public impactLegendConfiguration(value: Impact): Observable<ILegendWithTextConfig> {
    return this.translate.onLangChange.pipe(startWith(null as LangChangeEvent), map(() => this.createImpactLegend(
      this.mobilabConfig,
      ImpactMapping[value],
      GetImpactTranslationKey(value) + 'Legend')));
  }

  public createSquareLegendWithTextConfiguration(
    mobilabConfig: IMobilabCommonConfig,
    colorTexts: IColorText[],
    legendTitle?: string,
    infoType?: ComponentType<any>,
  ): ILegendWithTextConfig {
    const squareSize = mobilabConfig.legendConfiguration.relative.squareSize;
    const entryHeight = 1.2 * squareSize;
    let relY = 10;
    let height = squareSize;
    let cornerRadius = 0;
    let stroke = '';
    let strokeWidth = 0;
    let fill = '';

    const entries: ILegendWithTextElement[] = colorTexts.map(item => {
      switch (item.shape) {
        case Shape.Rectangle: {
          height = squareSize;
          cornerRadius = 0;
          stroke = '';
          strokeWidth = 0;
          fill = getRgbaColorFromArray(item.color);
          break;
        }
        case Shape.RectangleUnfilled: {
          height = squareSize;
          cornerRadius = 0;
          stroke = getRgbaColorFromArray(item.color);
          strokeWidth = 4;
          fill = getRgbaColorFromArray([255 , 255 , 255 , 1]);
          break;
        }
        case Shape.Line: {
          height = squareSize / 4;
          cornerRadius = squareSize / 6;
          stroke = '';
          strokeWidth = 0;
          fill = getRgbaColorFromArray(item.color);
          break;
        }
        case Shape.Dot: {
          height = squareSize;
          cornerRadius = squareSize;
          stroke = '';
          strokeWidth = 0;
          fill = getRgbaColorFromArray(item.color);
          break;
        }
      }

      const icon = {
        width: squareSize,
        height,
        stroke,
        strokeWidth,
        fill,
        cornerRadius,
      };

      relY = relY + entryHeight;
      return { icon, label: item.text, infoType: item.infoType };
    });

    return {
        title: legendTitle,
        entries: entries,
        infoType,
      };
  }

  private createImpactLegend(
    mobilabConfig: IMobilabCommonConfig,
    values: { type: 'integer' | 'decimal', limits: number[]},
    legendTitle: string
  ): ILegendWithTextConfig {
    const heights = ImpactStrokeWidths.slice().reverse();
    const squareSize = mobilabConfig.legendConfiguration.relative.squareSize;
    const entryHeight = 1.2 * squareSize;
    let relY = 10;
    const cornerRadius = squareSize / 4;
    const stroke = '';
    const strokeWidth = 0;
    const fill = 'rgba(44, 76, 143, 1)';
    const thresholds = values.limits.slice().reverse();
    const lastThresholdIsZero = values.limits[0] === 0;

    const entries: ILegendWithTextElement[] = thresholds.map((threshold, index) => {
      const height = heights[index];

      const icon = {
        width: 3 * squareSize,
        height,
        stroke,
        strokeWidth,
        fill,
        cornerRadius,
      };

      let label: string;
      switch (index) {
        case 0: {
          label = `> ${this.valDispService.rawValueToDisplayString(thresholds[1])}`;
          break;
        }
        case thresholds.length - 1: {
          label = lastThresholdIsZero ? '0' : `< ${this.valDispService.rawValueToDisplayString(threshold)}`;
          break;
        }
        case values.type === 'integer' && thresholds.length - 2: {
          const lowerBound = lastThresholdIsZero ? 1 : thresholds[index + 1];
          label = lowerBound === threshold ?
            `${this.valDispService.integerToDisplayString(threshold)}` :
            `${this.valDispService.integerToDisplayString(lowerBound)} – ${this.valDispService.integerToDisplayString(threshold)}`;
          break;
        }
        case values.type === 'decimal' && thresholds.length - 2: {
          label = lastThresholdIsZero ?
            `> 0 – ${this.valDispService.rawValueToDisplayString(threshold)}` :
            `${this.valDispService.rawValueToDisplayString(thresholds[index + 1])} – ${this.valDispService.rawValueToDisplayString(threshold)}`;
          break;
        }
        case values.type === 'integer' && index: {
          const lowerBound = thresholds[index + 1] + 1;
          label = lowerBound === threshold ?
            `${this.valDispService.integerToDisplayString(threshold)}` :
            `${this.valDispService.integerToDisplayString(lowerBound)} – ${this.valDispService.integerToDisplayString(threshold)}`;
          break;
        }
        case values.type === 'decimal' && index: {
          label = `${this.valDispService.rawValueToDisplayString(thresholds[index + 1])} – ${this.valDispService.rawValueToDisplayString(threshold)}`;
          break;
        }
        default: {
          label = ` – `;
          break;
        }
      }

      relY = relY + entryHeight;
      return { icon, label };
    });

    entries.push({
      icon: {
        width: 3 * squareSize,
        height: 10,
        stroke: 'rgba(44, 76, 143, 1)',
        strokeWidth: ImpactStrokeWidths[0],
        fill,
        cornerRadius,
        paintAsLine: true,
      },
      label: 'notModelledFlowlines',
      infoType: InfoPopupUnmodelledFlowlinesComponent
    });

    return { title: legendTitle, entries };
  }

  public createSquareLegendConfiguration(
    mobilabConfig: IMobilabCommonConfig,
    colorThresholds: IColorThreshold[],
    legendTitle: string,
    valueMappingFunction: ValueMappingFunction,
    showLabelsAbove = false
  ): Observable<ILegendAbsoluteDataConfiguration> {
    const squareSize = mobilabConfig.legendConfiguration.relative.squareSize;
    const horizSpaceText = mobilabConfig.legendConfiguration.relative.horizSpaceText;
    const offsetX = 12;
    const relX = 1 + offsetX - 0.5 * squareSize;
    let relY = 10;
    const labelOffset = this.calculateLabelOffset(
      Math.max(...colorThresholds.map(x => x.threshold)),
      mobilabConfig.legendConfiguration.em2ch);
    const xLabel = squareSize + horizSpaceText + labelOffset;

    const rectangles = colorThresholds.map((item, index) => {
      const rectangle = this._createRectangleItem(
        relX,
        relY,
        item.threshold,
        item.color,
        xLabel,
        showLabelsAbove,
        squareSize,
        mobilabConfig.legendConfiguration.em2ch
      );

      relY = relY + squareSize;
      return rectangle;
    });

    const infoIconX =  squareSize + 0.5 * horizSpaceText;

    return this.translate.stream(legendTitle)
      .pipe(map(titleTranslated => ({
          valueType: ValueType.Absolute,
          valueMappingFunction,

          title: titleTranslated,

          svgDimension: LegendConfigurationService.getDimensionsForSquareBasedLegend(
            rectangles.length,
            135,
            squareSize),
          infoIconX: infoIconX,
          infoIconPosition: IconPosition.below,
          showInfoIcon: false,

          noValueDescription: 'legend.noValueDescription',
          noValueTooltip: 'legend.tooltip',

          rectangles: rectangles,
          noValueRect: [],

          geographicalUnit: GeoUnit.hexagon,
          unit: null,
          circles: [],
        })));
  }

  private _createRectangleItem (
    relX: number,
    relY: number,
    threshold: number,
    color: ColorAsArray,
    xLabel: number,
    showLabelsAbove: boolean,
    squareSize: number,
    em2ch: number
  ): ILegendRectangleElement {
    const rect = {
      x: relX,
      y: relY,
      width: squareSize,
      height: squareSize,
      stroke: '',
      fill: getRgbaColorFromArray(color)
    };

    const label = {
      x: xLabel,
      y: relY + (showLabelsAbove ? 0 : squareSize),
      label: threshold,
      length: 2 * em2ch + 'em',
      anchor: 'end'
    };

    return { rect, label };
  }

  // the label-position needs to take into account how much space the label needs
  private calculateLabelOffset(maxValue: number, em2ch: number): number {
    return this.numberUtility.getNumberDisplayLengthInPixel(maxValue, 0, 16, em2ch);
  }
}
