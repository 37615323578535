import {Component, EventEmitter, OnInit, Output} from '@angular/core';

/*
  A generic content for e.g. a popup including a close-button in the top-right corner.
   - The title and content can be passed via ng-content using the following css-class
     - .mob-map-popup-title
     - .mob-map-popup-content
 */
@Component({
  selector: 'mob-popup-content',
  templateUrl: './popup-content.component.html',
  styleUrls: ['./popup-content.component.scss']
})
export class PopupContentComponent implements OnInit {
  @Output() readonly closeButtonClicked = new EventEmitter<void>();
  constructor() {}
  ngOnInit() {}
}
