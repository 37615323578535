import { Component, DoCheck, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ScenarioService } from '../domain/scenario/scenario.service';
import { Scenario } from '../openapi/models/scenario';
import { TranslateService } from '@ngx-translate/core';
import { MapModeSwitzerlandService } from '../domain/map-mode-switzerland/map-mode-switzerland.service';
import { MapModeFlowlinesService } from '../domain/map-mode-flowlines/map-mode-flowlines.service';
import { MapService } from '../scenario-page/main-map/map.service';
import { map } from 'rxjs/operators';
import { LocaleService } from '../../../mobilab-common/services/locale.service';
import { OnboardingService } from '../domain/onboarding/onboarding.service';
import { LocalStorageAccessService } from '../../../mobilab-common/services/local-storage-access.service';
import { SessionStorageAccessService } from '../../../mobilab-common/services/session-storage-access.service';
import { IScenarioPopup } from '../domain/scenario-popup/IScenarioPopup';
import { ScenarioPopupService } from '../domain/scenario-popup/scenario-popup.service';
import { IBoxClickEvent } from '../../../mobilab-common/models/info-icon/IBoxClickEvent';
import { IInfoPopupData } from '../../../mobilab-common/models/IInfoPopupData';
import { InfoPopupScenariosComponent } from '../scenario-page/info-popup/implementations/info-popup-scenarios/info-popup-scenarios.component';
import { InfoPopupService } from '../../../mobilab-common/services/info-popup.service';
import { StatisticsTrackerService } from '../../../mobilab-common/services/statistics-tracker.service';
import { RoutingHelperService } from '../../../mobilab-common/services/routing/routing-helper.service';
import { MenuType } from '../../../mobilab-common/enums/MenuType';

@Component({
  selector: 'how-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, DoCheck {

  scenarios$: Observable<Scenario[]>;
  methodLink$: Observable<string>;
  activeMenuType: MenuType;

  constructor(
    private scenarioService: ScenarioService,
    private translate: TranslateService,
    private mapModeSwitzerland: MapModeSwitzerlandService,
    private mapModeFlowlines: MapModeFlowlinesService,
    private mapService: MapService,
    private localeService: LocaleService,
    private onboardingService: OnboardingService,
    private localStorage: LocalStorageAccessService,
    private sessionStorage: SessionStorageAccessService,
    private scenarioPopupService: ScenarioPopupService,
    private readonly infoPopupService: InfoPopupService,
    private readonly statisticsTracker: StatisticsTrackerService,
    private readonly routingHelper: RoutingHelperService,
  ) {
    this.scenarios$ = scenarioService.scenarios$;

    this.methodLink$ = this.localeService.currentLanguageStream$.pipe(
      map(lang => `${environment.gravUrl}/${lang}/text_howadyn/menuitem/findings/Entscheidungshilfe_Szenarienauswahl_${lang}.pdf`));
  }

  ngOnInit() {
    this.openOnBoardingIfNotHidden();
    this.statisticsTracker.trackInternalPage(this.routingHelper.relativeRoute);
  }

  ngDoCheck() {
    const routeLang = this.routingHelper.relativeRoute.split('/')[1];
    this.setLangFromURL(routeLang);
  }

  activateScenario(scenario: Scenario) {
    this.scenarioService.currentScenario = scenario;
    this.mapModeSwitzerland.reset();
    this.mapModeFlowlines.reset();
    this.mapService.setDefaultExtentInstant();
    this.scenarioPopupService.closeScenarioPopup();
  }

  currentLang(): string {
    return this.translate.currentLang;
  }

  changeLanguage(language: string) {
    return this.translate.use(language);
  }

  handlePopup(scenarioPopup: IScenarioPopup) {
    if (scenarioPopup) {
      this.scenarioPopupService.openScenarioPopup(scenarioPopup, this.localeService.currentLocale);
    } else {
      this.scenarioPopupService.closeScenarioPopup();
    }
  }

  openOnBoardingIfNotHidden() {
    if (this.localStorage.getBoolean('onboardingHidden') || this.sessionStorage.getBoolean('onboardingHidden')) {
      return;
    }

    this.onboardingService.openOnBoarding();
  }

  onClickScenariosInfoIcon(event: IBoxClickEvent) {
    const popupConfig: IInfoPopupData = {
      clickEvent: event
    };

    this.infoPopupService.openOverlay(popupConfig, InfoPopupScenariosComponent);
  }

  onMenuOpened(menuType: MenuType): void {
    this.activeMenuType = menuType;
  }

  onHelpButtonClicked(): void {
    this.onboardingService.openOnBoarding(2);
  }

  setLangFromURL(routeLang: string): void {
    const selectedLocale = this.langToLocale(routeLang);
    this.changeLanguage(selectedLocale);
  }

  langToLocale(lang: string): string {
    if (lang === 'de') {
      return 'de-CH';
    } else if (lang === 'fr') {
      return 'fr-CH';
    } else if (lang === 'en') {
      return 'en-US';
    } else if (['de', 'en', 'fr'].includes(this.localeService.browserLanguage)) {
      return this.langToLocale(this.localeService.browserLanguage);
    } else {
      return 'de-CH';
    }
  }
}
