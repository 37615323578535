import { Impact } from '../../shared/enums/Impact';

export function GetImpactTranslationKey(impact: Impact) {
  const group = Object.keys(ImpactGroupings).find(k => ImpactGroupings[k].includes(impact));
  return `impact.${group}.${impact}`;
}

export const ImpactGroupings = {
  'buildings': [Impact.Buildings, Impact.DamageToBuildings, Impact.Hospitals, Impact.Schools, Impact.RestHomes],
  'personsAndWorkplaces': [Impact.Persons, Impact.RestHomeBeds, Impact.Workplaces],
  'streets': [Impact.StreetsNotUsed, Impact.StreetsLimitedlyUsed, Impact.StreetsWithEvasiveTraffic],
};

export const ImpactMapping: { [key: string]: { type: 'integer' | 'decimal', limits: number[]}} = {
  [Impact.Buildings]: { type: 'integer', limits:  [3, 10, 50, 200, Number.MAX_SAFE_INTEGER] },
  [Impact.DamageToBuildings]: { type: 'decimal', limits: [.1, 2, 10, 50, Number.MAX_SAFE_INTEGER] },
  [Impact.Hospitals]: { type: 'integer', limits: [0, 1, 2, 3, Number.MAX_SAFE_INTEGER] },
  [Impact.Schools]: { type: 'integer', limits: [0, 2, 5, 9, Number.MAX_SAFE_INTEGER] },
  [Impact.RestHomes]: { type: 'integer', limits: [0, 1, 2, 3, Number.MAX_SAFE_INTEGER] },
  [Impact.Persons]: { type: 'integer', limits: [0, 50, 200, 1000, Number.MAX_SAFE_INTEGER] },
  [Impact.Workplaces]: { type: 'integer', limits: [0, 50, 200, 1000, Number.MAX_SAFE_INTEGER] },
  [Impact.RestHomeBeds]: { type: 'integer', limits: [0, 50, 100, 200, Number.MAX_SAFE_INTEGER] },
  [Impact.StreetsNotUsed]: { type: 'decimal', limits: [0, 1, 3, 10, Number.MAX_SAFE_INTEGER] },
  [Impact.StreetsLimitedlyUsed]: { type: 'decimal', limits: [0, 1, 2, 5, Number.MAX_SAFE_INTEGER] },
  [Impact.StreetsWithEvasiveTraffic]: { type: 'decimal', limits: [0, 5, 20, 50, Number.MAX_SAFE_INTEGER] },
};

export const ImpactStrokeWidths = [2, 4, 6, 8, 11];
