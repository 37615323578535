import {Injectable} from '@angular/core';
import Feature, {FeatureLike} from 'ol/Feature';
import GeomPoint from 'ol/geom/Point';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Polygon from 'ol/geom/Polygon';
import {ValueType} from '../enums/ValueType';
import {GeoUnit} from '../enums/GeoUnit';
import {ColorAsArray} from '../models/configuration/ColorAsArray';
import {IMapStylingConfiguration} from '../models/configuration/IMapStylingConfiguration';
import * as _ from 'lodash';
import {IStaticStylesConfiguration} from '../models/configuration/IStaticStylesConfiguration';
import Style from 'ol/style/Style';
import StyleStroke from 'ol/style/Stroke';
import StyleCircle from 'ol/style/Circle';
import StyleFill from 'ol/style/Fill';
import {NumberUtilityService} from './utilities/number-utility.service';
import {FeatureIdentifierFunction} from '../models/FeatureIdentifierFunction';
import {IColorThreshold} from '../models/configuration/IColorThreshold';

/**
 * Service to determine the styling of features drawn on the map
 */
@Injectable({
  providedIn: 'root'
})
export class MapDrawingService {
  constructor(
    private numberUtility: NumberUtilityService
    ) {}

  // =====================================================================
  // ======================= Interaction Styling =========================
  // =====================================================================

  getFeatureHoverStyle(stylesConfig: IStaticStylesConfiguration): Style {
    return new Style({
      stroke: new StyleStroke({
        color: stylesConfig.polygonOutlineColorMouseOver,
        width: stylesConfig.polygonOutlineWidthHighlighted
      })
    });
  }

  getFeatureSelectStyle(stylesConfig: IStaticStylesConfiguration): Style {
    return new Style({
      stroke: new StyleStroke({
        color: stylesConfig.polygonOutlineColorSelected,
        width: stylesConfig.polygonOutlineWidthHighlighted
      })
    });
  }

  // =====================================================================
  // ========================== Polygon Styling ==========================
  // =====================================================================

  getPolygonStyle(stylingConfig: IMapStylingConfiguration, absoluteValue: number, relativeValue: number, showFeature: boolean): Style {
    const color = this.getPolygonColor(stylingConfig, absoluteValue, relativeValue, showFeature);

    return new Style({
      fill: new StyleFill({ color })
    });
  }

  // =====================================================================
  // =========================== Point Styling ===========================
  // =====================================================================

  getCircleStyle(stylingConfig: IMapStylingConfiguration, feature: Feature, absoluteValue: number, relativeValue: number = null): Style {
    const color = stylingConfig.valueType === ValueType.Mixed ?
      this.getRelativeColor(stylingConfig.styles, relativeValue) :
      this.getAbsoluteCircleColor(stylingConfig.styles);

    let strokeWidth = 0;
    const pointRadius = this.getCircleRadius(stylingConfig, absoluteValue);

    stylingConfig.styles.circleStrokeWidths.forEach( item => {
      if (item.thresholdRadius < pointRadius) {
        strokeWidth = item.width;
      }
    });

    const stroke: StyleStroke = new StyleStroke({
      color: stylingConfig.styles.circleBorderColor,
      width: strokeWidth
    });

    const circle: StyleCircle = new StyleCircle({
      radius: pointRadius,
      fill: new StyleFill({
        color: color
      }),
      stroke: stroke
    });

    return new Style({
      image: circle,
      geometry: this.getInteriorPointOfLargestPolygon(feature)
    });
  }

  getCircleRadius(stylingConfig: IMapStylingConfiguration, value: number): number {
    const minRadius = stylingConfig.styles.minCircleRadius[stylingConfig.geographicalUnit];
    const maxRadius = stylingConfig.styles.maxCircleRadius[stylingConfig.geographicalUnit];

    if (_.isNil(stylingConfig.maxAbsoluteValue)) {
      return minRadius;
    }

    const radius = Math.sqrt(value) * maxRadius / Math.sqrt(stylingConfig.maxAbsoluteValue);
    return Math.max(radius, minRadius);
  }

  getInteriorPointOfLargestPolygon(feature: Feature): GeomPoint {
    const geometry = feature.getGeometry();
    let labelPoint: GeomPoint;
    if (geometry instanceof MultiPolygon) {
      // Only render label for the widest polygon of a multipolygon
      const polygons = (geometry as MultiPolygon).getPolygons();
      let largestArea = 0.0;
      for (let i = 0, ii = polygons.length; i < ii; ++i) {
        const polygon = polygons[i];
        const area = polygon.getArea();
        if (area > largestArea) {
          largestArea = area;
          labelPoint = polygon.getInteriorPoint();
        }
      }
    } else if (geometry instanceof Polygon) {
      labelPoint = (geometry as Polygon).getInteriorPoint();
    }
    return labelPoint;
  }

  // =====================================================================
  // ============================== Colors ===============================
  // =====================================================================

  getPolygonColor(stylingConfig: IMapStylingConfiguration, absValue: number, relValue: number, showFeature: boolean): ColorAsArray {
    if (!showFeature) {
      return stylingConfig.styles.hasNoValueColor;
    }

    if (stylingConfig.valueType === ValueType.Relative) {
      return this.getRelativeColor(stylingConfig.styles, relValue);
    }

    if (stylingConfig.geographicalUnit === GeoUnit.hexagon) {
      return this.getAbsoluteHexagonColor(stylingConfig.styles, stylingConfig.maxAbsoluteValue, absValue);
    }

    return stylingConfig.styles.hasValueColor;   // polygons are not colored for absolute values (except hexagons)
  }

  getRelativeColor(styles: IStaticStylesConfiguration, value: number): ColorAsArray {
    let color = styles.hasNoValueColor;

    const relColors = styles.relativeColors;
    _.forOwn(relColors, (item) => {
      const colorThresold = item as IColorThreshold;
      if (value <= colorThresold.threshold) {
        color = colorThresold.color;
      }
    });

    return color;
  }

  getAbsoluteHexagonColor(styles: IStaticStylesConfiguration, maxAbsoluteValue: number, value: number): ColorAsArray {
    const hexColorThresholds = styles.absoluteHexColors;
    let color = styles.hasNoValueColor;

    if (_.isNil(value) || _.isNil(maxAbsoluteValue)) {
      // TODO this behavior is different than for other GeoUnits because the hexagons of schadpot do not have the "InShowMe" flags
      // TODO think about making this the same everywhere (would need a change in the topojson-data, though...)
      return color;
    }

    _.forEach(hexColorThresholds, colorThreshold => {
      const absoluteThreshold = _.ceil(maxAbsoluteValue * colorThreshold.threshold / 100);
      if (value <= absoluteThreshold) {
        color = colorThreshold.color;
      }
    });

    return color;
  }

  getAbsoluteCircleColor(styles: IStaticStylesConfiguration): ColorAsArray {
    return styles.absoluteCircleColor;
  }

  // =====================================================================
  // ========================== Miscellaneous ============================
  // =====================================================================

  /**
   * Get the order in which two features are to rendered: {@param feature1} and {@param feature2}
   * @return 1 for {@param feature1} first, -1 for {@param feature2} first, 0 if they are the same feature
   */
  getRenderOrder(
    feature1: FeatureLike, feature2: FeatureLike,
    mouseOverFeature: Feature, selectedFeature: Feature, getFeatureId: FeatureIdentifierFunction
  ): number {

    const id1 = getFeatureId(feature1);
    const id2 = getFeatureId(feature2);

    const hoverId = getFeatureId(mouseOverFeature);
    const selectedId = getFeatureId(selectedFeature);

    const priority1 = this._getRenderPriority(id1, hoverId, selectedId);
    const priority2 = this._getRenderPriority(id2, hoverId, selectedId);

    const priorityComparison = this.numberUtility.compareNumbers(priority1, priority2);
    return priorityComparison || this.numberUtility.compareNumbers(id1, id2); // never return 0 unless the features are the same
  }

  private _getRenderPriority(featureId: number, hoveredFeatureId: number, selectedFeatureId: number): number {
    if (featureId === selectedFeatureId) {
      return 2;
    } else if (featureId === hoveredFeatureId) {
      return 1;
    } else {
      return 0;
    }
  }
}
