<ng-container *ngIf="activeTimeMode$ | async as activeTimeMode">
  <mat-button-toggle-group [value]="activeTimeMode" (change)="timeModeChange($event.value)" [hideSingleSelectionIndicator]="true">
    <mat-button-toggle [value]="timeMode.Dynamic">
      <label class="scale-mode-label">{{timeMode.Dynamic | translate}}</label>
    </mat-button-toggle>
    <mat-button-toggle [value]="timeMode.Maximum">
      <label class="scale-mode-label">{{timeMode.Maximum | translate}}</label>
    </mat-button-toggle>
  </mat-button-toggle-group>


  <div *ngIf="activeTimeMode === timeMode.Dynamic" class="time-scale">
    <ng-container *ngIf="{
      isPlaying: isPlaying$ | async,
      currentTime: currentTime$ | async,
      maximumTime: maximumTime$ | async
    } as args">

      <mat-icon *ngIf="args.isPlaying" [inline]="true" (click)="pause()">pause</mat-icon>
      <mat-icon *ngIf="!args.isPlaying" [inline]="true" (click)="play()">play_arrow</mat-icon>
      <div class="custom-slider">
        <ngx-slider [value]="args.currentTime" [options]="options"
          (userChange)="userChange($event.value)" (userChangeStart)="pause()">
        </ngx-slider>
      </div>
      <div class="time-display">
        {{args.currentTime | videoTimeSpan}}/{{args.maximumTime | videoTimeSpan}} h
      </div>
    </ng-container>
  </div>
  <div *ngIf="activeTimeMode === timeMode.Maximum" class="maximum-text">
    <ng-container *ngIf="((activeMapMode$ | async) === mapMode.Switzerland) && {
      precipitationVisible: precipitationVisible$ | async,
      precipitationMode: precipitationMode$ | async
    } as args">
      {{'maxText.switzerland.' + args.precipitationVisible + '.' + args.precipitationMode | translate}}
    </ng-container>
    <ng-container *ngIf="((activeMapMode$ | async) === mapMode.Floodplain) && {
      streetsVisible: streetsVisible$ | async,
      floodingMode: floodingMode$ | async
    } as args">
      {{'maxText.flowlines.' + args.floodingMode + '.' + args.streetsVisible | translate}}
    </ng-container>
  </div>
</ng-container>
