import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Impact } from '../../shared/enums/Impact';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { GetImpactTranslationKey } from './impact';

@Pipe({
  name: 'impactTranslate',
  pure: false
})
export class ImpactTranslatePipe extends TranslatePipe implements PipeTransform {
  constructor(translateService: TranslateService, changeDetectionRef: ChangeDetectorRef
  ) {
    super(translateService, changeDetectionRef);
  }

  transform(value: Impact, suffix = ''): any {
    return super.transform(GetImpactTranslationKey(value) + suffix);
  }
}
