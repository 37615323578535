<div [class.narrow-screen]="isScreenNarrow">
  <div class="masonry">
    <div *ngFor="let img of images; let i = index">
      <mob-uesg-image
        [isScreenNarrow]="isScreenNarrow"
        [translations]="translations"
        [inputImage]="img"
      ></mob-uesg-image>
      <div *ngIf = "i == 0; then showInfoBlock"></div>
    </div>
  </div>

  <ng-template #showInfoBlock>
    <div class="infoBlockItem">
      <div class="infoBlockContent">
        <p class="info BlockText small">{{ translations.preInfo$ | async }}</p>
        <p class="infoBlockText">{{ translations.info$ | async }}</p>
        <div class="text link mouse text-link">
          <a class="text link mouse text-link" [href]="uesgUrl | async" target="_blank" rel="noopener noreferrer">
            <p class="text link mouse text-link">{{ translations.exploreMap$ | async }}
              <i><mat-icon class="mat-icon" aria-hidden="true">arrow_right_alt</mat-icon></i>
            </p></a>
        </div>
      </div>
    </div>
  </ng-template>
</div>
