import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Map, View } from 'ol';
import { MapService } from '../../../scenario-page/main-map/map.service';
import BaseLayer from 'ol/layer/Base';
import * as Projection from 'ol/proj';
import { CoordinateProjection } from '../../../../../mobilab-common/enums/CoordinateProjection';
import { ScenariosService } from '../../../openapi/services/scenarios.service';
import { ScenarioService } from '../../../domain/scenario/scenario.service';
import { Extent, getCenter } from 'ol/extent';
import { flatMap, take, tap } from 'rxjs/operators';
import ImageLayer from 'ol/layer/Image';
import { ImageWMS, Vector } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Stroke, Style } from 'ol/style';
import { GeoJSON } from 'ol/format';
import { MapModeFlowlinesService } from '../../../domain/map-mode-flowlines/map-mode-flowlines.service';
import { LegendConfigurationService } from '../../../domain/legend/legend-configuration.service';
import { ILegendAbsoluteDataConfiguration } from '../../../../../mobilab-common/models/legend/configuration/ILegendDataConfiguration';
import { Observable } from 'rxjs';
import { Scenario } from '../../../openapi/models/scenario';
import { FloodingMaximumLayer } from '../../../domain/map-mode-flowlines/flooding-maximum-layer';
import { Flooding } from '../../../shared/enums/Flooding';
import { FloodplainScenario } from '../../../openapi/models/floodplain-scenario';

@Component({
  selector: 'how-detail-map-flowlines',
  templateUrl: './detail-map-flowlines.component.html',
  styleUrls: ['./detail-map-flowlines.component.scss']
})
export class DetailMapFlowlinesComponent implements OnInit {
  private floodingLayer: FloodingMaximumLayer;
  private floodplainScenario: FloodplainScenario;
  public readonly map = new Map({
    view: new View({
      projection: Projection.get(CoordinateProjection.EPSG3857),
      constrainOnlyCenter: true,
    }),
    controls: [],
    interactions: [],
  });
  legend$: Observable<ILegendAbsoluteDataConfiguration>;

  @Input() floodplainKey;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.map.getView().setResolution(
      Math.max(5, this.map.getView().getResolutionForExtent(this.floodplainScenario.extent as Extent)));
  }

  constructor(
    private scenariosService: ScenariosService,
    private scenarioScervice: ScenarioService,
    private legendConfigurationService: LegendConfigurationService,
  ) { }

  ngOnInit() {
    let currentScenario: Scenario;
    this.scenarioScervice.currentScenario$.pipe(
      take(1),
      tap(x => currentScenario = x),
      flatMap(scenario => this.scenariosService.getFloodplainScenario$Json({
        scenarioKey: scenario.key,
        floodplainKey: this.floodplainKey,
      })))
    .subscribe(floodplainScenario => {
      this.floodplainScenario = floodplainScenario;
      const view = this.map.getView();
      view.setCenter(getCenter(floodplainScenario.extent as Extent));
      view.setResolution(Math.max(5, this.map.getView().getResolutionForExtent(this.floodplainScenario.extent as Extent)));
      this.layers.forEach(x =>  {
        this.map.addLayer(x);
      });
      this.floodingLayer = MapModeFlowlinesService.createMaximumFloodingLayer(
        Flooding.WaterDepth, floodplainScenario, currentScenario).layer as FloodingMaximumLayer;
      this.floodingLayer.setVisible(true);
      this.map.addLayer(this.floodingLayer);
    });
    this.legend$ = this.legendConfigurationService.flowDepthLegendConfiguration$;
  }

  private get layers(): BaseLayer[] {
    return [
      new ImageLayer({
        source: new ImageWMS({
          url: 'https://wms.geo.admin.ch/?service=WMS&version=1.3.0&request=GetMap',
          params: { layers: 'ch.swisstopo.landeskarte-grau-10', },
        }),
        zIndex: 1,
        opacity: 0.5,
      }),
      new ImageLayer({
        source: new ImageWMS({
          url: 'https://wms.geo.admin.ch/?service=WMS&version=1.3.0&request=GetMap',
          params: { layers: 'ch.swisstopo.swissalti3d-reliefschattierung', },
        }),
        zIndex: 0,
        opacity: 1,
      }),
      new VectorLayer({
        source: new Vector({
          url: MapService.featureUrl('FloodplainBoundariesModelled'),
          format: new GeoJSON(),
        }),
        zIndex: 10,
        style: new Style({
          stroke: new Stroke({
            color: 'rgb(0,132,129)',
            width: 1,
          })}),
      }),
      MapService.rivers,
      MapService.lakes,
      MapService.maskFloodplains,
      MapModeFlowlinesService.HospitalsRestHomesSchools,
    ];
  }
}
