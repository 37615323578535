<div [class.narrow-screen]="isScreenNarrow">
  <div class=item>
    <div class="preview">
      <img [src]="image.thumbnail" [alt]="image.thumbnail" class="thumbnail">
    </div>
    <div class="info-overlay">
      <div class="content" [id]="image.id" #imageContent (mouseenter)="updateImageTextByHeight()" (click)="updateImageTextByHeight()">
        <div class="text link mouse text-link">
          <a class="text link mouse text-link" [href]="image.url$ | async" target="_blank" rel="noopener noreferrer">
            <p class="text link mouse text-link">{{ translations.learnMoreLink$ | async }}
              <i><mat-icon class="mat-icon" aria-hidden="true">arrow_right_alt</mat-icon></i>
            </p></a>
        </div>
        <p *ngIf="image.location" class="title">{{ translations.location$ | async }}</p>
        <p *ngIf="image.location" class="text">{{ image.location }}</p>
        <p *ngIf="image.date" class="title">{{ translations.date$ | async }}</p>
        <p *ngIf="image.date" class="text">{{ image.date | date:'d.M.y' }}</p>
        <div class="text small">{{ image.cert$ | async }}</div>
      </div>
    </div>
  </div>
</div>
