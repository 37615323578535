import { Flooding } from '../../shared/enums/Flooding';
import { ImageStatic } from 'ol/source';
import * as Projection from 'ol/proj';
import { CoordinateProjection } from '../../../../mobilab-common/enums/CoordinateProjection';
import { environment } from '../../../environments/environment';
import ImageLayer from 'ol/layer/Image';
import { Options as BaseImageOptions } from 'ol/layer/BaseImage';
import { Scenario } from '../../openapi/models/scenario';

export interface Options extends BaseImageOptions {
  flooding: Flooding;
  floodplainKey: string;
  scenario: Scenario;
}

export class FloodingMaximumLayer extends ImageLayer {
  private readonly _flooding: Flooding;

  constructor(options: Options) {
    super(options);
    this._flooding = options.flooding;

    this.setExtent(options.extent);
    this.setSource(new ImageStatic({
      url: `${environment.gravUrl}/user/pages/howadyn_scenarios/${options.scenario.key.toLowerCase()}/${options.floodplainKey}_${this.filename}`,
      projection: Projection.get(CoordinateProjection.EPSG3857),
      imageExtent: options.extent,
    }));
  }

  private get filename(): string {
    switch (this._flooding) {
      case Flooding.WaterDepth:
        return 'max_flowdepth.png';
      case Flooding.HazardClasses:
        return 'max_hazclass.png';
      default:
        console.warn('Flooding Video File unknown.');
    }
  }
}
