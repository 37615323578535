import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { IInfoPopupData, INFO_POPUP_DATA } from '../../../../../../mobilab-common/models/IInfoPopupData';

@Component({
  selector: 'how-info-popup-catchment-area',
  templateUrl: './info-popup-catchment-area.component.html',
  styleUrls: ['./info-popup-catchment-area.component.scss']
})
export class InfoPopupCatchmentAreaComponent {
  readonly pathWithinCms = 'text_howadyn/infopopup/catchment_area';

  constructor(
    private overlayRef: OverlayRef,
    @Inject(INFO_POPUP_DATA) public data: IInfoPopupData,
  ) { }

  closeOverlay() {
    this.overlayRef.dispose();
  }
}
