<mob-main-container
  [appTitle]="'appTitle' | translate"
  [footerCopyrightTranslation]="'footerCopyright' | translate"
  [footerAboutUsTranslation]="'footerAboutUs' | translate"
  [localeCode]="currentLang()"
  [closeMenuOnBackgroundClick]="true"
  (localeCodeChange)="changeLanguage($event)"
  (menuOpened)="onMenuOpened($event)"
>
  <div class="mob-main-container-content">

    <how-main-header></how-main-header>
    <button class="help-button" mat-icon-button (click)="onHelpButtonClicked()">
      ?
    </button>
    <div class="content">
      <div class="title">{{'scenarios' | translate}}</div>
      <div class="description" [innerHTML]="'landingPageDescription' | translate"></div>
      <div class="instructions">
        {{'landingPageInstructions' | translate}}
      </div>
      <mat-icon>arrow_downward</mat-icon>

      <div class="interactions">
        <mob-info-icon [compensateBaseline]="false" (clicked)="onClickScenariosInfoIcon($event)" (click)="$event.stopPropagation()"></mob-info-icon>
        <div class="scenarios" *ngIf="(scenarios$ | async) as scenarios">
          <how-scenario-card *ngFor="let scenario of scenarios" [scenario]='scenario' (scenarioSelectedEvent)="activateScenario($event)" (scenarioHoverEvent)="handlePopup($event)">
          </how-scenario-card>
        </div>

        <!-- for some reason, routerLink is buggy on the langding page. href works, but is slower -->
        <a
          class="methodology"
          [href]="methodLink$ | async"
          target="_blank"
        >
          {{'decisionSupport' | translate}}
        </a>
      </div>
    </div>
  </div>

  <div class="mob-main-container-sidenav">
    <how-menu [menuType]="activeMenuType"></how-menu>
  </div>
</mob-main-container>
