import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ILegendCircleElement} from '../../models/legend/ILegendCircleElement';
import {ILegendRectangleElement} from '../../models/legend/ILegendRectangleElement';
import {GeoUnit} from '../../enums/GeoUnit';
import {ValueType} from '../../enums/ValueType';
import {ILegendConfiguration} from '../../models/legend/configuration/ILegendConfiguration';
import {IStaticStylesConfiguration} from '../../models/configuration/IStaticStylesConfiguration';
import {ColorAsArray, getRgbaColorFromArray} from '../../models/configuration/ColorAsArray';
import * as _ from 'lodash';
import {ILegendAbsoluteDataConfiguration, ILegendDataConfiguration} from '../../models/legend/configuration/ILegendDataConfiguration';
import {ValueMappingFunction} from '../../models/ValueMappingFunction';
import {IBoxClickEvent} from '../../models/info-icon/IBoxClickEvent';
import {NumberUtilityService} from '../../services/utilities/number-utility.service';
import {MOBILAB_COMMON_CONFIG, IMobilabCommonConfig} from '../../mobilab-common.config';
import {LocaleService} from '../../services/locale.service';
import {Subscription} from 'rxjs';

const fontSizePx = 16;

/**
 *  Generic legend panel showing a legend for absolute or relative values based on the current configuration.
 *  This component just shows the legend and does not have a preferred position on the screen: just put it in any container.
 */
@Component({
  selector: 'mob-legend-panel',
  templateUrl: './legend-panel.component.html',
  styleUrls: ['./legend-panel.component.scss']
})
export class LegendPanelComponent implements OnInit, OnChanges, OnDestroy {
  // static configuration inputs
  @Input() readonly valueMappingFunction: ValueMappingFunction;
  @Input() readonly reduceHeightOfLastRectangle: boolean;
  @Input() readonly absoluteValueUnit: string;

  // dynamic inputs relevant for computations
  @Input() readonly geographicalUnit: GeoUnit;
  @Input() readonly valueType: ValueType;
  @Input() readonly maxValue: number;

  // translations
  @Input() readonly legendAbsoluteTitle: string;
  @Input() readonly legendRelativeTitle: string;
  @Input() readonly noValueDescription: string;
  @Input() readonly noValueTooltip: string;

  @Output() readonly noValueInfoIconClicked = new EventEmitter<IBoxClickEvent>();

  private subscriptions: Subscription[] = [];
  private infoIconX: number;

  private circles: ILegendCircleElement[] = [];
  private rectangles: ILegendRectangleElement[] = [];
  private hexagonRectangles: ILegendRectangleElement[] = [];

  private noValueRect4Circle: ILegendRectangleElement[] = [];    // the circle symbolizing "no value"
  private noValueRect4Rect: ILegendRectangleElement[] = [];      // the rectangle symbolizing "no value"

  private readonly _configuration: ILegendConfiguration;
  private readonly _styles: IStaticStylesConfiguration;
  private readonly _defaultLocale: string;

  public readonly valueTypeEnum = ValueType;
  public legendAbsoluteConfig: ILegendAbsoluteDataConfiguration;
  public legendRelativeConfig: ILegendDataConfiguration;

  constructor(
    private localeService: LocaleService,
    private numberUtility: NumberUtilityService,
    @Inject(MOBILAB_COMMON_CONFIG) mobilabConfig: IMobilabCommonConfig,
  ) {
    this._styles = mobilabConfig.mapStylingConfiguration;
    this._configuration = mobilabConfig.legendConfiguration;
    this._defaultLocale = _.get(mobilabConfig.languageToLocaleMap, [mobilabConfig.defaultLanguage]);
  }

  ngOnInit(): void {
    this._calculateAllElements();

    this.subscriptions.push(this.localeService.onLocaleChanged$.subscribe(() =>
      this._calculateAllElements()
    ));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._calculateAllElements();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private _calculateAllElements(): void {
    this._calculateRelativeLegend();

    if (this.geographicalUnit === GeoUnit.hexagon && this.valueType === ValueType.Absolute) {
      this._calculateAbsoluteHexLegend();
    } else {
      this._calculateAbsoluteCircleLegend();
    }

    this.legendAbsoluteConfig = this._createLegendAbsoluteConfig();
    this.legendRelativeConfig = this._createLegendRelativeConfig();
  }


  private _createLegendAbsoluteConfig(): ILegendAbsoluteDataConfiguration {
    return {
      geographicalUnit: this.geographicalUnit,
      valueType: this.valueType,
      unit: this.absoluteValueUnit,
      valueMappingFunction: this.valueMappingFunction,

      title: this.legendAbsoluteTitle,

      svgDimension: this._absoluteSvgDimension,
      infoIconX: this.infoIconX,
      infoIconPosition: this._configuration.infoIconPosition,
      showInfoIcon: this.valueType === ValueType.Absolute,

      noValueDescription: this.noValueDescription,
      noValueTooltip: this.noValueTooltip,

      circles: this.circles,
      rectangles: this.hexagonRectangles,
      noValueRect: this.noValueRect4Circle,
    };
  }

  private _createLegendRelativeConfig(): ILegendDataConfiguration {
    return {
      valueType: this.valueType,
      valueMappingFunction: this.valueMappingFunction,

      title: this.legendRelativeTitle,

      svgDimension: this._relativeSvgDimension,
      infoIconX: this.infoIconX,
      infoIconPosition: this._configuration.infoIconPosition,
      showInfoIcon: this.valueType !== ValueType.Absolute,

      noValueDescription: this.noValueDescription,
      noValueTooltip: this.noValueTooltip,

      rectangles: this.rectangles,
      noValueRect: this.noValueRect4Rect,
    };
  }

  private get _svgWidth(): number {
    const locale = this.localeService.currentLocale;
    const width = this._configuration.svgWidth[locale];
    return width || this._configuration.svgWidth[this._defaultLocale];
  }

  private get _absoluteSvgDimension(): {width: number, height: number} {
    if (this.geographicalUnit === GeoUnit.hexagon) {
      return this._getDimensionsForSquareBasedLegend(this._styles.absoluteHexColors.length);
    }

    // circles are drawn
    const circleRadii = _.map(this._absoluteCircleThresholds, value => this._valueToRadius(value));
    const space = this._configuration.absolute.vertSpaceCircles;

    let height = circleRadii.reduce((a, b) => a + b, 0) * 2 + (circleRadii.length - 1) * space;

    if (this.valueType !== ValueType.Mixed) {
      height += 1.5 * this._configuration.relative.squareSize;
    }

    return { width: this._svgWidth, height: height + 6 };
  }

  private get _relativeSvgDimension(): {width: number, height: number} {
    return this._getDimensionsForSquareBasedLegend(this._styles.relativeColors.length);
  }

  get dynamicClass(): string {
    return this.valueType === ValueType.Mixed ? '' : 'wide';
  }

  private _getDimensionsForSquareBasedLegend(numberOfCategories): {width: number, height: number} {
    const addendNoValueRect = 1;
    const addendLastRect = this.reduceHeightOfLastRectangle ? 0.5 : 1;
    return {
      width: this._svgWidth,
      height: this._configuration.relative.squareSize * (numberOfCategories + addendNoValueRect + addendLastRect) + 1
    };
  }

  private get _maxCircleRadius(): number {
    return this._styles.maxCircleRadius[this.geographicalUnit];
  }

  private get _absoluteHexThresholds(): number[] {
    return _.map(this._styles.absoluteHexColors, colorThreshold => {
      const thresholdRelative = colorThreshold.threshold;
      return _.ceil(this.maxValue * thresholdRelative / 100);
    });
  }

  private get _absoluteCircleThresholds(): number[] {
    return this._configuration.absolute.valueFractions.map(fraction => Math.ceil(this.maxValue / fraction));
  }

  private _calculateAbsoluteCircleLegend(): void {
    const values = this._absoluteCircleThresholds;
    const space = this._configuration.absolute.vertSpaceCircles;
    const circles = [];
    let relY = 5;

    values.forEach(value => {
      circles.push(this._createCircleElement(relY, value));
      relY = relY + 2 * this._valueToRadius(value) + space;
    });

    this.circles = circles;

    if (this.valueType === ValueType.Mixed) {
      this.noValueRect4Circle = [];
    } else {
      this.noValueRect4Circle = [this._createRectangleItem(
        1 + this._maxCircleRadius - 0.5 * this._configuration.relative.squareSize,
        relY - space + 0.5 * this._configuration.relative.squareSize,
        undefined, this._styles.hasNoValueColor,
        this._configuration.relative.squareSize + 0.5 * this._configuration.relative.horizSpaceText + this._maxCircleRadius,
        false
      )];
    }

    this.infoIconX =  this._configuration.relative.squareSize + 0.5 * this._configuration.relative.horizSpaceText + this._maxCircleRadius;
  }

  private _calculateRelativeLegend() {
    const relX = 1 + this._maxCircleRadius - 0.5 * this._configuration.relative.squareSize;
    const xLabel = this._configuration.relative.squareSize + this._configuration.relative.horizSpaceText + this._maxCircleRadius;
    let relY = 10;

    this.rectangles = this._styles.relativeColors.map((item, index) => {
      const isLastThreshold = index === this._styles.relativeColors.length - 1;

      const rectangle = this._createRectangleItem(
        relX,
        relY,
        item.threshold,
        item.color,
        xLabel,
        isLastThreshold
      );

      relY = relY + this._configuration.relative.squareSize;
      return rectangle;
    });

    if (!this.reduceHeightOfLastRectangle) {
      relY = relY + this._configuration.relative.squareSize / 2;
    }

    this.noValueRect4Rect = [
      this._createRectangleItem(
        1 + this._maxCircleRadius - 0.5 * this._configuration.relative.squareSize,
        relY,
        undefined, this._styles.hasNoValueColor,
        this._configuration.relative.squareSize + 0.5 * this._configuration.relative.horizSpaceText + this._maxCircleRadius,
        false
      )
    ];

    this.infoIconX =  this._configuration.relative.squareSize + 0.5 * this._configuration.relative.horizSpaceText + this._maxCircleRadius;
  }

  private _calculateAbsoluteHexLegend() {
    let relY = 10;
    const items = [];

    const values = this._absoluteHexThresholds;

    const labelOffset = this._calculateLabelOffset(ValueType.Absolute);

    this._styles.absoluteHexColors.forEach((item, index) => {
      const isLastThreshold = index === this._styles.absoluteHexColors.length - 1;


      items.push(this._createRectangleItem(
        1 + this._maxCircleRadius - 0.5 * this._configuration.relative.squareSize,
        relY,
        values[index],
        item.color,
        this._configuration.relative.squareSize + this._configuration.relative.horizSpaceText + labelOffset,
        isLastThreshold
      ));
      relY = relY + this._configuration.relative.squareSize;
    });

    this.hexagonRectangles = items;

    if (!this.reduceHeightOfLastRectangle) {
      relY = relY + this._configuration.relative.squareSize / 2;
    }

    this.noValueRect4Circle = [
      this._createRectangleItem(
        1 + this._maxCircleRadius - 0.5 * this._configuration.relative.squareSize,
        relY,
        undefined,
        this._styles.hasNoValueColor,
        this._configuration.relative.squareSize + 0.5 * this._configuration.relative.horizSpaceText + this._maxCircleRadius,
        false
      )
    ];
  }

  private _createRectangleItem (
    relX: number, relY: number, threshold: number,
    color: ColorAsArray,  xLabel: number, isLastThreshold: boolean
  ): ILegendRectangleElement {
    const reduceHeight = isLastThreshold && this.reduceHeightOfLastRectangle;

    const rect = {
      x: relX,
      y: relY,
      width: this._configuration.relative.squareSize,
      height: reduceHeight ? 0.5 * this._configuration.relative.squareSize : this._configuration.relative.squareSize,
      stroke: getRgbaColorFromArray(this._styles.polygonOutlineColor),
      fill: getRgbaColorFromArray(color)
    };

    const label = {
      x: xLabel,
      y: !_.isNil(threshold) ? relY : relY + 0.5 * this._configuration.relative.squareSize,
      label: threshold,
      length: 2 * this._configuration.em2ch + 'em',
      anchor: 'end'
    };

    return {rect, label};
  }

  private _createCircleElement(relY: number, value: number): ILegendCircleElement {
    const circle = {
      cx: this._valueToRadius(this.maxValue) + 1,
      cy: relY + this._valueToRadius(value),
      r: this._valueToRadius(value),
      fill: this.valueType === ValueType.Absolute ? getRgbaColorFromArray(this._styles.absoluteCircleColor) : 'white',
      stroke: getRgbaColorFromArray(this._styles.circleBorderColor)
    };

    const labelOffset = this._calculateLabelOffset(ValueType.Absolute);
    const label = {
      x: this._valueToRadius(this.maxValue) * 2 + this._configuration.absolute.horizSpaceText + labelOffset,
      y: relY + this._valueToRadius(value),
      label: value,
    };

    return {circle, label};
  }

  // the label-position needs to take into account how much space the label needs
  private _calculateLabelOffset(valueType: ValueType): number {
    const unitLength = _.size(this.absoluteValueUnit);
    const roundedMaxValue = _.isNil(this.valueMappingFunction) ?
      this.maxValue :
      this.valueMappingFunction(this.maxValue, valueType).value;

    return this.numberUtility.getNumberDisplayLengthInPixel(roundedMaxValue, unitLength, fontSizePx, this._configuration.em2ch);
  }

  private _valueToRadius(value: number): number {
    const maxRadius = this._maxCircleRadius;
    const result = Math.sqrt(value) / Math.sqrt(this.maxValue) * maxRadius;
    return result || 0;
  }
}

