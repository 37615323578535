import {Component, Input, OnInit} from '@angular/core';
import {IMenuConfiguration} from '../../../models/menu/IMenuConfiguration';

/**
 * Generic component to show an in-app menu based on a list of its entries.
 */
@Component({
  selector: 'mob-generic-app-menu',
  templateUrl: './generic-app-menu.component.html',
  styleUrls: ['./generic-app-menu.component.scss']
})
export class GenericAppMenuComponent implements OnInit {
  @Input() readonly config: IMenuConfiguration;
  constructor() {}
  ngOnInit() {}
}
