import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Impact } from '../../shared/enums/Impact';

enum Unit {
  Count = 'count',
  Km = 'km',
  MioChf = 'mioChf'
}

@Pipe({
  name: 'impactUnit',
  pure: false
})
export class ImpactUnitPipe extends TranslatePipe implements PipeTransform {
  constructor(translateService: TranslateService, changeDetectionRef: ChangeDetectorRef
  ) {
    super(translateService, changeDetectionRef);
  }

  private static getUnit(impact: Impact): Unit {
    switch (impact) {
      case Impact.DamageToBuildings:
        return Unit.MioChf;
      case Impact.StreetsLimitedlyUsed:
      case Impact.StreetsNotUsed:
      case Impact.StreetsWithEvasiveTraffic:
        return Unit.Km;
      case Impact.Buildings:
      case Impact.Hospitals:
      case Impact.Schools:
      case Impact.RestHomes:
      case Impact.Persons:
      case Impact.Workplaces:
      case Impact.RestHomeBeds:
      default:
        return Unit.Count;
    }
  }

  transform(value: Impact, displayCountUnit = false): any {
    const unit = ImpactUnitPipe.getUnit(value);
    if (unit === Unit.Count && !displayCountUnit) {
      return '';
    }
    return super.transform(`impact.unit.${unit}`);
  }
}
