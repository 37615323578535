import { VideoLayer } from '../map/video-layer';
import { Options as VideoLayerOptions } from '../map/video-layer';
import { Precipitation } from '../../shared/enums/Precipitation';
import { ImageStatic } from 'ol/source';
import * as Projection from 'ol/proj';
import { CoordinateProjection } from '../../../../mobilab-common/enums/CoordinateProjection';
import { environment } from '../../../environments/environment';
import { DynamicLayer } from '../time/dynamic-layer';
import { ScenarioLayer } from '../scenario/scenario-layer';
import OlMap from 'ol/Map';
import { TimeService } from '../time/time.service';
import { Scenario } from '../../openapi/models/scenario';
import { defaultExtent } from '../scenario/defaultExtent';

export interface Options extends VideoLayerOptions {
  precipitation: Precipitation;
}

export class PrecipitationVideoLayer extends VideoLayer implements DynamicLayer, ScenarioLayer {
  private readonly _precipitation: Precipitation;
  private _scenario: Scenario;

  constructor(options: Options, map: OlMap, timeService: TimeService) {
    super(options, map, timeService);
    this._precipitation = options.precipitation;
  }

  get scenario(): Scenario {
    return this._scenario;
  }

  set scenario(value: Scenario) {
    this.setSource(new ImageStatic({
      url: 'assets/blankLayer.png',
      projection: Projection.get(CoordinateProjection.EPSG3857),
      imageExtent: defaultExtent,
    }));
    this.videoSourcePath = `${environment.gravUrl}/user/pages/howadyn_scenarios/${value.key.toLowerCase()}/${this.filename}`;
    this._scenario = value;
  }

  private get filename(): string {
    switch (this._precipitation) {
      case Precipitation.Intensity:
        return 'prec_int.webm';
      case Precipitation.Sum:
        return 'prec_sum.webm';
      default:
        console.warn('Precipitation Video File unknown.');
    }
  }
}
