import { InjectionToken } from '@angular/core';
import {IBasicMapConfiguration} from './models/map/map-basic/IBasicMapConfiguration';
import {IStaticStylesConfiguration} from './models/configuration/IStaticStylesConfiguration';
import {ILegendConfiguration} from './models/legend/configuration/ILegendConfiguration';
import {CoordinateProjection} from './enums/CoordinateProjection';
import {LanguageCode} from './enums/LocaleCode';

/* Used by the implementations (schadpot, schadsim, etc.) to provide configuration data to
 * mobilab-common stuff.
 *
 * See https://angular.io/guide/dependency-injection-providers#non-class-dependencies
 */

export interface IMobilabCommonConfig {
  logging: {
    sendToServer: boolean;
    writeToBrowserLog: boolean;
  };

  defaultLanguage: string;
  languageToLocaleMap: {[key: string]: string};

  basicMapConfiguration: IBasicMapConfiguration;
  mapStylingConfiguration: IStaticStylesConfiguration;
  legendConfiguration: ILegendConfiguration;

  lv95: {
    epsg: CoordinateProjection,
    proj4: string
  };

  municipalitySearchByNameUrl: string;
  municipalitySearchByBoundingBoxUrl: string;

  uesg?: {
    url: string,
    epsg: CoordinateProjection,
    maxImg: number,
    defaultImageIDs: string[],
    availableLanguages: LanguageCode[],
    defaultLanguage: LanguageCode,
    maxLocationLengthChars: { minHeightPx: number, maxLength: number }[], // max. length of the location-text depending on image height
    minHeightShowLocationPx: number,  // min. image height needed to display location-text
    minHeightShowDatePx: number,  // min. image height needed to display event-date
  };
}

export const MOBILAB_COMMON_CONFIG = new InjectionToken<IMobilabCommonConfig>('mobilab-common.config');
