<mob-legend-base [config]="config" (noValueInfoIconClicked)="noValueInfoIconClicked.emit($event)">
  <svg>
    <ng-container *ngIf="config.geographicalUnit !== geoUnitEnum.hexagon; else hexagons">
      <g *ngFor="let item of config.circles">
        <circle [attr.cx]="item.circle.cx" [attr.cy]="item.circle.cy" [attr.r]="item.circle.r" [attr.stroke]="item.circle.stroke"
                stroke-width="1" [attr.fill]="item.circle.fill"></circle>
        <text [attr.x]="item.label.x" [attr.y]="item.label.y" dy="0.5ex" text-anchor="end">
          {{ mapValueForDisplay(item.label.label) }}{{valueUnit}}
        </text>
      </g>
    </ng-container>
    <ng-template #hexagons>
      <g *ngFor="let item of config.rectangles">
        <rect [attr.x]="item.rect.x" [attr.y]="item.rect.y" [attr.width]="item.rect.width" [attr.height]="item.rect.height" [attr.fill]="item.rect.fill"
              [attr.stroke]="item.rect.stroke"></rect>
        <text [attr.x]="item.label.x" [attr.y]="item.label.y" dy="0.5ex" text-anchor="end">
          {{ mapValueForDisplay(item.label.label) }}{{valueUnit}}
        </text>
      </g>
    </ng-template>
    <g *ngFor="let item of config.noValueRect">
      <rect [attr.x]="item.rect.x" [attr.y]="item.rect.y" [attr.width]="item.rect.width" [attr.height]="item.rect.height" [attr.fill]="item.rect.fill"
            [attr.stroke]="item.rect.stroke"></rect>
      <text [attr.x]="item.label.x" [attr.y]="item.label.y" dy="0.5ex">
        {{config.noValueDescription}}
      </text>
    </g>
  </svg>
</mob-legend-base>
