import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router, UrlSerializer} from '@angular/router';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {commonRouteSegments} from '../../common-routing-constants';
import * as _ from 'lodash';
import {StatisticsTrackerService} from '../statistics-tracker.service';
import {LocaleService} from '../locale.service';
import {fromPromise} from 'rxjs/internal-compatibility';

/**
 * Service to help with routing-actions.
 */
@Injectable({
  providedIn: 'root'
})
export class RoutingHelperService {
  private _previousRelativeRoutes: string[] = []; // document.referrer does not always work => try to store the previous route

  constructor(
    private statisticsTracker: StatisticsTrackerService,
    private localeService: LocaleService,
    private serializer: UrlSerializer,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
  ) {}

  get relativeRoute(): string {
    return this.router.url;
  }

  get absoluteRoute(): string {
    return `${this.absoluteBaseUrl}/${this.relativeRoute}`;
  }

  /**
   * @return the base-route of this application
   */
  get absoluteBaseUrl(): string {
    const origin = window.location.origin;
    const protocol = _.replace(_.replace(window.location.protocol, ':', ''), '/', '');
    const host = window.location.host;
    return origin || `${protocol}://${host}`;
  }

  get relativeBaseRouteWithLanguage(): string[] {
    const routeSegmentLanguage =  this.localeService.currentLanguage;
    return [commonRouteSegments.home, routeSegmentLanguage];
  }

  get relativeBaseRouteWithLanguageStream$(): Observable<string[]> {
    return this.localeService.currentLocaleStream$.pipe(
      map(locale => this.localeService.getLanguageFromLocale(locale)),
      map(language => [commonRouteSegments.home, language]),
    );
  }

  // =====================================================================
  // ========================= Route Navigation ==========================
  // =====================================================================

  async returnToPreviousRoute() {
    while (!_.isEmpty(this._previousRelativeRoutes)) {
      const previousRoute = this._popPreviousRoute();

      const routeWouldChange = previousRoute !== this.absoluteRoute;
      const routeIsInternal = this._isRouteInternal(previousRoute);

      if (routeWouldChange && routeIsInternal) {
        this.statisticsTracker.trackCustomPage(previousRoute);
        this.location.back();
        return;
      }
    }

    await this.goToHome();  // default behavior if no valid previous route was found
  }

  async navigate(routeSegments: string[]): Promise<boolean> {
    this._previousRelativeRoutes.push(this.relativeRoute);
    return this.router.navigate(routeSegments);
  }

  async goToHome(): Promise<boolean> {
    this.statisticsTracker.trackBasePage();
    return await this.navigate(this.relativeBaseRouteWithLanguage);
  }

  addPreviousRoute(route: string) {
    this._previousRelativeRoutes.push(route);
  }

  // =====================================================================
  // ========================= Query Parameters ==========================
  // =====================================================================

  createAbsoluteUrlWithParams(queryParams: Params): string {
    const baseUrl = this.absoluteBaseUrl;
    const relativeUrl = this.createRelativeUrlWithParams(queryParams);
    return baseUrl + relativeUrl;
  }

  createRelativeUrlWithParams(queryParams: Params): string {
    const urlTree = this.router.createUrlTree([], { queryParams });
    return this.serializer.serialize(urlTree);
  }

  removePossibleQueryParams(): void {
   this.removePossibleQueryParams$().subscribe();
  }

  removePossibleQueryParams$(): Observable<boolean> {
    return  this.route.queryParamMap.pipe(
      take(1),
      filter(queryParamMap => !_.isEmpty(queryParamMap.keys)),  // nothing to do if there are no query-parameters
      switchMap(() => {
        const relativeRoute = this.relativeRoute;
        const startQueryParameters = _.indexOf(relativeRoute, '?');

        const routeWithoutQueryParameters = startQueryParameters >= 0 ?
          relativeRoute.substring(0, startQueryParameters) :
          relativeRoute;

        return fromPromise(this.navigate([routeWithoutQueryParameters]));
      }));
  }

  // =====================================================================
  // ========================== Helper Methods ===========================
  // =====================================================================

  private _popPreviousRoute(): string {
    // always pop the top route in the back-stack
    const previousRoute = _.isEmpty(this._previousRelativeRoutes) ? null : this._previousRelativeRoutes.pop();

    // document.referrer only works if the user clicked on a link instead of typing into the address-bar
    if (!_.isEmpty(document.referrer)) {
      return document.referrer;
    }

    return _.isEmpty(previousRoute) ? null : `${this.absoluteBaseUrl}/${previousRoute}`;
  }

  /**
   * @return whether it is "safe" to return to that route. Only routes within the app are considered safe.
   */
  private _isRouteInternal(route: string): boolean {
    if (_.isEmpty(route)) {
      return false;
    }

    return _.startsWith(route, this.absoluteBaseUrl);
  }
}
