import { IMobilabCommonConfig } from '../../mobilab-common/mobilab-common.config';
import { appConfiguration } from './shared/app-configuration';
import { IconPosition } from '../../mobilab-common/models/legend/configuration/ILegendDataConfiguration';

export const mobilabCommonConfig: IMobilabCommonConfig = {
  defaultLanguage: appConfiguration.defaultLanguage,
  languageToLocaleMap: appConfiguration.languageToLocaleMap,

  basicMapConfiguration: null,
  logging: {
    sendToServer: true,
    writeToBrowserLog: true,
  },
  lv95: null,
  municipalitySearchByBoundingBoxUrl: null,
  municipalitySearchByNameUrl: null,
  uesg: null,

  legendConfiguration: {
    absolute: {
      vertSpaceCircles: null,
      horizSpaceText: 2,
      valueFractions: null
    },
    relative: {
      squareSize: 20,
      horizSpaceText: 10
    },
    svgWidth: null,
    em2ch: 0.5, // scale em to fit approx. the width of a zero,
    infoIconPosition: IconPosition.below,
  },

  mapStylingConfiguration: null
};
